// Icons
import GridOnIcon from "@mui/icons-material/GridOn";

// Libs
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { navigate } from "gatsby";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    LoadingWrapper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    Select,
    Table,
    Modal,
    SearchBar,
} from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

//Hooks
import {
    useFindStep,
    useExternalCodes,
    useContext,
    useRegionDetails,
    usePartners,
    useProductDetails,
} from "@hooks";
//Atoms
import { externalCodesStepsState } from "@atoms";
//Types
import type { Location } from "@types";

// Utils
import {
    apiResponseCounter,
    isArrayWithContent,
    isSuccessfulCall,
} from "@utils";
import { ContextItem } from "@types";

/**
 * Type
 */
type CodeITem = {
    context: ContextItem;
    externalName: string;
    externalCode: string;
    externalId?: string;
    index?: number;
};

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
    itemId: string;
    type: string;
    id: string;
}

/**
 * Initial code state values
 */
const INITIAL_CODE = {
    context: { contextName: "", contextId: "" },
    externalName: "",
    externalCode: "",
    index: undefined,
};

/**
 * External Code Layout
 */
const ExternalCodes = ({ id, location, itemId, type }: Props) => {
    /**
     * Local states
     */
    // Toggle modal
    const [openModal, toggleModal] = useState(false);

    // Form values store
    const [values, setValues] = useState<CodeITem | undefined>(INITIAL_CODE);

    // Touched store
    const [touched, setTouch] = useState({});

    // Validation
    const [validationMsg, setValidationMsg] = useState({
        context: "",
        externalCode: "",
    });

    //Steps hooks
    const { currentStep } = useFindStep(location, externalCodesStepsState);

    /**
     * Type mapper (region, contract, or account)
     */
    const page = useMemo(() => {
        if (!type || typeof type !== "string") return;
        const pageType = type.toLowerCase();
        if (pageType === "region") {
            return {
                id: "REGION", //use the id to query the context by type
                title: "Region", // show the label on the side bar
                path: "/dashboard/regions/", // to navigate back to the correct overview
                pathLabel: "Return to regions overview", // button label to navigate to the overview
                callback: () => loadRegionDetails(itemId), // callback to fetch details in order to show it on the sidebar below the title
                apiObject: {
                    itemId: "regionId",
                    contextId: "contextId",
                    externalName: "externalRegionName",
                    externalCode: "externalRegionCode",
                },
            };
        }

        if (pageType === "account") {
            return {
                id: "ACCOUNT", //use the id to query the context by type
                title: "Partner", // show the label on the side bar
                path: "/dashboard/partners/", // to navigate back to the correct overview
                pathLabel: "Return to partners overview", // button label to navigate to the overview
                callback: () => loadPartnerDetails(), // callback to fetch details in order to show it on the sidebar below the title
                apiObject: {
                    itemId: "accountId",
                    contextId: "contextId",
                    externalName: "externalAccountName",
                    externalCode: "externalAccountCode",
                },
            };
        }

        if (pageType === "products") {
            return {
                id: "PRODUCT", //use the id to query the context by type
                title: "Product", // show the label on the side bar
                path: "/dashboard/products/", // to navigate back to the correct overview
                pathLabel: "Return to products overview", // button label to navigate to the overview
                callback: () => loadProductDetails(itemId), // callback to fetch details in order to show it on the sidebar below the title
                apiObject: {
                    itemId: "productId",
                    contextId: "contextId",
                    externalName: "externalProductName",
                    externalCode: "externalProductCode",
                },
            };
        }
    }, [type]);

    /**
     * API
     */
    const {
        create,
        update,
        list,
        loading: { creating, fetching, updating },
        load: loadExternalCodes,
    } = useExternalCodes(type, itemId);

    const {
        list: contexts,
        loading: { listLoading: contextsLoading },
        load: loadContextList,
        forceReset: clearContextsCache,
    } = useContext(false, page?.id);

    const {
        list: regionDetails,
        load: loadRegionDetails,
        loading: regionDetailsLoading,
    } = useRegionDetails();

    const {
        list: partnerDetails,
        load: loadPartnerDetails,
        loading: { fetching: partnerDetailsLoading },
    } = usePartners(`partner/${itemId}`, "partner", false);

    const {
        list: productDetails,
        load: loadProductDetails,
        loading: productDetailsLoading,
    } = useProductDetails();

    /**
     * Check if there is an id (region ID, contractID, partner ID,...)
     * Load the desired details info
     */
    useEffect(() => {
        if (!itemId) {
            navigate("/dashboard/regions/");
        } else {
            if (!!page && page.callback) page.callback();
        }
    }, [itemId]);

    /**
     * Reset all
     */
    const onReset = () => {
        setValues(undefined);
        setTouch({});
        toggleModal(false);
        clearContextsCache();
        setValidationMsg({ context: "", externalCode: "" });
    };

    /**
     * Check if the form is valid
     */
    const checkFormValidity = () => {
        if (!isArrayWithContent(list?.data?.records)) {
            setValidationMsg({ context: "", externalCode: "" });
            return false; //is valid
        }
        let hasDuplicateContext = false;
        let hasDuplicateCode = false;

        list?.data?.records.forEach((item, index) => {
            if (index === values?.index) return;

            if (
                item.context?.id === values?.context?.contextId &&
                (item.externalProductCode === values?.externalCode ||
                    item?.externalAccountCode === values?.externalCode ||
                    item?.externalProductCode === values?.externalCode ||
                    item?.externalRegionCode === values?.externalCode)
            ) {
                hasDuplicateContext = true;
                hasDuplicateCode = true;
            }
        });
        setValidationMsg({
            context: hasDuplicateContext
                ? "Context must be unique for each external code"
                : "",
            externalCode: hasDuplicateCode
                ? "Code must be unique for each context type"
                : "",
        });

        return hasDuplicateContext || hasDuplicateCode;
    };

    /**
     * Handle submit
     */
    const onSubmit = () => {
        const hasDuplication = checkFormValidity();

        if (hasDuplication) return;

        // Update code
        if (values?.externalId) {
            const mapExternalCode = {
                [`${page?.apiObject?.itemId}`]: itemId,
                [`${page?.apiObject?.contextId}`]: values?.context?.contextId,
                [`${page?.apiObject?.externalName}`]: values?.externalName,
                [`${page?.apiObject?.externalCode}`]: values?.externalCode,
            };

            update(values?.externalId, mapExternalCode).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    loadExternalCodes();
                    onReset();
                }
            });
        } else {
            // Add new code
            // Not allowed to use the same values when the user add new code

            const mapExternalCode = {
                countryIsoCode: values?.context?.country?.isoCode,
                [`${page?.apiObject?.itemId}`]: itemId,
                [`${page?.apiObject?.contextId}`]: values?.context?.contextId,
                [`${page?.apiObject?.externalName}`]: values?.externalName,
                [`${page?.apiObject?.externalCode}`]: values?.externalCode,
            };

            create(mapExternalCode).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    loadExternalCodes();
                    onReset();
                }
            });
        }
    };

    /**
     * Error handler
     */
    const hasError = useCallback(
        (field: string, subField?: string) => {
            if (!field || !values) return false;
            const isEmptyValue =
                typeof values[field] === "object" && subField
                    ? !values[field][subField]
                    : !values[field];
            return isEmptyValue && !!touched[field];
        },
        [values, touched],
    );

    /**
     * Blur handler
     */
    const handleBlur = (field: string) => {
        if (!field || (!!field && !!touched[field])) return;
        const copyTouched = { ...touched };
        copyTouched[field] = true;
        setTouch(copyTouched);
    };

    /**
     * Change handler
     */
    const onChange = (field: string, value: any) => {
        if (!field || !values) return;
        const copyValues = { ...values };
        copyValues[field] = value;

        setValues(copyValues);
    };

    /**
     * Prepare code to update
     */
    const storeCodeToUpdate = (item?: any) => {
        toggleModal(true);
        loadContextList().then(res => {
            if (
                isSuccessfulCall(res?.status) &&
                isArrayWithContent(res?.data?.records)
            ) {
                // Initialize code

                const initialCode = {
                    context: {
                        contextName: item?.context?.name || "",
                        contextId: item?.context?.id || "",
                    },
                    externalName:
                        item?.externalRegionName ||
                        item?.externalAccountName ||
                        item?.externalProductName ||
                        "",
                    externalCode:
                        item?.externalRegionCode ||
                        item?.externalAccountCode ||
                        item?.externalProductCode ||
                        "",
                    externalId:
                        item?.regionMappingId ||
                        item?.accountMappingId ||
                        item?.productMappingId ||
                        undefined,
                    index: item?.index !== undefined ? item.index : undefined,
                };

                setValues(initialCode);
            }
        });
    };

    const subTitleMapper = useMemo(() => {
        if (
            !regionDetails?.data?.regionName &&
            !productDetails?.data?.productName &&
            !partnerDetails?.data?.accountName
        )
            return;

        return (
            regionDetails?.data?.regionName ||
            productDetails?.data?.productName ||
            partnerDetails?.data?.accountName
        );
    }, [regionDetails, partnerDetails, productDetails]);

    const loading = useMemo(() => {
        const loadingStates = [
            regionDetailsLoading,
            partnerDetailsLoading,
            productDetailsLoading,
        ];

        return loadingStates?.find(
            item => item !== undefined && typeof item === "boolean",
        );
    }, [regionDetailsLoading, partnerDetailsLoading, productDetailsLoading]);
    /**
     * Render
     */
    return (
        <LoadingWrapper
            id={`external-code-wrapper-loading`}
            loading={loading}
            fullHeight
            NoEmptyPage
        >
            <Fragment>
                <Breadcrumbs
                    id={`create-partner-breadcrumbs`}
                    icon={<GridOnIcon color="primary" />}
                    title="Dashboard"
                    location={location}
                    onClick={() => navigate(`${page?.path}`)}
                />

                <FlowLayout>
                    <FlowAside>
                        <Stepper
                            id={`link-external-codes-stepper`}
                            title={currentStep?.title}
                            subTitle={
                                page
                                    ? `${page?.title}: ${subTitleMapper}`
                                    : undefined
                            }
                        />
                    </FlowAside>

                    <FlowBody>
                        <Modal
                            id={`add-new-region-modal`}
                            open={openModal}
                            smallView
                            onClose={() => {
                                onReset();
                            }}
                            title={
                                // hide header while we initialize the values object, to avoid the delay of rendering header & btns labels
                                values
                                    ? values?.externalId
                                        ? `Edit external code`
                                        : "Add new external code"
                                    : ""
                            }
                            primaryButton={{
                                action: () => {
                                    onSubmit();
                                },
                                text: values?.externalId ? "Save" : "Add",
                                loading: creating || updating,
                                disabled:
                                    !values?.context?.contextId ||
                                    !values?.externalCode ||
                                    !values?.externalName,
                                hidden: !values, // hide btns while we initialize the values object, to avoid the delay of rendering header & btns labels
                            }}
                            secondaryButton={{
                                action: () => {
                                    onReset();
                                },
                                text: "Cancel",
                                hidden: !values, // hide btns while we initialize the values object, to avoid the delay of rendering header & btns labels
                            }}
                        >
                            <LoadingWrapper
                                id={`external-code-wrapper`}
                                loading={
                                    contextsLoading ||
                                    (openModal && !contexts?.data)
                                }
                            >
                                <Grid container item xs={12} spacing={3} mt={3}>
                                    <Grid item xs={4}>
                                        <InputLabel
                                            shrink
                                            id={`country`}
                                            error={
                                                hasError(
                                                    "context",
                                                    "contextId",
                                                ) || !!validationMsg?.context
                                            }
                                        >
                                            {"Context (*)"}
                                        </InputLabel>

                                        <Select
                                            value={values?.context}
                                            id={`${id}-context`}
                                            onChange={(_, index) => {
                                                onChange(
                                                    "context",
                                                    contexts?.data?.records[
                                                        index
                                                    ],
                                                );
                                            }}
                                            menuItemLabel={"contextName"}
                                            list={contexts?.data?.records}
                                            menuItemId="contextId"
                                            name="context"
                                            error={
                                                hasError(
                                                    "context",
                                                    "contextId",
                                                ) || !!validationMsg?.context
                                            }
                                            onBlur={() => handleBlur("context")}
                                            loading={contextsLoading}
                                        />
                                        {!!validationMsg?.context && (
                                            <FormHelperText error>
                                                {validationMsg?.context}
                                            </FormHelperText>
                                        )}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel
                                            shrink
                                            id={`${id}-external-region-code`}
                                            error={
                                                hasError("externalCode") ||
                                                !!validationMsg?.externalCode
                                            }
                                        >
                                            {"Code (*)"}
                                        </InputLabel>
                                        <TextField
                                            id={`${id}-external-region-code`}
                                            fullWidth
                                            name={`${id}-external-region-code`}
                                            size="small"
                                            value={values?.externalCode}
                                            onChange={event =>
                                                onChange(
                                                    "externalCode",
                                                    event.target.value,
                                                )
                                            }
                                            variant="outlined"
                                            error={
                                                hasError("externalCode") ||
                                                !!validationMsg?.externalCode
                                            }
                                            onBlur={() =>
                                                handleBlur("externalCode")
                                            }
                                            disabled={contextsLoading}
                                        />
                                        {!!validationMsg?.externalCode && (
                                            <FormHelperText error>
                                                {validationMsg?.externalCode}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputLabel
                                            shrink
                                            id={`${id}-external-region-name`}
                                            error={hasError("externalName")}
                                        >
                                            {"Name (*)"}
                                        </InputLabel>
                                        <TextField
                                            id={`${id}-external-region-name`}
                                            size="small"
                                            fullWidth
                                            name={"externalName"}
                                            value={values?.externalName}
                                            onChange={event =>
                                                onChange(
                                                    "externalName",
                                                    event.target.value,
                                                )
                                            }
                                            variant="outlined"
                                            error={hasError("externalName")}
                                            onBlur={() =>
                                                handleBlur("externalName")
                                            }
                                            disabled={contextsLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </LoadingWrapper>
                        </Modal>

                        <LoadingWrapper
                            id={`link-external-code-loading`}
                            loading={fetching}
                            fullHeight
                        >
                            <Fragment>
                                <FlowContent>
                                    <Box mt={5}>
                                        <Box
                                            mt={2}
                                            mb={4}
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <SearchBar
                                                id="add-new-region"
                                                filterKey={
                                                    ROWRENDERERCONST.EXTERNAL_CODES
                                                }
                                                action={{
                                                    label: "Add new external code",
                                                    callback: () => {
                                                        storeCodeToUpdate();
                                                    },
                                                    disabled: fetching,
                                                }}
                                                hasFilters={false}
                                            />
                                        </Box>
                                        <Box
                                            display="flex"
                                            alignItems="baseline"
                                            mb={4}
                                        >
                                            <Typography variant="h2">
                                                External codes
                                            </Typography>

                                            <Typography
                                                ml={1}
                                                variant="caption1"
                                            >
                                                {apiResponseCounter(
                                                    list,
                                                    fetching,
                                                    "external code|external codes",
                                                )}
                                            </Typography>
                                        </Box>

                                        <Table
                                            id={`dashboard-partners-overview-list`}
                                            headers={HEADERS.EXTERNAL_CODES}
                                            maxHeight="35rem"
                                            rows={list?.data?.records}
                                            loading={fetching}
                                            type={
                                                ROWRENDERERCONST.EXTERNAL_CODES
                                            }
                                            callbacks={{
                                                onEdit: (item, index) => {
                                                    storeCodeToUpdate({
                                                        ...item,
                                                        index,
                                                    });
                                                },
                                            }}
                                            emptyMsg="No external codes found!"
                                            isOverview={false}
                                        />
                                    </Box>
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`create-partner-step-actions`}
                                        loading={false}
                                        primaryButton={{
                                            text: page?.pathLabel,
                                            action: page?.path
                                                ? () => navigate(page?.path)
                                                : undefined,
                                            disabled: false,
                                            isSubmitButton: true,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </LoadingWrapper>
                    </FlowBody>
                </FlowLayout>
            </Fragment>
        </LoadingWrapper>
    );
};

export default ExternalCodes;
