import { Location } from "@types";

const normalizePath = (path: string) =>
    path.endsWith("/") ? path : `${path}/`;

type Tab = {
    title: string;
    path: string;
};

export const breadcrumbsMapper = (
    tabs: Array<Tab | (Tab & { subpages?: { title: string; path: string } })>,
    location: Location,
) => {
    const currentPath = normalizePath(location.pathname);

    for (const tab of tabs) {
        // Check subpages first
        if ("subpages" in tab && tab.subpages) {
            const matchingSubpage = Array.isArray(tab.subpages)
                ? tab.subpages.find(subpage =>
                      normalizePath(subpage.path).includes(currentPath),
                  )
                : undefined;
            if (matchingSubpage) {
                return matchingSubpage;
            }
        }

        // If no matching subpage, check main tab
        if (normalizePath(tab.path).includes(currentPath)) {
            return tab;
        }
    }

    return undefined;
};
