// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Lib
import { Breadcrumbs as _Breadcrumbs } from "@mui/material";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment, useMemo } from "react";
import classnames from "classnames";

// Constants
import { BREADCRUMBS_MAP } from "@constants";

// Types
import { Location } from "@types";

// Styles
import * as style from "./style.module.scss";

/**
 * Breadcrumbs Page
 */

type BreadcrumbsPage = {
    label: string;
    id: string;
    homePath?: string;
};

/**
 * Props type
 */
interface Props {
    location: Location;
    icon: React.ReactNode;
    title: string;
    resetAtoms?: () => void;
    id: string;
    onClick?: () => void;
    itemCount?: number;
    subPage?: string;
}

/**
 * Breadcrumbs
 */
const Breadcrumbs: React.FC<Props> = ({
    location,
    title,
    id,
    itemCount,
    subPage,
}) => {
    // State
    const [currentPage, setPage] = useState<BreadcrumbsPage | undefined>(
        undefined,
    );

    useEffect(() => {
        if (!location) return;

        const index = BREADCRUMBS_MAP.findIndex(item =>
            location.pathname.includes(item.id),
        );

        if (index === -1) {
            setPage(undefined);
        } else setPage(BREADCRUMBS_MAP[index]);
    }, [location]);

    const titleConstructor = useMemo(() => {
        return (
            <Box display="flex" alignItems="center">
                <div
                    className={classnames(style.item, {
                        [style.lightTitle]: !!subPage,
                    })}
                    id={`${id}-home-page-btn`}
                >
                    {title}
                </div>

                {subPage && (
                    <div className={style.item} id={`${id}-sub-page-btn`}>
                        <ArrowForwardIosIcon
                            fontSize="small"
                            className={style.arrow}
                        />
                        {subPage}
                        {!!itemCount && (
                            <div className={style.chip}>{itemCount}</div>
                        )}
                    </div>
                )}
            </Box>
        );
    }, [subPage, itemCount]);

    /**
     * Render
     */
    return (
        <Fragment>
            {title && (
                <Helmet>
                    <title>
                        {currentPage?.label
                            ? `${title} | ${currentPage.label}`
                            : `${title}`}
                    </title>
                </Helmet>
            )}

            <div className={style.wrapper}>{titleConstructor}</div>
        </Fragment>
    );
};

export default Breadcrumbs;
