// Lib
import React, { useEffect, useImperativeHandle } from "react";

// Own components
import { MaintenanceMilestone } from "@components";

// Hooks
import { useMaintenanceMilestone } from "@hooks";
import { Location } from "@types";

/**
 * Props type
 */

interface MilestoneProps {
    onDataReady?: () => void;
    location?: Location;
    id?: string;
}

interface MilestoneRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Milestone
 */
const Milestone = React.forwardRef<MilestoneRef, MilestoneProps>(
    ({ onDataReady, id }, ref) => {
        /**
         * API
         */

        const {
            list: { billingPlanList, milestoneList },
            loading,
            load: { loadBillingPlanList },
            upsert: { linkBillingPlan },
        } = useMaintenanceMilestone();

        useEffect(() => {
            if (
                milestoneList?.maintenanceMilestoneList &&
                !loading?.fetchingMilestones
            ) {
                onDataReady?.();
            }
        }, [milestoneList, loading?.fetchingMilestones]);

        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount:
                            milestoneList?.maintenanceMilestoneList?.length,
                    };
                },
            }),
            [milestoneList?.maintenanceMilestoneList], // Add dependency array here
        );

        /**
         * Render
         */
        return (
            <MaintenanceMilestone
                id={`${id}-milestone-list`}
                billingPlanList={
                    billingPlanList?.maintenanceLinkableBillingPlans
                }
                milestoneList={milestoneList?.maintenanceMilestoneList}
                loading={loading}
                loadBillingPlanList={loadBillingPlanList}
                onLinkBillingPlan={linkBillingPlan}
            />
        );
    },
);
export default Milestone;
