// extracted by mini-css-extract-plugin
export var action = "style-module--action--bf82f";
export var error = "#ff3131";
export var hasNoPagination = "style-module--hasNoPagination--90a6d";
export var header = "style-module--header--954c4";
export var headerItem = "style-module--headerItem--8ce45";
export var isClickable = "style-module--isClickable--f107c";
export var largeOverview = "style-module--largeOverview--f052e";
export var loadingOrNoContent = "style-module--loadingOrNoContent--f03a3";
export var overviewContent = "style-module--overviewContent--4eb86";
export var primary = "#036";
export var row = "style-module--row--e40a5";
export var secondary = "#019cdc";
export var tableContainer = "style-module--tableContainer--f3baf";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";