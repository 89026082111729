import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import * as React from "react";

// Style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Option {
    label: string;
    disabled?: boolean;
    hidden?: boolean;
    action?: () => void;
    link?: string;
    helperText?: string;
    icon?: React.ReactNode;
}
interface Props {
    options: Array<Option>;
    id: string;
    rowIndex?: number;
    autoHide?: boolean;
    disabled?: boolean;
    autoWidth?: boolean;
    stopPropagation?: boolean;
}

/**
 * Action menu
 */
const ActionMenu: React.FC<Props> = ({
    options,
    id,
    autoHide = false,
    disabled,
    autoWidth = false,
    stopPropagation = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close action menu
     */
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        if (stopPropagation) {
            e?.stopPropagation();
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="action-menu-button"
                id={`${id}-action-menu-button`}
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={e => {
                    if (stopPropagation) {
                        e.stopPropagation();
                    }
                    handleClick(e);
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={`${id}-menu`}
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: autoWidth ? "unset" : "20ch",
                    },
                }}
                className={style.list}
            >
                {options.map(
                    (option: Option, index: number) =>
                        (option.hidden === undefined || !option.hidden) && (
                            <MenuItem
                                key={option.label}
                                onClick={e => {
                                    if (!option?.action || !!option.disabled)
                                        return;

                                    autoHide && handleClose(e);
                                    return option.action();
                                }}
                                id={`${id}-action-menu-item-${index}`}
                                disabled={disabled || option.disabled}
                                className={classnames({
                                    [style.link]: option.link,
                                    [style.allowPointerEvent]:
                                        !!option?.helperText,
                                })}
                            >
                                <Box display="flex" alignItems="center">
                                    {option.disabled && (
                                        <LockIcon className={style.disabled} />
                                    )}

                                    {option.icon}

                                    {option.link ? (
                                        <a
                                            href={option.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Typography
                                                variant="caption1"
                                                className={style.item}
                                            >
                                                {option.label}
                                            </Typography>
                                        </a>
                                    ) : option?.helperText ? (
                                        <Tooltip
                                            arrow
                                            placement="left"
                                            title={option?.helperText}
                                        >
                                            <Typography
                                                variant="caption1"
                                                className={style.item}
                                            >
                                                {option.label}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography
                                            variant="caption1"
                                            className={style.item}
                                        >
                                            {option.label}
                                        </Typography>
                                    )}
                                </Box>
                            </MenuItem>
                        ),
                )}
            </Menu>
        </div>
    );
};

export default ActionMenu;
