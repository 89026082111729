//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Import utils
import { constantMapper } from "@utils";

// Styles
import * as style from "../../style.module.scss";

import { Country } from "types/api";

interface Row {
    type: string;
    contextId: string;
    country: Country;
    contextName: string;
    active: boolean;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    disabled?: boolean;
}

/**
 * Context row
 */
const ContextRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    disabled,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            className={style.row}
        >
            {isColVisible("contextName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contextName || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("type") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.type) || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.country?.name}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-task-row"`}
                        autoHide
                        disabled={disabled}
                        stopPropagation
                        options={[
                            {
                                label: "Edit",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onContextEdit(row, rowIndex),
                                disabled: row.active,
                            },
                            {
                                label: "Delete",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onContextDelete(row),
                                disabled: row.active,
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ContextRow;
