//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Types
import type { BillingPlanValues } from "@types";

// Styles
import * as style from "../../style.module.scss";

interface Props {
    row: BillingPlanValues;
    rowIndex: number;
    id: string;
    callbacks: any;
    isColVisible: (col: string) => boolean;
    disabled?: boolean;
}

const BillingPlanRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
    disabled,
}: Props) => {
    return (
        <TableRow className={style.row}>
            {isColVisible("salesOrg") && (
                <TableCell>
                    <Typography variant="caption2">{row.salesOrg}</Typography>
                </TableCell>
            )}

            {isColVisible("documentType") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.documentType}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("division") && (
                <TableCell>
                    <Typography variant="caption2">{row.division}</Typography>
                </TableCell>
            )}
            {isColVisible("planType") && (
                <TableCell>
                    <Typography variant="caption2">{row.planType}</Typography>
                </TableCell>
            )}

            {isColVisible("counter") && (
                <TableCell>
                    <Typography variant="caption2">{row.counter}</Typography>
                </TableCell>
            )}

            {isColVisible("days") && (
                <TableCell>
                    <Typography variant="caption2">{row.days}</Typography>
                </TableCell>
            )}

            {isColVisible("percentage") && (
                <TableCell>
                    <Typography variant="caption2">{row.percentage}</Typography>
                </TableCell>
            )}
            {isColVisible("paymentTerms") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.paymentTerms}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("billingBlock") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.billingBlock}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                {callbacks?.linkBillingPlan ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-${rowIndex}-link-billing-plan-row-btn"`}
                            autoHide
                            stopPropagation
                            options={[
                                {
                                    label: " Link to milestone",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.linkBillingPlan(
                                            row.billingPlanId,
                                        ),
                                    disabled: disabled,
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-${rowIndex}-billing-plan-row"`}
                            autoHide
                            stopPropagation
                            options={[
                                {
                                    label: "Edit",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.editItem(rowIndex),
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default BillingPlanRow;
