//libs
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Button, Typography, Divider } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { useRecoilState } from "recoil";

//own components
import { Breadcrumbs } from "@components";

//types
import type { Location, Team, Permissions } from "@types";

// Hooks
import { usePermission, useLandingPage } from "@hooks";

// Atoms
import { teamState } from "@atoms";
import { HEADERS } from "@constants";
/**
 * Props type
 */
interface Props {
    children?: React.ReactNode;
    location: Location;
}

/**
 * Account layout
 */
const AccountLayout = ({ children, location }: Props) => {
    const [selectedTeam, setTeam] = useState<Team | undefined>(undefined);
    const [team, storeTeam] = useRecoilState<any>(teamState);
    const { hasPermissionToEditMaintenance }: Permissions = usePermission();
    const { isUserWithRights } = useLandingPage();

    useEffect(() => {
        if (!team) return;
        setTeam(team);
    }, [team]);

    /**
     * Is select team page
     */
    const isSelectTeamPage = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("/select-team");
    }, [location]);

    const clearCache = () => {
        Object.keys(HEADERS).map(key => localStorage.removeItem(key));
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                minHeight: "calc(100vh - 4rem)",
                overflowY: "scroll",
            }}
        >
            <div>
                <Breadcrumbs
                    id="account-layout"
                    icon={
                        isSelectTeamPage ? (
                            <GroupsIcon color="primary" />
                        ) : (
                            <PersonOutlineIcon color="primary" />
                        )
                    }
                    title={isSelectTeamPage ? "Select team" : "Account"}
                    location={location}
                    subPage={
                        isSelectTeamPage ? "Select team" : "Account details"
                    }
                />
                <Box px={7}>
                    {React.Children.map(
                        children as React.ReactElement,
                        (child: React.ReactElement) =>
                            React.cloneElement(child, {
                                location,
                                selectedTeam,
                                setTeam,
                                id: `create-contract`,
                                isSelectTeamPage,
                            }),
                    )}
                </Box>
            </div>
            {isSelectTeamPage && (
                <Box px={7}>
                    <Divider variant="fullWidth" />

                    <Box display="flex" justifyContent="flex-end" mt={3}>
                        {(hasPermissionToEditMaintenance || !!team) && (
                            <Button
                                id={`cancel-team-confirm-btn`}
                                variant="outlined"
                                size="large"
                                disableElevation
                                color="primary"
                                disabled={!selectedTeam}
                                sx={{ marginRight: "1rem" }}
                                onClick={() =>
                                    !isUserWithRights &&
                                    hasPermissionToEditMaintenance
                                        ? (window.location.href =
                                              "/maintenance/product/")
                                        : (window.location.href = "/")
                                }
                            >
                                <Typography variant="button">Cancel</Typography>
                            </Button>
                        )}

                        <Button
                            id={`select-team-confirm-btn`}
                            variant="contained"
                            size="large"
                            disableElevation
                            color="primary"
                            disabled={!selectedTeam}
                            onClick={() => {
                                if (!selectedTeam) return;
                                storeTeam(selectedTeam);
                                clearCache();
                                window.location.href = "/";
                            }}
                        >
                            <Typography variant="button">
                                Select team
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
export default AccountLayout;
