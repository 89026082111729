// Libs
import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";
import { Country } from "types/api";
import { truncateString, splitStringWithDash } from "@utils";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    country: Country;
    regionName: string;
    regionCode: string;
    type: string;
    tenderRegionCode: {
        regionName: string;
        regionCode: string;
        regionId: string;
        type: string;
    };
    parentRegionCode: {
        regionName: string;
        regionCode: string;
        regionId: string;
        type: string;
    };
    regionAccountField: string;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    isColVisible: (col: string) => boolean;
    permissions: any;
}

/**
 * Partners Row
 */
const PartnersRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
    permissions,
}: Props) => {
    return (
        <TableRow
            className={style.row}
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("type") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.type || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("code") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.regionCode || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("name") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.regionName || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("tenderRegionCode") && (
                <TableCell>
                    {row.tenderRegionCode?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.tenderRegionCode, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.tenderRegionCode, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("parentRegionCode") && (
                <TableCell>
                    {row.parentRegionCode?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.parentRegionCode, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.parentRegionCode, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("regionAccountField") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.regionAccountField || "-"}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                {permissions && permissions.hasPermissionToEditDataTables && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-row-${rowIndex}-partner`}
                            autoHide
                            stopPropagation
                            options={[
                                {
                                    label: "Edit",
                                    action: () =>
                                        callbacks &&
                                        callbacks.onEdit(row, rowIndex),
                                },
                                {
                                    label: "Delete",
                                    action: () =>
                                        callbacks &&
                                        callbacks.onDelete(row, rowIndex),
                                },
                                {
                                    label: "External codes",
                                    disabled: !callbacks?.onExternalCodesEdit,
                                    action: () =>
                                        callbacks &&
                                        callbacks.onExternalCodesEdit(
                                            row,
                                            rowIndex,
                                        ),
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default PartnersRow;
