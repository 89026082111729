// Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Own components
import { Table, Dialog } from "@components";

//custom hooks
import { useGetContractCategory, useUpdateStep } from "@hooks";

// Atoms
import {
    productsDetailsState,
    selectCategoriesState,
    selectModelState,
    generalInformationState,
    contractPartnersState,
    createVolumeBasedContractStepsState,
} from "@atoms";

// Types
import type { FlowStep, Location } from "@types";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    flowState?: Array<FlowStep>;
}

/**
 * Category Tags
 */
const CategoryTags = ({ location, flowState }: Props) => {
    const [resetFlowWarning, setResetFlowWarning] =
        useState<undefined>(undefined);

    const selectedModel = useRecoilValue(selectModelState);
    const { products } = useRecoilValue(productsDetailsState);

    const [volumeBasedSteps, setVolumeBasedSteps] = useRecoilState(
        createVolumeBasedContractStepsState,
    );

    // Reset partially
    const resetGeneralInformationState = useResetRecoilState(
        generalInformationState,
    );
    const resetProductsDetailsState = useResetRecoilState(productsDetailsState);
    const resetContractPartnersState = useResetRecoilState(
        contractPartnersState,
    );

    /**
     * Reset only general info related states if the type is volume
     * otherwise reset all
     */
    const resetOnlyCategoriesRelatedStates = () => {
        resetGeneralInformationState();
        resetProductsDetailsState();
        resetContractPartnersState();

        const copySteps = [...volumeBasedSteps];
        const updatedSteps = copySteps.map(el => {
            if (el?.id === "model" || el?.id === "category-tags") return el;
            return { ...el, isPrepared: false, isCompleted: false };
        });
        setVolumeBasedSteps(updatedSteps);
    };

    /**
     * Get data model list
     */
    const {
        list: categoryList,
        loading: fetching,
        load: loadList,
    } = useGetContractCategory();

    /**
     * Recoil states
     */
    // categories state
    const [selectedCategories, updateCategories] = useRecoilState(
        selectCategoriesState,
    );

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    /**
     *  Update step hook
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    /**
     * Save state and go to the next page (controlled by the layout)
     */
    useEffect(() => {
        updateStepValidation(isArrayWithContent(selectedCategories));
    }, [selectedCategories]);

    /**
     * Fetch category list based on the selected model
     */
    useEffect(() => {
        if (!selectedModel?.datamodelId) return;
        loadList(selectedModel.datamodelId);
    }, [selectedModel]);

    /**
     * Map categories and store it as a default value in the atom
     * if the category has the same name ( usually it's already grouped by BE)
     * and the same value the user must select only one
     */
    useEffect(() => {
        if (
            isArrayWithContent(selectedCategories) ||
            !isArrayWithContent(categoryList?.data?.categories)
        ) {
            return;
        }

        const mapCats = categoryList?.data?.categories.map(item => {
            return {
                categoryLabel: item.categoryLabel,
                categoryName: item.categoryName,
                ...item?.categories[0],
            };
        });

        updateCategories(mapCats);
    }, [categoryList?.data?.categories, selectedCategories]);

    /**
     * Update categories state
     */
    const handleUpdateCategories = category => {
        const copyCats = [...selectedCategories];

        const foundCatIndex = isArrayWithContent(copyCats)
            ? copyCats.findIndex(
                  item => category?.categoryLabel === item?.categoryLabel,
              )
            : -1;

        if (foundCatIndex === -1) return {};

        if (foundCatIndex > -1) {
            copyCats[foundCatIndex] = {
                ...category,
            };

            updateCategories(copyCats);
        }
    };

    /**
     * Find Selected Category
     */
    const findSelectedCategory = useCallback(
        (categoryName: string) => {
            if (!categoryName || !isArrayWithContent(selectedCategories))
                return false;

            const foundCat = selectedCategories.find(
                item => item.categoryName === categoryName,
            );

            return foundCat;
        },
        [selectedCategories],
    );

    /**
     * Render
     */
    return (
        <Box display="flex" flexDirection="column">
            <Dialog
                title={"Reinitialize Contract Creation Process"}
                open={!!resetFlowWarning}
                id={`reset-flow`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        {`You are about to change a category. Doing so will remove the filled data. This action cannot be undone.`}
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: () => {
                        resetOnlyCategoriesRelatedStates();
                        handleUpdateCategories(resetFlowWarning);
                        setResetFlowWarning(undefined);
                    },
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setResetFlowWarning(undefined),
                }}
            />

            <Box mb={7}>
                <Typography variant="h2">Select category</Typography>
            </Box>

            <Table
                id="model-list"
                headers={HEADERS.CONTRACT_CATEGORY}
                rows={categoryList?.data?.categories}
                loading={fetching}
                type={ROWRENDERERCONST.CONTRACT_CATEGORY}
                callbacks={{
                    onCategorySelect: (category: any) => {
                        if (isArrayWithContent(products)) {
                            setResetFlowWarning(category);
                        } else handleUpdateCategories(category);
                    },
                    findSelectedCategory: (categoryName: string) =>
                        findSelectedCategory(categoryName),
                }}
                maxHeight="30rem"
                disabled={fetching}
                emptyMsg="No categories found!"
                isOverview={false}
            />
        </Box>
    );
};

export default React.memo(CategoryTags);
