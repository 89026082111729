// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";

//Lib
import React from "react";

import { convertDateForAPI, datePlaceholder } from "@utils";
import { DatePicker } from "@mui/x-date-pickers";
/**
 * Props type
 */
interface Props {
    onChange: (date: any) => void;
    value: string;
    onClear?: () => void;
}

/**
 * Date Filter
 */
const DateFilter: React.FC<Props> = ({ onChange, value, onClear }) => {
    return (
        <DatePicker
            value={datePlaceholder(value)}
            onChange={value => {
                if (!value || !value?.isValid) {
                    onChange("");
                    return;
                }

                if (value) {
                    onChange(convertDateForAPI(value));
                }
            }}
            format="dd/MM/yyyy"
            slotProps={{
                textField: {
                    variant: "outlined",
                    autoComplete: "off",
                    sx: {
                        svg: { color: "#036", opacity: "0.5" },
                    },
                    size: "small",
                    fullWidth: true,
                    placeholder: "dd/mm/yyyy",
                    required: true,
                    name: "dateFilter",
                    InputProps: {
                        endAdornment: onClear ? (
                            <CloseIcon
                                onClick={onClear}
                                sx={{ cursor: "pointer" }}
                            />
                        ) : undefined,
                    },
                },
            }}
            slots={{
                openPickerIcon: CalendarTodayIcon,
            }}
        />
    );
};

export default React.memo(DateFilter);
