// icon
import { Add } from "@mui/icons-material";

// Lib
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import clonedeep from "lodash.clonedeep";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { Fragment, useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";

// Own components
import {
    GrayBox,
    Modal,
    Table,
    AddProduct,
    FieldRenderer,
    Card,
} from "@components";

// Types
import {
    Brand,
    PriceConditionType,
    Model,
    Category,
    AdvancedScale,
} from "@types";

// Utils
import { getContractSubheader, isArrayWithContent } from "@utils";

// Constants

// Hooks
import { useBrands } from "@hooks";

// Constants
import { ROWRENDERERCONST, HEADERS, CATEGORY_TYPE_MAPPER } from "@constants";

/**
 * Brand type
 */
interface BrandType extends Brand {
    name?: string;
    id?: string;
}

type Product = {
    contractPrice: null | string;
    currency: string;
    discountPercentage: null | string;
    externalProductCode: string | undefined;
    priceCondition: { price: string; currency?: string };
    productId: string;
    productName: string;
    sapSkuNo: string;
    scales: Array<AdvancedScale>;
};

type ProductDetailsType = Array<Product>;

/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    productsDetails?: ProductDetailsType;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (value: Record<string, any>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    id: string;
    shrinkColumns?: boolean;
    brand: BrandType;
    isEditContract?: boolean;
    generalInformation: any;
    productsList?: {
        load: (brandId: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
        disabledProducts?: Array<string>;
    };
    productPrices?: {
        load: (sapSkuNo: string, pricesFrom: string, pricesTo: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
    };
    model?: Model;
    contractType?: "VOLUME" | "PERFORMANCE";
    isCreateContract?: boolean;
    categories?: Array<Category>;
    currency: string;
}

/**
 * ProductsDetailsSummary
 */
const ProductsDetailsSummary: React.FC<Props> = ({
    productsDetails,
    id,
    hideEdit = false,
    callbacks,
    shrinkColumns,
    brand,
    isEditContract,
    productsList,
    generalInformation,
    productPrices,
    model,
    contractType,
    disableEdit,
    isCreateContract,
    categories,
    currency,
}) => {
    const [showPrices, togglePricesModal] = useState({
        productName: undefined,
        sapSkuNo: undefined,
    });

    const [touched, setTouched] = useState({});
    const [editProduct, setProduct]: any = useState();

    const [selectedBrand, setBrand] = useState<BrandType | undefined>(
        undefined,
    );
    const [scales, setScales] = useState<AdvancedScale[]>([]);

    /**
     * Api
     */
    // Brands
    const {
        list: brands,
        loading: loadingBrands,
        error: brandsError,
        load: loadBrands,
    } = useBrands(false);

    const resetProductStates = () => {
        setScales([]);
        setProduct(undefined);
        setBrand(undefined);
        setTouched({});
    };

    /**
     * Category type
     */

    const categoryType = useMemo(() => {
        if (!categories || !isArrayWithContent(categories)) return;

        const foundCat = categories.find(cat => {
            return CATEGORY_TYPE_MAPPER[
                cat?.categoryId?.split("_")?.slice(1)?.join("_")
            ];
        });

        if (!foundCat?.categoryId) return;

        return CATEGORY_TYPE_MAPPER[
            foundCat?.categoryId?.split("_")?.slice(1)?.join("_")
        ];
    }, [categories]);

    /**
     * Can select price
     * if price source exists & price source is SAP
     *         => user needs to enter it manually
     * if the source is LAUER
     *          => the user can't select any price from price table ( the condition will be set from the model table)
     */
    const canSelectPriceCondition = useMemo(() => {
        if (!isEditContract) return true; // performance based contract

        return model?.priceSource === "SAP";
    }, [model, isEditContract]);

    /**
     * Map product for Add product component
     */
    const onInitializeProduct = (item?: any, index?: number) => {
        if (item && index !== undefined) {
            setProduct({
                index: index,
                product: {
                    productId: item.productId,
                    productName: item.productName,
                    sapSkuNo: item.sapSkuNo,
                    externalProductCode: item.externalProductCode,
                },
                priceCondition: item.priceCondition,
                priceSource: item.priceSource || model?.priceSource,
                amount: item.discountPercentage || item.contractPrice || "",
                type: isArrayWithContent(item.scales)
                    ? "scale"
                    : item.discountPercentage
                      ? "discountPercentage"
                      : item.contractPrice
                        ? "contractPrice"
                        : "",
                scales: item.scales,
                brand: {
                    brandName: item?.brand?.name
                        ? item?.brand?.name
                        : item?.brand?.brandName,
                    brandId: item?.brand?.id
                        ? item?.brand?.id
                        : item?.brand?.brandId,
                },
            });
        } else
            setProduct({
                index: productsDetails?.length,
                product: {},
                priceCondition: canSelectPriceCondition ? {} : undefined,
                priceSource: model?.priceSource,
                amount: undefined,
                type: categoryType,
                scales: [],
            });
    };

    useEffect(() => {
        if (
            !showPrices?.sapSkuNo ||
            !generalInformation ||
            !productPrices?.load
        )
            return;

        productPrices?.load(
            showPrices.sapSkuNo,
            generalInformation.startDate,
            generalInformation.endDate,
        );
    }, [showPrices?.sapSkuNo]);

    /**
     * Initialize product and brand
     */
    useEffect(() => {
        if (!editProduct || editProduct?.index === undefined) {
            isEditContract &&
                !!callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);

            return;
        }
        // Load brands
        loadBrands();

        if (editProduct?.product?.productId) {
            // Store brand in local state
            setBrand(editProduct?.brand);
            // Fetch all products that belong to the selected brand
            productsList?.load(editProduct?.brand?.brandId);
        }

        callbacks?.setDisableSaveButton &&
            callbacks?.setDisableSaveButton(true);
    }, [editProduct?.index]);

    /**
     * Initialize product and brand
     * Reset the current product if the user change the brand
     */
    useEffect(() => {
        if (
            !productsList?.load ||
            !selectedBrand ||
            editProduct?.brand?.brandId === selectedBrand?.brandId
        )
            return; // is already initialized

        productsList?.load(selectedBrand?.brandId);

        const copyProductMapper = clonedeep(editProduct);
        copyProductMapper.product = {};

        setProduct(copyProductMapper);
    }, [selectedBrand]);

    /**
     * Handle delete
     */
    const onDelete = (index: number) => {
        if (
            !callbacks?.onEditContractItem ||
            !productsDetails ||
            !isArrayWithContent(productsDetails)
        )
            return;

        const copyProducts = [...productsDetails];
        const filtered = copyProducts.filter((_, idx) => idx !== index);

        callbacks?.onEditContractItem({ products: filtered });
        resetProductStates();
    };

    /**
     * Save product
     */
    const onProductSave = () => {
        if (
            !callbacks?.onEditContractItem ||
            !editProduct?.product?.productId ||
            (!editProduct?.priceCondition && !editProduct?.priceSource)
        )
            return;

        const copyProducts = clonedeep(productsDetails);

        copyProducts[editProduct.index] = {
            productId: editProduct?.product?.productId,
            productName: editProduct?.product?.productName,
            sapSkuNo: editProduct?.product?.sapSkuNo,
            externalProductCode: editProduct?.product?.externalProductCode,
            priceCondition:
                typeof editProduct?.priceCondition === "string" ||
                editProduct?.priceCondition === undefined
                    ? editProduct?.priceCondition
                    : { ...editProduct?.priceCondition },
            priceSource: editProduct?.priceSource,
            scales: editProduct.type !== "scale" ? [] : [...editProduct.scales],
            [editProduct.type]:
                editProduct.type === "scale" ? null : editProduct.amount,
            brand: selectedBrand ? { ...selectedBrand } : undefined,
        };

        callbacks?.onEditContractItem({ products: copyProducts });
        resetProductStates();
    };

    /**
     * Change price handler
     */
    const handlePriceChange = (value: any) => {
        if (contractType !== "VOLUME") return;

        const copyProduct = clonedeep(editProduct);
        copyProduct["priceCondition"] = value;
        setProduct(copyProduct);
    };

    /**
     * Can edit products
     */
    const canEditProducts = useMemo(() => {
        if (!contractType) return false;
        return contractType === "VOLUME";
    }, [contractType]);

    /**
     * Handle blur
     */
    const onBlur = (e: React.BaseSyntheticEvent, key?: string) => {
        const copyTouched = { ...touched };
        const name = key ? key : e.target.name;
        if (copyTouched[name]) return;
        Object.assign(copyTouched, {
            [name]: true,
        });

        setTouched(copyTouched);
    };

    /**
     * Map errors
     */
    const mapErrors = useMemo(() => {
        if (!editProduct) return {};
        return {
            amount:
                !editProduct.amount &&
                (editProduct.type === "contractPrice" ||
                    editProduct.type === "discountPercentage"),
            brand: !selectedBrand,
            product: !editProduct.product?.productId,
            type: !editProduct.type,
        };
    }, [editProduct, selectedBrand]);

    /**
     * Disable duplicate product
     */
    const disableSelectedProducts = useMemo(() => {
        if (!isArrayWithContent(productsDetails)) return [];

        return productsDetails?.map((el, idx) => {
            if (idx !== editProduct?.index) return el?.productId;
        });
    }, [productsDetails, editProduct]);

    /**
     * Get subheader
     */
    const getSubheader = (
        product: Product & {
            product?: { sapSkuNo?: string };
            type?: "discountPercentage" | "contractPrice" | "scale";
            amount?: number;
            priceSource?: string;
            priceCondition: string | { priceCondition: string; price: string };
        },
    ) => {
        return getContractSubheader(product, currency);
    };

    const getHeader = (product: {
        productName?: string;
        product?: { productName?: string };
    }) => {
        const productName =
            product?.product?.productName || product?.productName;
        const brandName = brand?.brandName || brand?.name;

        return (
            <Typography variant="h3">
                {brandName ? `${productName} (${brandName})` : productName}
            </Typography>
        );
    };

    /**
     *
     * @param product {Product}
     * @param index number
     * @returns { onClick: () => void; text: string;}
     */
    const handlePrimaryAction = (product, index) => {
        if (canEditProducts && isEditContract) {
            return {
                text: !isEditContract ? "Edit" : undefined,
                onClick: () => onInitializeProduct(product, index),
                disabled:
                    disableEdit ||
                    (!!editProduct?.productId &&
                        editProduct.productId !== product.productId),
            };
        }

        if (isArrayWithContent(product.scales)) {
            return {
                onClick: () => setScales(product.scales),
                text: "Show scales",
            };
        }

        return undefined;
    };

    /**
     * Check if the user can input values
     */

    const { canAddFixedPrice, canAddDiscountPercentage, canAddScales } =
        useMemo(() => {
            const canAddDiscountPercentage =
                categoryType === "discountPercentage" &&
                (model?.name === "DEVOLVED_NATION_PERCENTAGE" ||
                    (model?.name !== "DEVOLVED_NATION_PERCENTAGE" &&
                        model?.priceSource !== "iTENDER"));

            const canAddFixedPrice =
                categoryType === "contractPrice" &&
                model?.priceSource !== "iTENDER";

            const canAddScales =
                model?.name !== "DEVOLVED_NATION_PERCENTAGE" &&
                model?.priceSource !== "iTENDER";

            return {
                canAddFixedPrice,
                canAddDiscountPercentage,
                canAddScales,
            };
        }, [model, categoryType]);

    /**
     * Map cumulative scale row data
     */
    const mapCumulativeScaleRowData = useMemo(() => {
        if (!isArrayWithContent(scales)) return [];

        const groupedArray: {
            periodFrom: string;
            periodTo: string;
            data: {
                cumulativePeriodFrom: string;
                cumulativePeriodTo: string;
                unitOfMeasureFrom: string;
                unitOfMeasureTo: string;
                discountPercentage: string;
                price: string;
                currency: string;
            }[];
        }[] = Object.values(
            scales.reduce(
                (acc, scale) => {
                    const key = `${scale.periodFrom}|${scale.periodTo}`;

                    if (!acc[key]) {
                        acc[key] = {
                            periodFrom: scale.periodFrom,
                            periodTo: scale.periodTo,
                            data: [],
                        };
                    }

                    (scale as AdvancedScale).cumulativePeriods.forEach(
                        period => {
                            acc[key].data.push({
                                cumulativePeriodFrom:
                                    period.cumulativePeriodFrom,
                                cumulativePeriodTo: period.cumulativePeriodTo,
                                unitOfMeasureFrom: scale.unitOfMeasureFrom,
                                unitOfMeasureTo: scale.unitOfMeasureTo,
                                discountPercentage:
                                    scale.discountPercentage as string,
                                price: scale.price as string,
                                currency: currency,
                            });
                        },
                    );

                    return acc;
                },
                {} as Record<
                    string,
                    {
                        periodFrom: string;
                        periodTo: string;
                        data: {
                            cumulativePeriodFrom: string;
                            cumulativePeriodTo: string;
                            unitOfMeasureFrom: string;
                            unitOfMeasureTo: string;
                            discountPercentage: string;
                            price: string;
                            currency: string;
                        }[];
                    }
                >,
            ),
        );

        // Sort data by cumulativePeriodFrom and cumulativePeriodTo using Luxon
        groupedArray.forEach(group => {
            group.data.sort((a, b) => {
                // Convert to Luxon DateTime objects for comparison
                const dateAFrom = DateTime.fromISO(a.cumulativePeriodFrom);
                const dateBFrom = DateTime.fromISO(b.cumulativePeriodFrom);
                const dateATo = DateTime.fromISO(a.cumulativePeriodTo);
                const dateBTo = DateTime.fromISO(b.cumulativePeriodTo);

                // Sort by cumulativePeriodFrom first
                if (dateAFrom.valueOf() !== dateBFrom.valueOf()) {
                    return dateAFrom.valueOf() - dateBFrom.valueOf();
                }

                // If cumulativePeriodFrom is the same, sort by cumulativePeriodTo
                return dateATo.valueOf() - dateBTo.valueOf();
            });
        });

        return groupedArray;
    }, [scales]);

    /**
     * Check the type of the contract
     */
    const { scaleType } = useMemo(() => {
        return {
            scaleType:
                categoryType === "discountPercentage"
                    ? "discountPercentage"
                    : "price",
        };
    }, [categoryType]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                open={!!showPrices?.productName}
                id={`${id}-contracts-products-prices`}
                title={"Price table"}
                onClose={() =>
                    togglePricesModal({
                        sapSkuNo: undefined,
                        productName: undefined,
                    })
                }
            >
                <div>
                    <Grid container item xs={12} spacing={3} mb={5}>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-dashboard-products-overview-products-prices-product-name`}
                                label="Product name"
                                value={showPrices?.productName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-dashboard-products-overview-products-prices-product-sku-number`}
                                label="SAP SKU number"
                                value={showPrices?.sapSkuNo}
                            />
                        </Grid>
                    </Grid>

                    <Table
                        headers={HEADERS.PRICES}
                        rows={productPrices?.data || []}
                        loading={productPrices?.loading as boolean}
                        type={ROWRENDERERCONST.PRICES}
                        id={`${id}-products-prices`}
                        emptyMsg={"No data"}
                        callbacks={{
                            selectCondition: (
                                priceCondition: PriceConditionType,
                            ) => {
                                handlePriceChange({
                                    priceCondition: priceCondition?.condition,
                                    currency: priceCondition?.currency,
                                    price: priceCondition?.price,
                                });

                                togglePricesModal({
                                    sapSkuNo: undefined,
                                    productName: undefined,
                                });
                            },
                        }}
                        isOverview={false}
                    />
                </div>
            </Modal>

            <Modal
                open={isArrayWithContent(scales)}
                id={`contract-product-scales-overview`}
                title={"Product scales"}
                onClose={() => setScales([])}
            >
                <Table
                    headers={HEADERS.ADVANCED_SCALES_WITH_ACTION.filter(
                        item => {
                            if (
                                item.rowKey !== "discountPercentage" &&
                                item.rowKey !== "price"
                            ) {
                                return true;
                            }

                            return item.rowKey === scaleType;
                        },
                    )}
                    rows={mapCumulativeScaleRowData || []}
                    loading={false}
                    type={ROWRENDERERCONST.ADVANCED_SCALES_WITH_ACTION}
                    id={`${id}-product-scales-table`}
                    emptyMsg={"No scales found"}
                    specificKeys={{ currency }}
                    isOverview={false}
                />
            </Modal>

            <Grid item xs={12} md={shrinkColumns ? 6 : 12}>
                <Card
                    title="Product details"
                    id={`${id}-product-details`}
                    action={{
                        hidden:
                            !isCreateContract ||
                            hideEdit ||
                            (isEditContract && contractType === "PERFORMANCE"),
                        disabled: disableEdit,
                        icon: <EditIcon fontSize="small" />,
                        action: () =>
                            !!callbacks?.onEditClick &&
                            callbacks.onEditClick("product-details"),
                    }}
                >
                    {!!productsDetails &&
                        !!isArrayWithContent(productsDetails) &&
                        productsDetails.map((product: any, index) => (
                            <Fragment key={index}>
                                {canEditProducts &&
                                !!isEditContract &&
                                editProduct?.index === index ? (
                                    <AddProduct
                                        id={`${id}-edit-product-form`}
                                        onChange={setProduct}
                                        onDelete={() => onDelete(index)}
                                        onSave={onProductSave}
                                        onBlur={onBlur}
                                        touched={touched}
                                        product={editProduct}
                                        currency={currency}
                                        products={{
                                            data: productsList?.data || [],
                                            loading:
                                                productsList?.loading as boolean,
                                            error: productsList?.error,
                                            disabledProducts:
                                                disableSelectedProducts as string[],
                                        }}
                                        canDelete={productsDetails?.length > 1}
                                        canDiscard
                                        onDiscard={resetProductStates}
                                        brands={{
                                            data: brands?.data,
                                            loading: loadingBrands,
                                            error: brandsError,
                                        }}
                                        setBrand={setBrand}
                                        brand={selectedBrand}
                                        onShowPricesClick={() =>
                                            togglePricesModal({
                                                productName:
                                                    editProduct?.product
                                                        ?.productName,
                                                sapSkuNo:
                                                    editProduct?.product
                                                        ?.sapSkuNo,
                                            })
                                        }
                                        canSelectPriceCondition={
                                            canSelectPriceCondition
                                        }
                                        type={editProduct?.type}
                                        amount={editProduct?.amount}
                                        scales={editProduct?.scales}
                                        isVolumeBasedContract
                                        canAddMultipleProducts
                                        allowedScaleRange={{
                                            periodFrom:
                                                generalInformation.startDate,
                                            periodTo:
                                                generalInformation.endDate,
                                        }}
                                        handleProductScales={scales =>
                                            setProduct({
                                                ...editProduct,
                                                scales,
                                            })
                                        }
                                        categoryType={categoryType}
                                        canAddFixedPrice={canAddFixedPrice}
                                        canAddScales={canAddScales}
                                        canAddDiscountPercentage={
                                            canAddDiscountPercentage
                                        }
                                        errors={mapErrors}
                                    />
                                ) : (
                                    <GrayBox
                                        padding={"1rem"}
                                        marginY={"1rem"}
                                        key={index}
                                        id={`${id}-product-details`}
                                        primaryAction={handlePrimaryAction(
                                            product,
                                            index,
                                        )}
                                        subheaderSplitter={getSubheader(
                                            product,
                                        )}
                                        header={getHeader(product)}
                                    />
                                )}
                            </Fragment>
                        ))}

                    {!!isEditContract && canEditProducts && (
                        <Fragment>
                            {!!isEditContract &&
                                !!productsDetails &&
                                editProduct?.index ===
                                    productsDetails.length && (
                                    <AddProduct
                                        id={`${id}-add-product-form`}
                                        onChange={setProduct}
                                        onDelete={() =>
                                            onDelete(editProduct?.index)
                                        }
                                        touched={touched}
                                        onSave={onProductSave}
                                        product={editProduct}
                                        products={{
                                            data: productsList?.data || [],
                                            loading:
                                                productsList?.loading as boolean,
                                            error: productsList?.error,
                                            disabledProducts:
                                                disableSelectedProducts as string[],
                                        }}
                                        errors={mapErrors}
                                        onBlur={onBlur}
                                        onShowPricesClick={() =>
                                            togglePricesModal({
                                                productName:
                                                    editProduct?.product
                                                        ?.productName,
                                                sapSkuNo:
                                                    editProduct?.product
                                                        ?.sapSkuNo,
                                            })
                                        }
                                        brands={{
                                            data: brands?.data,
                                            loading: loadingBrands,
                                            error: brandsError,
                                        }}
                                        currency={currency}
                                        brand={selectedBrand}
                                        setBrand={setBrand}
                                        canSelectPriceCondition={
                                            canSelectPriceCondition
                                        }
                                        canAddFixedPrice={canAddFixedPrice}
                                        canAddScales={canAddScales}
                                        canAddDiscountPercentage={
                                            canAddDiscountPercentage
                                        }
                                        type={editProduct?.type}
                                        amount={editProduct?.amount}
                                        scales={editProduct?.scales}
                                        canAddMultipleProducts
                                        isVolumeBasedContract
                                        allowedScaleRange={{
                                            periodFrom:
                                                generalInformation.startDate,
                                            periodTo:
                                                generalInformation.endDate,
                                        }}
                                        handleProductScales={scales =>
                                            setProduct({
                                                ...editProduct,
                                                scales,
                                            })
                                        }
                                        categoryType={categoryType}
                                    />
                                )}

                            <GrayBox
                                padding={2}
                                marginY={1}
                                id={`${id}-add-product-btn`}
                                header={
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => onInitializeProduct()}
                                        startIcon={<Add />}
                                        size="large"
                                        disabled={
                                            disableEdit ||
                                            editProduct?.index !== undefined
                                        }
                                    >
                                        <Typography
                                            variant="caption1"
                                            color={
                                                disableEdit ||
                                                editProduct?.index !== undefined
                                                    ? "action.disabled"
                                                    : "primary"
                                            }
                                        >
                                            {`Add product`}
                                        </Typography>
                                    </Button>
                                }
                            />
                        </Fragment>
                    )}
                </Card>
            </Grid>
        </Fragment>
    );
};

export default React.memo(ProductsDetailsSummary);
