//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import classnames from "classnames";
import React from "react";

// Utils
import { constantMapper, displayDate } from "@utils";

// Components
import { ActionMenu } from "@components";

// Constants
import { ORDER_STATUSES } from "@constants";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    contractRef: string;
    treatmentSite: string;
    orderDate: string;
    orderStatus: string;
    brandName: string;
    infusionDate: string;
    indicationName: string;
    sapSkuNo: string;
    orderId: string;
    country: string;
    salessystemOrderId: string;
    sapOrderNo: string;
    unlinkable: boolean;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    permissions: any;
    isColVisible: (col: string) => boolean;
}

/**
 * Order overview
 */
const OrdersOverviewRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    permissions,
    isColVisible,
}: Props) => {
    return (
        <TableRow
            className={classnames(style.row, style.isClickable)}
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            onClick={() => {
                callbacks && callbacks.onViewOrder(row);
            }}
        >
            {isColVisible("treatmentSite") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.treatmentSite || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("infusionDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.infusionDate ? displayDate(row.infusionDate) : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("cquenceId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("contractReference") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("brand") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.brandName || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("sapOrderNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapOrderNo}</Typography>
                </TableCell>
            )}

            {isColVisible("orderDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.orderDate)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("orderStatus") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.orderStatus === "N/A"
                            ? row.orderStatus
                            : constantMapper(row.orderStatus)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("indication") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.indicationName}
                    </Typography>
                </TableCell>
            )}
            <TableCell align="right" className={style.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-row-${rowIndex}-order`}
                        autoHide
                        stopPropagation={true}
                        options={[
                            /*  {
                                label: "View order",
                                action: () =>
                                    callbacks && callbacks.onViewOrder(row),
                                disabled:
                                    !permissions?.hasPermissionToEditOrder &&
                                    !permissions?.hasPermissionToCreateOrder &&
                                    !permissions?.hasPermissionToReadOrder,
                            }, */
                            {
                                label: "Edit order status",
                                action: () =>
                                    callbacks &&
                                    callbacks.onEditOrder(row, rowIndex),
                                disabled:
                                    !permissions?.hasPermissionToEditOrder ||
                                    !ORDER_STATUSES[row.orderStatus],
                            },
                            {
                                label: "Link order to contract",
                                action: () =>
                                    callbacks &&
                                    callbacks.onLinkOrder(row, rowIndex),
                                disabled:
                                    !permissions?.hasPermissionToEditOrder ||
                                    (!permissions?.kanLinkWithoutInfusionDate &&
                                        !row.infusionDate) ||
                                    !row.sapSkuNo ||
                                    row.orderStatus !== "NON_LINKED",
                                hidden: row.unlinkable,
                            },
                            {
                                label: "Unlink order",
                                action: () =>
                                    callbacks &&
                                    callbacks.onUnlinkOrder(row, rowIndex),
                                hidden: !row.unlinkable,
                                disabled:
                                    !permissions?.hasPermissionToEditOrder,
                            },
                            {
                                label: "Add outcome",
                                action: () =>
                                    callbacks &&
                                    callbacks.onAddOutcome(row, rowIndex),
                                disabled:
                                    !permissions?.hasPermissionToEditOutcome ||
                                    row.orderStatus !== "ACTIVE",
                            },
                            {
                                label: row?.infusionDate
                                    ? "Edit infusion date"
                                    : "Add infusion date",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onInfusionDateChange &&
                                    callbacks?.onInfusionDateChange(row),
                                disabled:
                                    !permissions?.hasPermissionToEditOrder ||
                                    row.orderStatus !== "NON_LINKED",
                                helperText:
                                    row.orderStatus !== "NON_LINKED"
                                        ? "The infusion date for this order cannot be changed. All Outcomes and Financial Conditions for this must be cancelled and the Order must be unlinked form the Contract before being able to change the infusion date"
                                        : undefined,
                            },
                        ]}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default OrdersOverviewRow;
