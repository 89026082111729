// Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GridOn from "@mui/icons-material/GridOn";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LockIcon from "@mui/icons-material/Lock";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AutoModeIcon from "@mui/icons-material/AutoMode";

// Lib
import { StaticImage } from "gatsby-plugin-image";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import { navigate } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResetRecoilState, useRecoilValue } from "recoil";

import { Box, Grid, Paper, ClickAwayListener } from "@mui/material";

// Hooks
import { useLandingPage } from "@hooks";

// Recoil
import { uploadClaimProcessMapper } from "@selectors";

import { teamState } from "@atoms";

// Types
import { Location, Team } from "@types";

// Styles
import * as style from "./style.module.scss";

// Utils
import { constantMapper, truncateString } from "@utils";

// Constants
import { ROWRENDERERCONST } from "@constants";

interface User {
    info: { email: string; firstName: string; lastName: string };
    role: string;
}
interface Props {
    user: User;
    location: Location;
    id: string;
}

const Sidebar: React.FC<Props> = ({ user, location, id }) => {
    const team = useRecoilValue<Team | undefined>(teamState);
    const [sublist, setSublist] = useState("");

    const initialSelectedMenu = useMemo(() => {
        const path = location?.pathname || "";
        if (path.includes("/dashboard")) return "DASHBOARD";
        if (path.includes("/maintenance")) return "MAINTENANCE";
        if (path.includes("/tasks")) return "TASKS";
        if (path.includes("/reports")) return "REPORTS";
        if (path.includes("/process")) return "PROCESS";
        return "";
    }, [location]);

    const [selectedMenu, setSelectedMenu] = useState("");

    useEffect(() => {
        if (!initialSelectedMenu) return;

        setSelectedMenu(initialSelectedMenu);
    }, [initialSelectedMenu]);

    /**
     * Permissions
     */
    const {
        isUserWithRights,
        areContractsVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areFinancesVisible,
        hasPermissionToEditDataTables,
        hasPermissionToEditMaintenance,
        hasPermissionToEditClaim,
        areTasksVisible,
        areClaimsVisible,
    } = useLandingPage();

    /**
     * States
     */
    // Reset process flow
    const resetUploadClaimProcessMapper = useResetRecoilState(
        uploadClaimProcessMapper,
    );

    /**
     * Set sidebar state in localStorage
     */
    const isOpen = useMemo(() => {
        if (typeof window !== "undefined") {
            const state = localStorage.getItem("SIDEBAR_OPEN");

            if (!state || state === "false") {
                localStorage.setItem("SIDEBAR_OPEN", "false");
                return false;
            }

            return true;
        }
    }, []);

    const [open, setOpen] = useState(isOpen);

    /**
     * Reset creation flow
     */
    const handleNavigation = (
        hasSublist: boolean,
        key: string,
        path?: string,
    ) => {
        setSelectedMenu(key);

        if (!hasSublist && path) {
            location?.pathname.includes("process") &&
                resetUploadClaimProcessMapper();
            // only clear custom items
            for (const key in sessionStorage) {
                if (/^FILTER_|^SEARCH_VALUE/.test(key)) {
                    sessionStorage.removeItem(key);
                }
            }
            navigate(path);
        } else {
            setSublist(key);
            setOpen(false);
        }
    };

    const isSublistActive = useCallback(
        (key: string, subpages?: string[]) => {
            if (subpages) {
                return (
                    subpages
                        .map(s => (s.endsWith("/") ? s.slice(0, -1) : s))
                        .includes(
                            location.pathname.endsWith("/")
                                ? location.pathname.slice(0, -1)
                                : location.pathname,
                        ) || location.pathname.includes(key)
                );
            }
            return location.pathname.includes(key);
        },
        [location],
    );

    const sublistMapper = useMemo(() => {
        return {
            DASHBOARD: {
                icon: <GridOn color="primary" />,
                items: [
                    {
                        title: "Contracts",
                        path: "/dashboard/contracts/",
                        disabled: !areContractsVisible,
                    },
                    {
                        title: "Orders",
                        path: "/dashboard/orders/",
                        disabled: !areOrdersVisible,
                    },
                    {
                        title: "Outcomes",
                        path: "/dashboard/outcomes/",
                        disabled: !areOutcomesVisible,
                    },
                    {
                        title: "Financial conditions",
                        path: "/dashboard/financial-conditions/",
                        disabled: !areOutcomesVisible,
                    },
                    {
                        title: "Claims",
                        path: "/dashboard/claims/",
                        disabled: !areClaimsVisible,
                        subpages: ["/dashboard/price-correction-claims"],
                    },
                    {
                        title: "Finances",
                        path: "/dashboard/finances/bpf/",
                        disabled: !areFinancesVisible,
                        subpages: [
                            "/dashboard/finances/credit-note-outcome/",
                            "/dashboard/finances/invoice-release",
                            "/dashboard/finances/credit-note-claim/",
                        ],
                    },
                    {
                        title: "Products",
                        path: "/dashboard/products/",
                    },
                    {
                        title: "Partners",
                        path: "/dashboard/partners/",
                    },
                    {
                        title: "Regions",
                        path: "/dashboard/regions/",
                    },
                ],
            },
            MAINTENANCE: {
                icon: <ConstructionIcon color="primary" />,
                items: [
                    {
                        title: "Product",
                        path: "/maintenance/product/",
                    },
                    {
                        title: "Financial conditions",
                        path: "/maintenance/financial-conditions/",
                    },
                    {
                        title: "Product conditions",
                        path: "/maintenance/product-conditions/",
                    },
                    {
                        title: "Partner",
                        path: "/maintenance/partner/",
                    },
                    {
                        title: "Country info",
                        path: "/maintenance/country/",
                    },
                    {
                        title: "Teams",
                        path: "/maintenance/teams/",
                    },
                    {
                        title: "Users",
                        path: "/maintenance/users/",
                    },
                    {
                        title: "Context",
                        path: "/maintenance/context",
                    },
                    {
                        title: "Model",
                        path: "/maintenance/model",
                    },
                    {
                        title: "Category",
                        path: "/maintenance/category",
                    },
                    {
                        title: "Interface",
                        path: "/maintenance/interface/",
                    },
                    {
                        title: "Billing plan",
                        path: "/maintenance/billing-plan/",
                    },
                    {
                        title: "Milestone",
                        path: "/maintenance/milestone/",
                    },
                    {
                        title: "System availability",
                        path: "/maintenance/system-maintenance/",
                    },
                ],
            },
            TASKS: {
                icon: <AssignmentIcon color="primary" />,
                items: [
                    {
                        title: "Ongoing tasks",
                        path: "/tasks/ongoing-tasks/",
                    },
                    {
                        title: "Completed tasks",
                        path: "/tasks/completed-tasks/",
                    },
                ],
            },
        };
    }, [
        areContractsVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areClaimsVisible,
        areFinancesVisible,
    ]);

    /**
     * Render
     */
    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
                setSublist("");
            }}
        >
            <div id={`${id}-drawer`} className={style.wrapper}>
                <Drawer
                    id={`${id}-drawer`}
                    variant="persistent"
                    open={true}
                    className={classnames(style.drawer, {
                        [style.folded]: !open,
                    })}
                    classes={{
                        paper: classnames(style.paper, {
                            [style.folded]: !open,
                        }),
                    }}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        direction={"column"}
                        wrap="nowrap"
                    >
                        <Grid item xs={1}>
                            <Button
                                id={`${id}-logo`}
                                className={style.logo}
                                variant="text"
                                color="primary"
                                onClick={() => {
                                    if (!open) {
                                        setOpen(true);
                                        if (sublist) setSublist("");
                                    }
                                }}
                                size="small"
                            >
                                {open || !setSublist ? (
                                    <StaticImage
                                        src={"../../../images/scp-logo.svg"}
                                        alt="Smart contracting platform"
                                        objectFit="contain"
                                        placeholder="blurred"
                                    />
                                ) : (
                                    <StaticImage
                                        src={"../../../images/s.svg"}
                                        alt="Smart contracting platform icon"
                                        objectFit="contain"
                                        placeholder="blurred"
                                    />
                                )}
                            </Button>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={11}
                            direction={"column"}
                            className={style.menu}
                            flexGrow={1}
                            wrap="nowrap"
                        >
                            <Grid container item xs={8}>
                                <List className={style.navs}>
                                    <ListItem
                                        disablePadding
                                        sx={{ display: "block" }}
                                        id={`${id}-list-item-opener`}
                                    >
                                        <ListItemButton
                                            id={`${id}-slider-opener-btn`}
                                            onClick={() => {
                                                setOpen(!open);
                                                if (setSublist) setSublist("");

                                                localStorage.setItem(
                                                    "SIDEBAR_OPEN",
                                                    `${!open}`,
                                                );
                                            }}
                                        >
                                            <ListItemIcon>
                                                {open ? (
                                                    <ChevronLeftIcon color="primary" />
                                                ) : (
                                                    <ChevronRightIcon color="primary" />
                                                )}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        id={`${id}-dashboard`}
                                        disablePadding
                                        sx={{ display: "block" }}
                                        className={classnames({
                                            [style.active]:
                                                selectedMenu === "DASHBOARD",
                                        })}
                                    >
                                        <ListItemButton
                                            id={`${id}-dashboard-btn`}
                                            disabled={
                                                !team ||
                                                (!areContractsVisible &&
                                                    !areOrdersVisible &&
                                                    !areOutcomesVisible &&
                                                    !areFinancesVisible &&
                                                    !hasPermissionToEditDataTables)
                                            }
                                            onClick={() =>
                                                handleNavigation(
                                                    true,
                                                    "DASHBOARD",
                                                )
                                            }
                                        >
                                            <ListItemIcon
                                                className={style.listIcon}
                                                sx={{ position: "relative" }}
                                            >
                                                {(!team ||
                                                    (!areContractsVisible &&
                                                        !areOrdersVisible &&
                                                        !areOutcomesVisible &&
                                                        !areFinancesVisible &&
                                                        !hasPermissionToEditDataTables)) && (
                                                    <LockIcon
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            position:
                                                                "absolute",
                                                            left: "-14px",
                                                            top: "5px",
                                                        }}
                                                    />
                                                )}
                                                <GridOn color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2">
                                                    Dashboard
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        id={`${id}-maintenance`}
                                        disablePadding
                                        sx={{ display: "block" }}
                                        className={classnames({
                                            [style.active]:
                                                selectedMenu === "MAINTENANCE",
                                        })}
                                    >
                                        <ListItemButton
                                            id={`${id}-maintenance-btn`}
                                            disabled={
                                                !hasPermissionToEditMaintenance
                                            }
                                            onClick={() =>
                                                handleNavigation(
                                                    true,
                                                    "MAINTENANCE",
                                                )
                                            }
                                        >
                                            <ListItemIcon
                                                className={style.listIcon}
                                                sx={{ position: "relative" }}
                                            >
                                                {!hasPermissionToEditMaintenance && (
                                                    <LockIcon
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            position:
                                                                "absolute",
                                                            left: "-14px",
                                                            top: "5px",
                                                        }}
                                                    />
                                                )}
                                                <ConstructionIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2">
                                                    Maintenance
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        id={`${id}-tasks`}
                                        disablePadding
                                        sx={{ display: "block" }}
                                        className={classnames({
                                            [style.active]:
                                                selectedMenu === "TASKS",
                                        })}
                                    >
                                        <ListItemButton
                                            id={`${id}-tasks-btn`}
                                            onClick={() =>
                                                handleNavigation(true, "TASKS")
                                            }
                                            disabled={!team || !areTasksVisible}
                                        >
                                            <ListItemIcon
                                                className={style.listIcon}
                                                sx={{ position: "relative" }}
                                            >
                                                {(!team ||
                                                    !areTasksVisible) && (
                                                    <LockIcon
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            position:
                                                                "absolute",
                                                            left: "-14px",
                                                            top: "5px",
                                                        }}
                                                    />
                                                )}
                                                <AssignmentIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2">
                                                    Tasks
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        id={`${id}-reports`}
                                        disablePadding
                                        sx={{ display: "block" }}
                                        className={classnames({
                                            [style.active]:
                                                selectedMenu === "REPORTS",
                                        })}
                                    >
                                        <ListItemButton
                                            id={`${id}-reports-btn`}
                                            onClick={() =>
                                                handleNavigation(
                                                    false,
                                                    "REPORTS",
                                                    "/reports/",
                                                )
                                            }
                                            disabled={
                                                !team || !isUserWithRights
                                            }
                                        >
                                            <ListItemIcon
                                                className={style.listIcon}
                                                sx={{ position: "relative" }}
                                            >
                                                {(!team ||
                                                    !isUserWithRights) && (
                                                    <LockIcon
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            position:
                                                                "absolute",
                                                            left: "-14px",
                                                            top: "5px",
                                                        }}
                                                    />
                                                )}
                                                <AssignmentReturnedIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2">
                                                    Reports
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        id={`${id}-process`}
                                        disablePadding
                                        sx={{ display: "block" }}
                                        className={classnames({
                                            [style.active]:
                                                selectedMenu === "PROCESS",
                                        })}
                                    >
                                        <ListItemButton
                                            id={`${id}-process-btn`}
                                            onClick={() =>
                                                handleNavigation(
                                                    false,
                                                    "PROCESS",
                                                    "/process/process-details/",
                                                )
                                            }
                                            disabled={
                                                !team ||
                                                !hasPermissionToEditClaim
                                            }
                                        >
                                            <ListItemIcon
                                                className={style.listIcon}
                                                sx={{ position: "relative" }}
                                            >
                                                {(!team ||
                                                    !hasPermissionToEditClaim) && (
                                                    <LockIcon
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            position:
                                                                "absolute",
                                                            left: "-14px",
                                                            top: "5px",
                                                        }}
                                                    />
                                                )}
                                                <AutoModeIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body2">
                                                    Process
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Grid>
                            {!!user && (
                                <Grid
                                    item
                                    xs={4}
                                    container
                                    alignItems={"flex-end"}
                                >
                                    <List className={style.navs}>
                                        <ListItem
                                            id={`${id}-user-info`}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            className={classnames({
                                                [style.active]: false,
                                            })}
                                        >
                                            <ListItemButton
                                                id={`${id}-user-info-btn`}
                                                onClick={() =>
                                                    handleNavigation(
                                                        false,
                                                        "ACCOUNT",
                                                        `/account/`,
                                                    )
                                                }
                                                disabled={!team}
                                            >
                                                <ListItemIcon
                                                    className={style.listIcon}
                                                    sx={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    {!team && (
                                                        <LockIcon
                                                            sx={{
                                                                fontSize:
                                                                    "0.9rem",
                                                                position:
                                                                    "absolute",
                                                                left: "-14px",
                                                                top: "5px",
                                                            }}
                                                        />
                                                    )}
                                                    <PersonOutlineIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="body2">
                                                        {user?.info
                                                            ?.firstName &&
                                                        user?.info?.lastName
                                                            ? `${user?.info?.firstName} ${user?.info?.lastName}`
                                                            : user?.info?.email}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem
                                            id={`${id}-user-info`}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            className={classnames({
                                                [style.active]: false,
                                            })}
                                        >
                                            <Tooltip
                                                arrow
                                                title={
                                                    team
                                                        ? team?.teamName
                                                        : "No team Selected"
                                                }
                                                id={`${id}-selected-team-tooltip`}
                                                placement="right"
                                            >
                                                <ListItemButton
                                                    id={`${id}-select-team-btn`}
                                                    onClick={() =>
                                                        handleNavigation(
                                                            false,
                                                            "TEAM",
                                                            "/select-team",
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon
                                                        className={
                                                            style.listIcon
                                                        }
                                                    >
                                                        <GroupsIcon color="primary" />
                                                    </ListItemIcon>

                                                    <ListItemText>
                                                        <Typography variant="body2">
                                                            {team
                                                                ? truncateString(
                                                                      team?.teamName,
                                                                      15,
                                                                      undefined,
                                                                      20,
                                                                  )
                                                                : "No team Selected"}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </Tooltip>
                                        </ListItem>

                                        <ListItem
                                            disablePadding
                                            sx={{ display: "block" }}
                                            id={`${id}-logout`}
                                        >
                                            <ListItemButton
                                                id={`${id}-logout-btn`}
                                                onClick={() => {
                                                    // clear saved viewing options in local storage
                                                    Object.keys(
                                                        ROWRENDERERCONST,
                                                    ).forEach((key: string) => {
                                                        localStorage.removeItem(
                                                            key,
                                                        );
                                                    });

                                                    navigate("/logout");
                                                }}
                                            >
                                                <ListItemIcon
                                                    className={style.listIcon}
                                                >
                                                    <ExitToAppIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="body2">
                                                        Logout
                                                    </Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Drawer>

                <div
                    id={`${id}-sublist-drawer`}
                    className={classnames(style.sublist, {
                        [style.openSublist]: !!sublist,
                    })}
                >
                    <Paper className={style.sublistPaper}>
                        <Button
                            id={`${id}-logo`}
                            startIcon={<ChevronLeftIcon color="primary" />}
                            className={style.logo}
                            variant="text"
                            color="primary"
                            onClick={() => {
                                setSublist("");
                                setOpen(true);
                            }}
                            size="small"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                height={"1.5rem"}
                            >
                                {sublistMapper[sublist]?.icon}
                                <span className={style.sublistHeader}>
                                    {constantMapper(sublist)}
                                </span>
                            </Box>
                        </Button>

                        <List className={style.navs}>
                            {sublistMapper[sublist]?.items?.map(
                                (item, index) =>
                                    !item?.disabled && (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            id={`${id}-sublist-item-opener`}
                                            className={classnames({
                                                [style.active]: isSublistActive(
                                                    item.path,
                                                    item.subpages,
                                                ),
                                            })}
                                        >
                                            <ListItemButton
                                                id={`${id}-dashboard-btn`}
                                                disabled={item?.disabled}
                                                onClick={() => {
                                                    navigate(item.path);
                                                    setSublist("");
                                                }}
                                            >
                                                <ListItemText>
                                                    <Typography variant="body2">
                                                        {item.title}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ),
                            )}
                        </List>
                    </Paper>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default React.memo(Sidebar);
