// Libs
import EditIcon from "@mui/icons-material/Edit";
import {
    TableCell,
    TableRow,
    Typography,
    IconButton,
    Box,
} from "@mui/material";
import React from "react";

// styles
import * as style from "../../style.module.scss";

interface Row {
    productName: string;
    productId: string;
    salesOrg: string;
    sapSkuNo: string;
    brandName: string;
    brandId: string;
    active: boolean;
    country: { isoCode: string; name: string };
    productConditions: Array<{
        conditionTypeId: string;
        conditionName: string;
        conditionDescription: string;
        status: boolean;
        amount: number;
    }>;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    isColVisible: (col: string) => boolean;
}

/**
 * Products conditions
 */
const ProductsConditions = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            className={style.row}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("productName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.productName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("salesOrg") && (
                <TableCell>
                    <Typography variant="caption2">{row.salesOrg}</Typography>
                </TableCell>
            )}
            {isColVisible("sapSkuNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapSkuNo}</Typography>
                </TableCell>
            )}
            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.active ? "Active" : "Inactive"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.country?.name}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton
                        id={`${id}-edit-model-btn`}
                        color="primary"
                        aria-label="edit button"
                        component="span"
                        onClick={() =>
                            callbacks?.onProductConditionsChange
                                ? callbacks.onProductConditionsChange(
                                      row?.productId,
                                      row?.productConditions,
                                  )
                                : undefined
                        }
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ProductsConditions;
