// lib
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { ReactNode, useRef, useLayoutEffect, Fragment } from "react";
import { useRecoilValue } from "recoil";
import { Location } from "@reach/router";

// own containers/components
import { Sidebar, SystemTimer } from "@components";
import { Notifications } from "@containers";

//import footer img
/* import footer from "../../images/footer.svg";
import smartContractingLogo from "@images/smart-contracting.svg"; */

//recoil atoms
import { userState } from "@atoms";

// style
import * as style from "./style.module.scss";
import classNames from "classnames";
import { pathname } from "../../utils";

// Own hooks
import { useSystemAvailability, useUser, usePermission } from "@hooks";

import type { Permissions } from "@types";
import { navigate } from "gatsby";

/**
 * Props type
 */
interface Props {
    children: ReactNode;
}

const Layout = ({ children }: Props) => {
    const isInactive =
        pathname?.includes("logout") || pathname?.includes("inactive");

    const { hasPermissionToEditMaintenance }: Permissions = usePermission();

    const { loading: fetchingUserInfo } = useUser(!isInactive);

    const {
        loading: fetchingSystemInfo,
        response: { blockSystemUse, message, active },
    } = useSystemAvailability();

    const loading = fetchingUserInfo || fetchingSystemInfo;

    const ref = useRef<HTMLDivElement>(null);

    const user = useRecoilValue<any>(userState);

    useLayoutEffect(() => {
        if (!user || isInactive) return;

        if (!blockSystemUse && pathname?.includes("system-availability")) {
            navigate("/");
            return;
        }

        if (hasPermissionToEditMaintenance) return;

        if (blockSystemUse) {
            navigate("/system-availability", {
                state: { message },
            });
        }
    }, [
        user,
        blockSystemUse,
        hasPermissionToEditMaintenance,
        message,
        isInactive,
    ]);

    /**
     * Render
     */
    return (
        <div className={style.layout}>
            <Notifications />

            <Box className={style.body} ref={ref}>
                <SystemTimer itemRef={ref} />

                <Location>
                    {({ location }) =>
                        !!user &&
                        !loading &&
                        (!blockSystemUse ||
                            (blockSystemUse &&
                                hasPermissionToEditMaintenance)) && (
                            <div className={style.sidebar}>
                                <Sidebar
                                    id="layout"
                                    user={user}
                                    location={location}
                                />
                            </div>
                        )
                    }
                </Location>

                <Box
                    className={classNames(style.content, {
                        [style.fullWidth]: !user,
                        [style.noScroll]:
                            (blockSystemUse &&
                                !hasPermissionToEditMaintenance) ||
                            loading,
                    })}
                    tabIndex={0}
                >
                    {loading && (
                        <Box
                            height={"90vh"}
                            justifyContent="center"
                            display="flex"
                            alignItems="center"
                            width={"100%"}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {!loading && hasPermissionToEditMaintenance && (
                        <Fragment>
                            {active && (
                                <Box textAlign={"center"} my={1}>
                                    <Typography variant="h4" component="h4">
                                        {message}
                                    </Typography>
                                </Box>
                            )}
                            {children}
                        </Fragment>
                    )}

                    {!loading && !hasPermissionToEditMaintenance && (
                        <Fragment>
                            {!blockSystemUse && active && (
                                <Box textAlign={"center"} my={1}>
                                    <Typography variant="h4" component="h4">
                                        {message}
                                    </Typography>
                                </Box>
                            )}

                            {children}
                        </Fragment>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default React.memo(Layout);
