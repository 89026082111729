//Lib
import {
    Autocomplete as _Autocomplete,
    CircularProgress,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import ClearIcon from "@mui/icons-material/Clear";
import debounce from "lodash.debounce";
import { matchSorter } from "match-sorter";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useState,
    useMemo,
} from "react";

//Utils
import { isArrayWithContent } from "@utils";

//Types
import { Account } from "@types";

//Styles
import * as style from "./style.module.scss";

// Own component
import FilterListRenderer from "./FilterListRenderer";
import classNames from "classnames";

type List = {
    keysToMatch: Array<string>;
    option: Account;
    inputValue: string;
    onClick: any;
    id: string;
};

/**
 * Props type
 */
interface Props {
    data: Array<Account>;
    loading: boolean;
    error?: boolean;
    onBlur?: (event: React.SyntheticEvent) => void;
    size: "small" | "medium";
    name?: string;
    variant?: "outlined" | "standard" | "filled";
    id: string;
    onSearch: (query: string) => void;
    onChange: (value: any) => void;
    value: any;
    displayEmpty?: boolean;
    keysToMatch: Array<string>;
    label?: string;
    disabled?: boolean;
    clearIfNoValueSelected?: boolean;
    fullWidth?: boolean;
    onDelete?: () => void;
}

const NO_RESULT = [{ key: "no_result", parts: [] }];

/**
 * Filtered List Mapper
 */
const ListMapper = ({
    keysToMatch,
    option,
    inputValue,
    onClick,
    id,
    ...rest
}: List) => {
    /**
     * Highlight the matched text depending on the keyToMach prop
     */
    const checkMatches = useCallback((option, inputValue: any) => {
        if (!option) return;

        if (option) {
            const result: Array<{
                key: string;
                parts: Array<{ text: string; highlight: boolean }>;
            }> = [];

            keysToMatch?.map(key => {
                let parts: Array<{ text: string; highlight: boolean }> = [];
                if (option[key]) {
                    const matches = match(option[key], inputValue);
                    if (isArrayWithContent(matches)) {
                        const arr = parse(option[key], matches);
                        parts = arr;
                    } else {
                        parts.push({ text: option[key], highlight: false });
                    }
                    result.push({ key, parts });
                }
            });
            return result;
        }
    }, []);

    const list = checkMatches(option, inputValue);

    return (
        <li
            {...rest}
            key={id}
            id={id}
            className={style.listWrapper}
            onClick={isArrayWithContent(list) ? onClick : undefined}
            onKeyDown={isArrayWithContent(list) ? onClick : undefined}
        >
            <FilterListRenderer
                list={list || []}
                keysToMatch={keysToMatch}
                id={id}
            />
        </li>
    );
};

/**
 * Auto complete
 */
const Autocomplete: React.FC<Props> = ({
    data,
    id,
    loading,
    error,
    onBlur,
    label,
    size = "small",
    name,
    variant = "outlined",
    onChange,
    onSearch,
    value,
    keysToMatch,
    displayEmpty,
    disabled,
    clearIfNoValueSelected,
    fullWidth,
    onDelete,
}) => {
    // States
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");

    /**
     * filterOptions renderer, otherwise will not show the requested keys as a search result
     */
    const matching = (list, value) => {
        if (isArrayWithContent(list)) {
            const res = matchSorter(list, value, {
                keys: keysToMatch,
                threshold: matchSorter.rankings.STARTS_WITH,
            });

            return res?.length ? res : NO_RESULT;
        } else return NO_RESULT;
    };

    /**
     * Check if the input is not empty
     * then we show the result
     */
    const canShowResult = useMemo(() => {
        return inputValue?.trim()?.length > 1;
    }, [inputValue]);

    useEffect(() => {
        // We trim it because the user can filter on white spaces
        if (canShowResult && !value?.accountId) {
            // Filter & show loading and result
            onSearch(inputValue);
            setOpen(true);
        } else {
            // close it if the input is empty
            setOpen(false);
        }
    }, [inputValue]);

    const changeHandler = (query: string) => setInputValue(query);

    const debouncedChangeHandler = useCallback(
        debounce((query: string) => {
            // please keep the toggler here, there is a bug that keeps the result
            // we have to force the component to rerender in order to show the fresh data
            setOpen(false);
            changeHandler(query);
        }, 350),
        [],
    );

    /**
     * Render
     */
    return (
        <_Autocomplete
            id={`${id}-autocomplete`}
            className={classNames(style.whiteInput, {
                [style.darkDisabled]: disabled,
            })}
            open={open}
            onClose={() => setOpen(false)}
            value={value || ""}
            onInputChange={(_, newInputValue) => {
                debouncedChangeHandler(newInputValue);
            }}
            clearOnBlur={
                clearIfNoValueSelected && !value?.accountId && !!inputValue
            }
            blurOnSelect={clearIfNoValueSelected}
            onOpen={() => (canShowResult ? setOpen(true) : undefined)}
            getOptionLabel={option => {
                return !displayEmpty && option?.key !== "no_result" && !!value
                    ? option.accountName
                    : "";
            }}
            options={data || []}
            loading={loading}
            onChange={(_, text) => {
                onChange(text);
                setOpen(false);
            }}
            disabled={!!value?.accountId || disabled}
            disableClearable={displayEmpty}
            data-testid="autocomplete"
            freeSolo
            loadingText={<CircularProgress color="inherit" size={20} />}
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    name={name}
                    variant={variant}
                    disabled={!!value?.accountId || disabled}
                    autoComplete="off"
                    fullWidth={fullWidth}
                    id={`${id}-autocomplete-input`}
                    size={size}
                    onBlur={event => {
                        onBlur && onBlur(event);
                    }}
                    placeholder={label}
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                        },
                        minWidth: "8rem",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {open && loading && (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                )}
                                {value?.accountId && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={disabled}
                                            id={`${id}-autocomplete-clear-btn`}
                                            size="small"
                                            onClick={() => {
                                                if (onDelete) {
                                                    onDelete();
                                                } else {
                                                    onChange("");
                                                }

                                                setInputValue("");
                                            }}
                                        >
                                            <ClearIcon color="disabled" />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </Fragment>
                        ),
                    }}
                />
            )}
            filterOptions={(x, state) => {
                return matching(x, state.inputValue);
            }}
            renderOption={(props: any, option, { inputValue }) => (
                <ListMapper
                    id={`${id}-autocomplete-list-item-${option.accountId}`}
                    option={option}
                    inputValue={inputValue}
                    keysToMatch={keysToMatch}
                    {...props}
                />
            )}
        />
    );
};
export default Autocomplete;
