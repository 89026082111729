// Lib
import React, { useEffect, useImperativeHandle } from "react";

// Own components
import { MaintenanceBillingPlan } from "@components";

// Hooks
import { useMaintenance } from "@hooks";
import { Location } from "@types";

// Types
import type { BillingPlanValues } from "@types";

// Utils
import { isSuccessfulCall } from "@utils";

const FIELDS = [
    {
        id: "salesOrg",
        inputLabel: "Organisation",
        type: "string",
        required: true,
    },
    {
        id: "documentType",
        inputLabel: "Document Type",
        type: "string",
        required: true,
    },
    {
        id: "division",
        inputLabel: "Division",
        type: "string",
        required: true,
    },
    {
        id: "planType",
        inputLabel: "Plan Type",
        type: "string",
        required: true,
    },
    {
        id: "counter",
        inputLabel: "Counter",
        type: "string",
        required: true,
    },
    {
        id: "days",
        inputLabel: "Days",
        type: "integer",
        required: false,
    },
    {
        id: "percentage",
        inputLabel: "Percentage",
        type: "decimal",
        required: false,
    },

    {
        id: "paymentTerms",
        inputLabel: "Payment Terms",
        type: "string",
        required: false,
    },
    {
        id: "billingBlock",
        inputLabel: "Billing Block",
        type: "string",
        required: false,
    },
];

interface BillingPlanProps {
    onDataReady?: () => void;
    id?: string;
    location?: Location;
}

interface BillingPlanRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Billing Plan
 */
const BillingPlan = React.forwardRef<BillingPlanRef, BillingPlanProps>(
    ({ onDataReady, id }, ref) => {
        /**
         * API
         */
        const {
            list: billingPlanList,
            error: billingPlanError,
            loading: { listLoading: fetchingList, creating },
            upsert,
        } = useMaintenance("billingPlan", "Billing plan|Billing plan");

        /**
         * Submit handler
         */
        const handleSubmit = (values: BillingPlanValues, isEdit: boolean) => {
            return upsert(
                values,
                isEdit ? "Billing plan" : "New billing plan",
            ).then(res => isSuccessfulCall(res?.status));
        };

        useEffect(() => {
            if (billingPlanList?.maintenanceBillingPlanList && !fetchingList) {
                onDataReady?.();
            }
        }, [billingPlanList, fetchingList]);

        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount:
                            billingPlanList?.maintenanceBillingPlanList?.length,
                    };
                },
            }),
            [billingPlanList?.maintenanceBillingPlanList], // Add dependency array here
        );

        /**
         * Render
         */
        return (
            <MaintenanceBillingPlan
                id={`${id}-billing-plan-list`}
                list={billingPlanList?.maintenanceBillingPlanList}
                loading={{
                    fetchingList,
                    creating,
                }}
                fields={FIELDS}
                error={{ billingPlanError }}
                onSubmit={handleSubmit}
            />
        );
    },
);
export default BillingPlan;
