// Libs
import { Box, Grid } from "@mui/material";
import { navigate } from "gatsby";
import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
    useImperativeHandle,
} from "react";

// Own components
import { SearchBar, Table, Modal, FieldRenderer } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useProductPrices,
    useProducts,
    useViewingOptions,
    useUpdateProduct,
    usePermission,
    useTeamCountries,
    useBrands,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import { constructQueryString, isSuccessfulCall } from "@utils";

const ShowPriceTable = ({ item, onClose }: any) => {
    /**
     * Products price
     */
    const {
        getProductPrices,
        response: productPrices,
        loading: loadingProductPrices,
    } = useProductPrices();

    /**
     * Show product price table
     */
    useEffect(() => {
        if (item) {
            getProductPrices(item.productId);
        }
    }, [item]);

    return (
        <Modal
            title={"Price table"}
            id={`dashboard-products-overview-products-prices`}
            open={!!item}
            onClose={onClose}
        >
            <div>
                <Grid container item xs={12} spacing={3} mb={5}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`dashboard-products-overview-products-prices-product-name`}
                            label="Product name"
                            value={item?.productName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`dashboard-products-overview-products-prices-product-sku-number`}
                            label="SAP SKU number"
                            value={item?.sapSkuNo}
                        />
                    </Grid>
                </Grid>
                <Table
                    headers={HEADERS.PRICES}
                    rows={productPrices?.data?.records || []}
                    loading={loadingProductPrices}
                    type={ROWRENDERERCONST.PRICES}
                    id={`products-prices`}
                    emptyMsg={"No data"}
                    isOverview={false}
                />
            </div>
        </Modal>
    );
};

/**
 * Props type
 */

type Props = {
    onDataReady?: () => void;
};

interface ProductsRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Products
 */
const Products = React.forwardRef<ProductsRef, Props>(
    ({ onDataReady }, ref) => {
        const [product, setProduct] = useState<any>();

        /**
         * Permissions
         */
        const { hasPermissionToEditDataTables }: Permissions = usePermission();

        /**
         * Hooks
         */
        const { viewingOptions, setViewingOptions } = useViewingOptions(
            ROWRENDERERCONST.PRODUCTS,
        );

        /**
         * API
         */

        /**
         * Countries
         */

        const { loading: countryLoading, list: countries } = useTeamCountries();

        const { list: brands, loading: brandsLoading } = useBrands();

        /**
         * Products
         */
        const {
            response: products,
            loading: loadingProducts,
            getProducts,
        } = useProducts(false);

        /**
         * Update Product
         */
        const { updateProduct, loading: loadingUpdateProduct } =
            useUpdateProduct();

        /**
         * Update Product
         */
        const onUpdateProduct = (productId: string, brand: any) => {
            updateProduct(productId, brand?.brandId).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    getProducts();
                }
            });
        };

        const onSearch = (searchFilters: SearchFilters) => {
            const hasFilters = Object.values(searchFilters).some(
                (filter: any) => filter.length,
            );

            // if status is present it should be toggler
            // and should be mapped to isActive
            const mappedFilters = {
                ...searchFilters,
                status:
                    searchFilters["status"].length == 2
                        ? null
                        : searchFilters["status"],
            };

            const params = constructQueryString(mappedFilters, true);

            const newParams = params.replace(/status/g, "isActive");

            if (hasFilters) getProducts(newParams);
            else getProducts();
        };

        /**
         * Map Brands
         */
        const mapBrands = useMemo(() => {
            if (!!brandsLoading || !brands?.data) return [];
            return brands?.data?.map(brand => {
                return {
                    brandId: brand.brandId,
                    brandName: brand.brandName,
                };
            });
        }, [brandsLoading, brands?.data]);

        /**
         * Data ready
         */
        useEffect(() => {
            if (products?.data?.records && !loadingProducts && !brandsLoading) {
                onDataReady?.();
            }
        }, [products?.data?.records, loadingProducts, brandsLoading]);

        /**
         * Imperative handle
         */
        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount: products?.data?.records?.length,
                    };
                },
            }),
            [products],
        );

        /**
         * Render
         */
        return (
            <Fragment>
                <ShowPriceTable
                    item={product}
                    onClose={() => setProduct(undefined)}
                />

                <Box mt={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id={`dashboard-products-overview-search-bar`}
                        filterKey={ROWRENDERERCONST.PRODUCTS}
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        placeholder="Search product..."
                        viewingOptions={viewingOptions}
                        setViewingOptions={setViewingOptions}
                        startDate={false}
                        endDate={false}
                        countries={{
                            data: countries?.data?.records,
                            loading: countryLoading,
                        }}
                        status={{
                            data: [
                                { label: "Active", value: "true" },
                                { label: "Inactive", value: "false" },
                            ],
                            loading: false,
                        }}
                        brands={{
                            data: mapBrands,
                            loading: brandsLoading,
                        }}
                        priceRange
                    />
                </Box>

                <Table
                    id={`dashboard-products-list`}
                    headers={HEADERS.PRODUCTS}
                    rows={products?.data?.records}
                    loading={
                        loadingProducts || brandsLoading || loadingUpdateProduct
                    }
                    type={ROWRENDERERCONST.PRODUCTS}
                    viewingOptions={viewingOptions}
                    brands={mapBrands}
                    callbacks={{
                        onEditProduct: (productId: string, brand: any) =>
                            onUpdateProduct(productId, brand),
                        onPriceTableClick: (item: any) => setProduct(item),
                        onExternalCodesEdit: item =>
                            navigate(
                                `/product/external-codes/${item.productId}/`,
                            ),
                    }}
                    emptyMsg="No products found!"
                    permissions={{ hasPermissionToEditDataTables }}
                />
            </Fragment>
        );
    },
);
export default Products;
