// icon
import { KeyboardArrowDown } from "@mui/icons-material";

// Lib
import {
    Box,
    Typography,
    Grid,
    InputLabel,
    TextField,
    MenuItem,
    Select,
    FormHelperText,
} from "@mui/material";

import React, {
    useState,
    Fragment,
    useEffect,
    useImperativeHandle,
} from "react";

// Own components
import { SearchBar, Table, Modal, LoadingWrapper, Dialog } from "@components";

// custom hooks
import { useViewingOptions, useMaintenance } from "@hooks";

// Constants
import { HEADERS, ROWRENDERERCONST, CONTEXT_TYPES } from "@constants";

// Utils

import { isArrayWithContent, isSuccessfulCall, trimAndFormat } from "@utils";

/**
 * Category type
 */
type Category = {
    index?: number;
    categoryId?: string;
    countryIsoCode: string;
    categoryValue: string;
    categoryLabel: string;
    categoryName: string;
    type: string;
};

/**
 * Initial category state values
 */
const INITIAL_CATEGORY = {
    categoryName: "",
    categoryLabel: "",
    countryIsoCode: "",
    categoryValue: "",
    type: "",
};

interface MaintenanceCategoryProps {
    onDataReady?: () => void;
    id?: string;
}

interface MaintenanceCategoryRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Maintenance Category
 */
const MaintenanceCategory = React.forwardRef<
    MaintenanceCategoryRef,
    MaintenanceCategoryProps
>(({ onDataReady, id }, ref) => {
    const [category, setCategory] = useState<Category>(INITIAL_CATEGORY);
    const [categoryToDelete, setCategoryToDelete] = useState<
        { categoryName: string; categoryId: string } | undefined
    >(undefined);

    const [openModal, toggleModal] = useState(false);
    const [touched, setTouched] = useState<any>({});
    const [validationMsg, setValidationMsg] = useState("");

    /**
     * Get data category list
     */
    const {
        list: categoryList,
        loading: { listLoading: fetching, creating, deleting },
        upsert,
        delete: deleteCategory,
    } = useMaintenance("category", "category|categories");

    const {
        list: countries,
        loading: { listLoading: fetchingCountries },
        reload: loadCountries,
    } = useMaintenance("country", "country|countries", false);

    /**
     * Custom Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.CATEGORY,
    );

    useEffect(() => {
        if (!openModal) return;
        loadCountries();
    }, [openModal]);

    /**
     * Reset all
     */
    const resetAll = () => {
        toggleModal(false);
        setCategory(INITIAL_CATEGORY);
        setTouched({});
        setCategoryToDelete(undefined);
        setValidationMsg("");
    };

    /**
     * Change handler
     */
    const handleCategoryChange = (type: string, value: string | number) => {
        if (!type) return;
        const copyCategory = { ...category };
        copyCategory[type] = value;
        setCategory(copyCategory);
    };

    /**
     * Check if the form is valid
     */
    const checkFormValidity = () => {
        if (!isArrayWithContent(categoryList?.maintenanceCategoryList)) {
            setValidationMsg("");
            return false; //is valid
        }

        let hasDuplicateValue = false;

        categoryList?.maintenanceCategoryList.forEach((item, index) => {
            if (index === category?.index) return;

            if (
                item?.categoryLabel?.toUpperCase() ===
                    category?.categoryLabel.toUpperCase() &&
                item?.categoryName?.toUpperCase() ===
                    category?.categoryName.toUpperCase() &&
                item?.country?.isoCode === category?.countryIsoCode &&
                item?.type === category?.type &&
                item?.categoryValue === category?.categoryValue
            ) {
                hasDuplicateValue = true;
            }
        });

        setValidationMsg(
            hasDuplicateValue ? "Fields must be unique for each category" : "",
        );

        return hasDuplicateValue;
    };

    /**
     * Submit handler
     */

    const onSubmit = () => {
        const hasDuplication = checkFormValidity();

        if (hasDuplication) return;

        const mapApiData = {
            categoryId: category?.categoryId || undefined,
            countryIsoCode: category?.countryIsoCode,
            categoryLabel: category?.categoryLabel,
            categoryName: category?.categoryName,
            type: category?.type,
            categoryValue: category?.categoryValue,
        };

        upsert(mapApiData, category.categoryName).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    /**
     * validation handler
     */
    const hasError = (field: string) => {
        if (!category || !touched[field]) return;
        return !category?.[field] && touched?.[field];
    };

    /**
     * Delete handler
     */
    const onCategoryDelete = () => {
        if (!categoryToDelete) return;
        deleteCategory(
            categoryToDelete?.categoryId,
            categoryToDelete?.categoryName,
        ).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    useEffect(() => {
        if (categoryList?.maintenanceCategoryList && !fetching) {
            onDataReady?.();
        }
    }, [categoryList?.maintenanceCategoryList, fetching]);

    useImperativeHandle(
        ref,
        () => ({
            getRecordsCount() {
                return {
                    itemCount: categoryList?.maintenanceCategoryList?.length,
                };
            },
        }),
        [categoryList?.maintenanceCategoryList], // Add dependency array here
    );

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                id={`delete-category-dialog`}
                title={"Delete category"}
                open={!!categoryToDelete?.categoryId}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${categoryToDelete?.categoryName})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onCategoryDelete,
                    loading: deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setCategoryToDelete(undefined),
                }}
            />

            <Modal
                id={`category-modal`}
                open={openModal}
                mediumView
                onClose={resetAll}
                title={
                    category?.categoryId ? "Edit category" : "Add new category"
                }
                primaryButton={{
                    action: () => onSubmit(),
                    text: category?.categoryId ? "Save" : "Add",
                    loading: creating,
                    disabled:
                        !category?.categoryLabel ||
                        !category?.categoryName ||
                        !category?.countryIsoCode ||
                        !category.categoryValue ||
                        !category.type ||
                        fetchingCountries,
                }}
                secondaryButton={{
                    action: () => resetAll(),
                    text: "Cancel",
                }}
            >
                <LoadingWrapper
                    id={`${id}-category-loading`}
                    loading={fetchingCountries}
                >
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={4}>
                            <InputLabel
                                shrink
                                id={`category-categoryName`}
                                error={
                                    !!hasError("categoryName") ||
                                    !!validationMsg
                                }
                            >
                                {"Category name (*)"}
                            </InputLabel>
                            <TextField
                                id={`category-categoryName`}
                                fullWidth
                                autoComplete="off"
                                name={"categoryName"}
                                onBlur={() => {
                                    if (touched?.categoryName) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            categoryName: true,
                                        };
                                    });
                                }}
                                error={
                                    !!hasError("categoryName") ||
                                    !!validationMsg
                                }
                                size="small"
                                value={category?.categoryName}
                                disabled={creating}
                                onChange={event =>
                                    handleCategoryChange(
                                        "categoryName",
                                        trimAndFormat(event.target.value, true),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel
                                shrink
                                id={`category-categoryLabel`}
                                error={
                                    !!hasError("categoryLabel") ||
                                    !!validationMsg
                                }
                            >
                                {"Category label (*)"}
                            </InputLabel>
                            <TextField
                                id={`category-categoryLabel`}
                                fullWidth
                                autoComplete="off"
                                name={"categoryLabel"}
                                onBlur={() => {
                                    if (touched?.categoryLabel) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            categoryLabel: true,
                                        };
                                    });
                                }}
                                error={
                                    !!hasError("categoryLabel") ||
                                    !!validationMsg
                                }
                                size="small"
                                value={category?.categoryLabel}
                                disabled={creating}
                                onChange={event =>
                                    handleCategoryChange(
                                        "categoryLabel",
                                        trimAndFormat(event.target.value, true),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`categoryValue`}
                                error={
                                    !!hasError("categoryValue") ||
                                    !!validationMsg
                                }
                            >
                                {"Category value (*)"}
                            </InputLabel>

                            <TextField
                                id={`category-categoryValue`}
                                fullWidth
                                autoComplete="off"
                                name={"categoryValue"}
                                onBlur={() => {
                                    if (touched?.categoryValue) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            categoryValue: true,
                                        };
                                    });
                                }}
                                error={
                                    !!hasError("categoryValue") ||
                                    !!validationMsg
                                }
                                size="small"
                                value={category?.categoryValue}
                                disabled={creating}
                                onChange={event =>
                                    handleCategoryChange(
                                        "categoryValue",
                                        trimAndFormat(event.target.value, true),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`category-country-label`}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                            >
                                {"Country (*)"}
                            </InputLabel>
                            <Select
                                id={`category-country-select`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.countryIsoCode) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            countryIsoCode: true,
                                        };
                                    });
                                }}
                                value={category?.countryIsoCode || ""}
                                disabled={creating}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                                size="small"
                                onChange={event => {
                                    handleCategoryChange(
                                        "countryIsoCode",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {countries?.maintenanceCountryList?.map(
                                    (item: any) => {
                                        return (
                                            <MenuItem
                                                key={item.countryIsoCode}
                                                value={item.countryIsoCode}
                                            >
                                                {item.country}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`type`}
                                error={!!hasError("type") || !!validationMsg}
                            >
                                {"Type (*)"}
                            </InputLabel>
                            <Select
                                id={`category-type`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.type) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            type: true,
                                        };
                                    });
                                }}
                                value={category?.type || ""}
                                disabled={creating}
                                size="small"
                                error={!!hasError("type") || !!validationMsg}
                                onChange={event => {
                                    handleCategoryChange(
                                        "type",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {CONTEXT_TYPES.map((type: any) => {
                                    return (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            {!!validationMsg && (
                                <FormHelperText error>
                                    {validationMsg}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </LoadingWrapper>
            </Modal>

            <Box mt={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="category-action"
                    filterKey={ROWRENDERERCONST.CATEGORY}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    action={{
                        label: "Add new category",
                        callback: () => {
                            toggleModal(true);
                            setCategory(INITIAL_CATEGORY);
                        },
                        disabled: false,
                    }}
                    hasFilters={false}
                />
            </Box>

            <Table
                id="category-list"
                headers={HEADERS.CATEGORY}
                rows={categoryList?.maintenanceCategoryList}
                loading={fetching}
                type={ROWRENDERERCONST.CATEGORY}
                viewingOptions={viewingOptions}
                callbacks={{
                    onCategoryDelete: item => {
                        setCategoryToDelete(item);
                    },
                    onCategoryEdit: (item, index) => {
                        toggleModal(true);
                        const selectedCategory = { ...item, index };
                        selectedCategory.countryIsoCode =
                            selectedCategory?.country?.isoCode;

                        delete selectedCategory.country;
                        setCategory(selectedCategory);
                    },
                }}
                disabled={deleting}
                emptyMsg="No categories found!"
            />
        </Fragment>
    );
});
export default MaintenanceCategory;
