// Libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import classnames from "classnames";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { displayDate, constantMapper, thousandsSeparator } from "@utils";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    outcomeId: string;
    productName: string;
    therapeuticAreaName: string;
    accountName: string;
    milestoneNo: number | string;
    sapOrderNo: string;
    scpRefNo: string;
    contractRef: string;
    salessystemOrderId: string;
    amount: number;
    percentage: number | string;
    country: string;
    createdAt: string;
    processedAt: string;
    endDate: string;
    infusionDate: string;
    sapSkuNo: string;
    status: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const CreditNotesOutcomeRow = ({
    id,
    row,
    rowIndex,
    permissions,
    isColVisible,
    callbacks,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            className={classnames(style.row, {
                [style.isClickable]:
                    permissions.hasPermissionToReadFinancialActions ||
                    permissions?.hasPermissionToEditFinancialActions,
            })}
            onClick={() => {
                !permissions.hasPermissionToReadFinancialActions &&
                permissions?.hasPermissionToEditFinancialActions
                    ? undefined
                    : callbacks?.viewCreditNoteDetails(row.outcomeId);
            }}
        >
            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("accountName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("infusionDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.infusionDate)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("salessystemOrderId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("sapOrderNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapOrderNo}</Typography>
                </TableCell>
            )}

            {isColVisible("scpRefNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.scpRefNo}</Typography>
                </TableCell>
            )}

            {isColVisible("milestoneNo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.milestoneNo !== null ? row.milestoneNo : "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("amount") && (
                <TableCell>
                    <Typography variant="caption2">
                        {thousandsSeparator(row.amount)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("percentage") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.percentage !== null ? row.percentage : "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("createdAt") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.createdAt ? displayDate(row.createdAt) : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("processedAt") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.processedAt !== null
                            ? displayDate(row.processedAt)
                            : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.status)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                {permissions &&
                    (permissions.hasPermissionToReadFinancialActions ||
                        permissions?.hasPermissionToEditFinancialActions) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <ActionMenu
                                id={`${id}-${rowIndex}-credit-note-row"`}
                                autoHide
                                autoWidth
                                stopPropagation
                                options={[
                                    {
                                        action: () =>
                                            callbacks?.reprocessCreditNote(
                                                row.outcomeId,
                                            ),
                                        label: "Reprocess outcome",
                                        disabled:
                                            row.status === "PROCESSED_BY_SAP" ||
                                            row.status === "PROCESSED" ||
                                            !permissions?.hasPermissionToEditFinancialActions,
                                    },
                                    /*  {
                                        action: () =>
                                            callbacks?.viewCreditNoteDetails(
                                                row.outcomeId,
                                            ),
                                        label: "View credit note details",
                                        disabled:
                                            !permissions.hasPermissionToReadFinancialActions &&
                                            permissions?.hasPermissionToEditFinancialActions,
                                    }, */
                                ]}
                                rowIndex={rowIndex}
                            />
                        </Box>
                    )}
            </TableCell>
        </TableRow>
    );
};

export default CreditNotesOutcomeRow;
