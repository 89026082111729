// Lib
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Popover from "@mui/material/Popover";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import debounce from "lodash.debounce";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// Style
import * as style from "./style.module.scss";

interface Price {
    priceRangeStart: string;
    priceRangeEnd: string;
}

interface Props {
    handleFilterChange: (price: Price) => void;
    id: string;
    value: Price;
    embeded?: boolean;
}

/**
 * Data Range
 */
const DataRange: React.FC<Props> = ({
    handleFilterChange,
    id,
    value,
    embeded = true,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [priceRange, setPriceRange] = useState(value);

    useEffect(() => {
        if (!embeded) return;
        if (!value.priceRangeEnd && !value?.priceRangeStart) return;

        setPriceRange(value);
    }, [value, embeded]);

    const cancel = () => {
        setAnchorEl(null);
        setPriceRange({ priceRangeStart: "", priceRangeEnd: "" });
    };

    const setAnchor = ev => {
        if (ev.target.tagName === "BUTTON") return setAnchorEl(ev.target);
        let anchor = ev.target;
        while (anchor.tagName !== "BUTTON") {
            anchor = anchor.parentElement;
        }
        setAnchorEl(anchor);
    };

    const handleRangeChange = (key: string, value: string) => {
        const copyPriceRange = { ...priceRange };
        copyPriceRange[key] = value;
        setPriceRange(copyPriceRange);
    };

    const onPriceRangeFilter = () => {
        handleFilterChange(priceRange);
        setAnchorEl(null);
    };
    useEffect(() => {
        if (embeded) return;

        const debouncedUpdate = debounce(values => {
            handleFilterChange(values);
        }, 500);

        debouncedUpdate(priceRange);

        return () => {
            debouncedUpdate.cancel();
        };
    }, [priceRange, embeded]);

    const contructRenderValue = useMemo(() => {
        if (!embeded) return;

        return (
            <Box display="flex" alignItems={"center"}>
                <Typography
                    className={style.filterCounter}
                    variant="button"
                    color="primary"
                    ml={2}
                    mr={1}
                >
                    {value.priceRangeStart && value.priceRangeEnd
                        ? 2
                        : value.priceRangeEnd || value.priceRangeStart
                          ? 1
                          : undefined}
                </Typography>
                <Typography variant="button" color="primary">
                    {"Selected"}
                </Typography>
            </Box>
        );
    }, [value, embeded]);

    return embeded ? (
        <Fragment>
            <Button
                id={`${id}-price-range-button`}
                color="primary"
                onClick={setAnchor}
                size={"small"}
                variant={"outlined"}
            >
                {"Price"}
                {contructRenderValue}
            </Button>

            <Popover
                id={`fade-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => cancel()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box p={1} display="flex" flexDirection="column">
                    <InputLabel shrink>{"Between"}</InputLabel>
                    <TextField
                        fullWidth
                        id={`${id}-between-input-textfield`}
                        name="priceRangeStart"
                        autoComplete="off"
                        variant="outlined"
                        value={priceRange.priceRangeStart}
                        onChange={ev =>
                            handleRangeChange(
                                "priceRangeStart",
                                ev.target.value,
                            )
                        }
                        size="small"
                    />
                    <Box my={2}>
                        <InputLabel shrink>{"And"}</InputLabel>

                        <TextField
                            fullWidth
                            id={`${id}-end-input-textfield`}
                            name="priceRangeEnd"
                            autoComplete="off"
                            variant="outlined"
                            value={priceRange.priceRangeEnd}
                            onChange={ev =>
                                handleRangeChange(
                                    "priceRangeEnd",
                                    ev.target.value,
                                )
                            }
                            size="small"
                        />
                    </Box>

                    <Button
                        id={`${id}-search-price-range-button`}
                        color="primary"
                        variant="contained"
                        onClick={onPriceRangeFilter}
                        size={"large"}
                    >
                        Apply
                    </Button>
                </Box>
            </Popover>
        </Fragment>
    ) : (
        <Fragment>
            <Box display="flex" gap={1}>
                <TextField
                    id={`${id}-between-input-textfield`}
                    name="priceRangeStart"
                    placeholder="Between"
                    autoComplete="off"
                    variant="outlined"
                    value={priceRange.priceRangeStart}
                    onChange={ev =>
                        handleRangeChange("priceRangeStart", ev.target.value)
                    }
                    size="small"
                />

                <TextField
                    id={`${id}-end-input-textfield`}
                    name="priceRangeEnd"
                    placeholder="And"
                    autoComplete="off"
                    variant="outlined"
                    value={priceRange.priceRangeEnd}
                    onChange={ev =>
                        handleRangeChange("priceRangeEnd", ev.target.value)
                    }
                    size="small"
                />
            </Box>
        </Fragment>
    );
};

export default React.memo(DataRange);
