import { Box } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";

// Own components
import { SearchBar, Table, Dialog } from "@components";

// constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// hooks
import {
    useViewingOptions,
    usePermission,
    useCreditNotes,
    useReprocessFinanceCreditNote,
} from "@hooks";

// types
import { SearchFilters } from "@types";

// utils
import { constructQueryString, isSuccessfulCall } from "@utils";

interface Props {
    isCreditNoteClaim?: boolean;
}
/**
 * Credit Notes
 */
const CreditNotes = ({ isCreditNoteClaim }: Props) => {
    const [reprocessId, setIdToReprocess] = useState("");

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        isCreditNoteClaim
            ? ROWRENDERERCONST.CREDIT_NOTES_CLAIM
            : ROWRENDERERCONST.CREDIT_NOTES_OUTCOME,
    );

    /**
     * API
     */
    // Get outcomes
    const { getCreditNotes, loading, response }: any =
        useCreditNotes(isCreditNoteClaim);

    /**
     * Permissions
     */
    const {
        hasPermissionToReadFinancialActions,
        hasPermissionToEditFinancialActions,
    }: any = usePermission();

    /**
     * Search handler
     */
    const onSearch = (searchFilters: SearchFilters) => {
        const hasFilters = Object.values(searchFilters).some(
            filter => filter.length,
        );

        const params = constructQueryString(searchFilters, true);

        if (hasFilters) {
            getCreditNotes(params);
        } else getCreditNotes();
    };

    const {
        reprocessFinanceCreditNote,
        loading: reprocessing,
        response: reprocessResponse,
    }: any = useReprocessFinanceCreditNote(
        isCreditNoteClaim ? "Claim|claim" : "Outcome|outcome",
        isCreditNoteClaim ? "CLAIM" : "OUTCOME",
    );

    useEffect(() => {
        if (!reprocessing && !reprocessResponse) return;

        if (!reprocessing && isSuccessfulCall(reprocessResponse?.status)) {
            setIdToReprocess("");
            getCreditNotes();
        }
    }, [reprocessing, reprocessResponse]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                id={`dashboard-credit-note-reprocess-dialog`}
                title={
                    isCreditNoteClaim ? "Reprocess claim" : "Reprocess outcome"
                }
                open={!!reprocessId}
                message={
                    isCreditNoteClaim
                        ? "Are you sure you want to resent this claim to SAP? It can lead to double payments."
                        : "Are you sure you would like to reprocess the outcome? it could lead to a extra payment."
                }
                primaryButton={{
                    text: "Confirm",
                    action: () =>
                        reprocessFinanceCreditNote(
                            reprocessId,
                            isCreditNoteClaim ? "CLAIM" : "OUTCOME",
                        ),
                    loading: reprocessing,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setIdToReprocess(""),
                }}
            />
            <Box mt={4} display="flex" justifyContent="space-between">
                <SearchBar
                    id={`dashboard-credit-note-overview-search-bar`}
                    filterKey={
                        isCreditNoteClaim
                            ? ROWRENDERERCONST.CREDIT_NOTES_CLAIM
                            : ROWRENDERERCONST.CREDIT_NOTES_OUTCOME
                    }
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder={
                        isCreditNoteClaim
                            ? "Search by claim no, account, claim status or contract reference."
                            : "Search by contract reference or cquence id"
                    }
                    searchTextOnly
                />
            </Box>

            <Table
                id={`dashboard-credit-note-overview-list`}
                headers={
                    isCreditNoteClaim
                        ? HEADERS.CREDIT_NOTES_CLAIM
                        : HEADERS.CREDIT_NOTES_OUTCOME
                }
                rows={response?.data?.records}
                loading={loading}
                type={
                    isCreditNoteClaim
                        ? ROWRENDERERCONST.CREDIT_NOTES_CLAIM
                        : ROWRENDERERCONST.CREDIT_NOTES_OUTCOME
                }
                viewingOptions={viewingOptions}
                callbacks={{
                    reprocessCreditNote: (id: string) => setIdToReprocess(id),
                    viewCreditNoteDetails: (id: string) =>
                        navigate(`/finances/credit-note-details/${id}/`),
                }}
                permissions={{
                    hasPermissionToReadFinancialActions,
                    hasPermissionToEditFinancialActions,
                }}
                emptyMsg="No data"
            />
        </Fragment>
    );
};
export default CreditNotes;
