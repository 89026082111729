//Lib
import {
    Select,
    MenuItem,
    Radio,
    Typography,
    FormControl,
    CircularProgress,
    Box,
} from "@mui/material";
import React, { useMemo } from "react";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "./style.module.scss";
import { KeyboardArrowDown } from "@mui/icons-material";

type ValueItem = {
    label: string;
    value: string;
    filterKey: string;
};

/**
 * Props type
 */
interface Props {
    id: string;
    renderValue: string;
    className?: string;
    loading: boolean;
    handleFilterChange: any;
    labelKey: string;
    valueKey: string;
    data: Array<any>;
    filterName: string;
    value: ValueItem[];
    disableItemSelection?: boolean;
    fullWidth?: boolean;
}

/**
 * Select single item
 */
const OneItemFilter: React.FC<Props> = ({
    id,
    renderValue,
    loading,
    handleFilterChange,
    labelKey,
    valueKey,
    data,
    filterName,
    value,
    disableItemSelection,
    fullWidth,
}) => {
    const mappedValue = useMemo(() => {
        if (value === undefined || !isArrayWithContent(value)) return;
        return value[0];
    }, [value]);

    const contructRenderValue = useMemo(() => {
        if (mappedValue === undefined || !mappedValue[valueKey])
            return renderValue;

        return (
            <Box display="flex" alignItems={"center"}>
                {renderValue}
                <Typography
                    className={style.filterCounter}
                    variant="button"
                    color="primary"
                    ml={2}
                    mr={1}
                >
                    {"1"}
                </Typography>
                <Typography variant="button" color="primary">
                    {"Selected"}
                </Typography>
            </Box>
        );
    }, [renderValue, mappedValue, valueKey]);

    return (
        <FormControl fullWidth={fullWidth}>
            <Select
                className={
                    mappedValue !== undefined && mappedValue[valueKey]
                        ? style.hasSelectedItems
                        : undefined
                }
                id={`${id}-one-item-filter-select`}
                value={
                    mappedValue !== undefined
                        ? JSON.stringify(mappedValue[valueKey])
                        : ""
                }
                onChange={event => {
                    const val = data?.find(
                        el => el[valueKey] === JSON.parse(event.target.value),
                    );
                    handleFilterChange(filterName, val, valueKey, true);
                }}
                disabled={loading || disableItemSelection}
                displayEmpty
                renderValue={() => (
                    <Typography variant="button">
                        {loading ? (
                            <CircularProgress color="inherit" size={15} />
                        ) : (
                            contructRenderValue
                        )}
                    </Typography>
                )}
                size="small"
                IconComponent={props => <KeyboardArrowDown {...props} />}
            >
                {isArrayWithContent(data) ? (
                    data.map((item, index) => (
                        <MenuItem
                            value={JSON.stringify(item[valueKey])}
                            key={`${id}-filter-item-key-${index}`}
                        >
                            <Radio
                                checked={
                                    mappedValue !== undefined &&
                                    item[valueKey] === mappedValue[valueKey]
                                }
                                size="small"
                            />
                            <Typography variant="caption1">
                                {item[labelKey]}
                            </Typography>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>
                        <Typography variant="caption1">No data</Typography>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default React.memo(OneItemFilter);
