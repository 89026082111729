// icon
import { KeyboardArrowDown } from "@mui/icons-material";

// Lib
import {
    Box,
    Typography,
    Grid,
    InputLabel,
    TextField,
    MenuItem,
    Select,
    FormHelperText,
    InputAdornment,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import React, {
    useState,
    Fragment,
    useEffect,
    useMemo,
    useImperativeHandle,
} from "react";

// Own components
import { SearchBar, Table, Modal, LoadingWrapper, Dialog } from "@components";

// custom hooks
import {
    useViewingOptions,
    useMaintenance,
    useLinkCategoriesToModel,
} from "@hooks";

// Constants
import {
    HEADERS,
    ROWRENDERERCONST,
    MODEL_DATA_SOURCE,
    MODEL_PRICE_SOURCE,
    CONTRACT_TYPES,
} from "@constants";

// Utils

import {
    constantMapper,
    constructQueryString,
    isArrayWithContent,
    isSuccessfulCall,
    trimAndFormat,
} from "@utils";

// Types
import { ContextItem } from "@types";

/**
 * Model type
 */
type Model = {
    index?: number;
    name: string;
    dmnId: string;
    datamodelId?: string;
    countryIsoCode: string;
    dataSource: string;
    priceSource: string;
    allowedTotalUnitsTolerance?: number | string; // if empty MUI returns empty string
    type: string;
    productContextId?: string | undefined;
    accountContextId?: string | undefined;
    regionContextId?: string | undefined;
    hasLinkedCategories?: boolean;
};

/**
 * Initial model state values
 */
const INITIAL_MODEL = {
    name: "",
    dmnId: "",
    countryIsoCode: "",
    dataSource: "",
    priceSource: "",
    allowedTotalUnitsTolerance: "",
    type: "",
    productContextId: undefined,
    accountContextId: undefined,
    regionContextId: undefined,
};

interface MaintenanceModelProps {
    onDataReady?: () => void;
    id?: string;
}

interface MaintenanceModelRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Maintenance Model
 */
const MaintenanceModel = React.forwardRef<
    MaintenanceModelRef,
    MaintenanceModelProps
>(({ onDataReady, id }, ref) => {
    const [model, setModel] = useState<Model>(INITIAL_MODEL);
    const [modelToDelete, setModelToDelete] = useState<
        { name: string; datamodelId: string } | undefined
    >(undefined);

    const [categories, setLinkCategories] = useState<string[]>([]);
    const [datamodelId, setDatamodelId] = useState("");

    const [openAddModal, toggleAddModal] = useState(false);
    const [touched, setTouched] = useState<any>({});
    const [validationMsg, setValidationMsg] = useState("");

    /**
     * Get contexts list
     */

    const {
        list: contextData,
        loading: { listLoading: fetchingContextList },
        reload: loadContextList,
    } = useMaintenance("context", "context|contexts", false);

    /**
     * Get data model list
     */
    const {
        list: dataModelList,
        loading: { listLoading: fetching, creating, deleting },
        upsert,
        delete: deleteModel,
        reload: loadModelList,
    } = useMaintenance("datamodel", "model|models");

    const {
        loading: {
            fetching: fetchingLinkedCategories,
            creating: linkingCategories,
        },
        load: loadLinkedCategories,
        upsert: linkCategories,
    } = useLinkCategoriesToModel();
    /**
     * Get data category list
     */
    const {
        list: categoryList,
        loading: { listLoading: fetchingCategories },
        reload: loadCategories,
    } = useMaintenance("category", "category|categories");

    const {
        list: countries,
        loading: { listLoading: fetchingCountries },
        reload: loadCountries,
    } = useMaintenance("country", "country|countries", false);

    /**
     * Custom Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.MODEL,
    );

    const { viewingOptions: viewingOptionsLinkCategory } = useViewingOptions(
        ROWRENDERERCONST.LINK_CATEGORY,
    );

    useEffect(() => {
        if (!openAddModal) return;
        loadCountries();
        loadContextList();
    }, [openAddModal]);

    /**
     * Fetch categories list
     */
    const loadCategoryList = (isoCode: string) => {
        if (!isoCode) return;

        const params = constructQueryString(
            {
                countryIsoCode: isoCode,
                type: "CONTRACT",
            },
            true,
        );
        loadCategories(params);
    };

    /**
     * Reset all
     */
    const resetAll = () => {
        toggleAddModal(false);
        setDatamodelId("");
        setLinkCategories([]);
        setModel(INITIAL_MODEL);
        setTouched({});
        setModelToDelete(undefined);
        setValidationMsg("");
    };

    /**
     * Change handler
     */
    const handleModelChange = (type: string, value: string | number) => {
        if (!type) return;

        const copyModel = { ...model };
        copyModel[type] = value;
        if (type === "countryIsoCode") {
            copyModel["productContextId"] = "";
            copyModel["accountContextId"] = "";
            copyModel["regionContextId"] = "";
        }

        setModel(copyModel);
    };

    /**
     * Check if the form is valid
     */
    const checkFormValidity = () => {
        if (!isArrayWithContent(dataModelList?.maintenanceDatamodelList)) {
            setValidationMsg("");
            return false; //is valid
        }

        let hasDuplicateValue = false;

        dataModelList?.maintenanceDatamodelList.forEach((item, index) => {
            if (index === model?.index) return;

            if (
                item?.name?.toUpperCase() === model?.name.toUpperCase() &&
                item?.country?.isoCode === model?.countryIsoCode
            ) {
                hasDuplicateValue = true;
            }
        });

        setValidationMsg(
            hasDuplicateValue
                ? "Name and country must be unique for each model"
                : "",
        );

        return hasDuplicateValue;
    };

    /**
     * Submit handler
     */

    const onSubmit = () => {
        const hasDuplication = checkFormValidity();

        if (hasDuplication) return;

        const mapApiData = {
            datamodelId: model?.datamodelId || undefined,
            countryIsoCode: model?.countryIsoCode,
            name: model?.name,
            dmnId: model?.dmnId,
            dataSource: model?.dataSource,
            priceSource: model?.priceSource,
            type: model?.type,
            productContextId: model?.productContextId
                ? model?.productContextId
                : null, // BE requires null if the value should be empty
            accountContextId: model?.accountContextId
                ? model?.accountContextId
                : null, // BE requires null if the value should be empty
            regionContextId: model?.regionContextId
                ? model?.regionContextId
                : null, // BE requires null if the value should be empty
            allowedTotalUnitsTolerance:
                model?.allowedTotalUnitsTolerance || undefined, // BE requires undefined if the value should be empty
        };

        upsert(mapApiData, model.name).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    /**
     * Submit link categories
     */
    const onLinkCategories = () => {
        linkCategories(datamodelId, categories).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setLinkCategories([]);
                setDatamodelId("");
                loadModelList();
            }
        });
    };

    /**
     * Fetch linked categories
     * if we find categories => store it in local state
     */
    useEffect(() => {
        if (!datamodelId) return;
        loadLinkedCategories(datamodelId).then(res => {
            if (
                isSuccessfulCall(res?.status) &&
                isArrayWithContent(res?.data?.maintenanceDatamodelCategoryList)
            ) {
                const catIDs = res?.data?.maintenanceDatamodelCategoryList.map(
                    el => el.categoryId,
                );
                setLinkCategories(catIDs);
            }
        });
    }, [datamodelId]);

    /**
     * Validation handler
     */
    const hasError = (field: string) => {
        if (!model || !touched[field]) return;
        return !model?.[field] && touched?.[field];
    };

    /**
     * Delete handler
     */
    const onModelDelete = () => {
        if (!modelToDelete) return;
        deleteModel(modelToDelete?.datamodelId, modelToDelete?.name).then(
            res => {
                if (isSuccessfulCall(res?.status)) {
                    resetAll();
                }
            },
        );
    };

    /**
     * Context mapper
     * Filter contexts based on country
     */
    const mapContext = useMemo(() => {
        if (
            !model?.countryIsoCode ||
            fetchingContextList ||
            !isArrayWithContent(contextData?.maintenanceContextList)
        )
            return {
                productContext: [],
                accountContext: [],
                regionContext: [],
            };

        const productContext: ContextItem[] = [];
        const accountContext: ContextItem[] = [];
        const regionContext: ContextItem[] = [];

        contextData?.maintenanceContextList.forEach((el: ContextItem) => {
            if (el?.country?.isoCode !== model?.countryIsoCode) return;

            if (el.type === "ACCOUNT") {
                accountContext.push(el);
            }
            if (el.type === "PRODUCT") {
                productContext.push(el);
            }
            if (el.type === "REGION") {
                regionContext.push(el);
            }
        });
        return {
            productContext,
            accountContext,
            regionContext,
        };
    }, [
        model?.countryIsoCode,
        fetchingContextList,
        contextData?.maintenanceContextList,
    ]);

    /**
     * Disable edit country
     * if is not edit mode (add new model)
     * or if there is at least product, region or account assigned to the model
     * or there is at least one category linked to this model
     */
    const disableEditCountry = useMemo(() => {
        if (model?.index === undefined) return false; // Not edit mode

        return (
            !!model.productContextId ||
            !!model?.regionContextId ||
            !!model?.accountContextId ||
            model?.hasLinkedCategories
        );
    }, [model]);

    /**
     * Add categories handler
     */
    const handleAddCategories = (categoryId: string) => {
        if (!categories) return;

        const copyCategories = [...categories];

        const catIndex = copyCategories?.findIndex(item => item === categoryId);

        // Category exists, remove it
        if (catIndex > -1) {
            const filteredCats = copyCategories?.filter(
                (_, index) => index !== catIndex,
            );
            setLinkCategories(filteredCats);
        } else {
            // Category is not exist, Add it
            copyCategories?.push(categoryId);
            setLinkCategories(copyCategories);
        }
    };

    const isCategorySelected = (categoryId: string) => {
        if (!isArrayWithContent(categories)) return;
        return categories?.includes(categoryId);
    };

    /**
     * Disable save button
     */
    const disabled =
        !model?.name ||
        !model.dmnId ||
        !model?.countryIsoCode ||
        !model.dataSource ||
        !model.priceSource ||
        !model.type;

    useEffect(() => {
        if (dataModelList && !fetching) {
            onDataReady?.();
        }
    }, [dataModelList, fetching]);

    useImperativeHandle(
        ref,
        () => ({
            getRecordsCount() {
                return {
                    itemCount: dataModelList?.maintenanceDatamodelList?.length,
                };
            },
        }),
        [dataModelList?.maintenanceDatamodelList], // Add dependency array here
    );

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                id={`${id}-delete-model`}
                title={"Delete model"}
                open={!!modelToDelete?.datamodelId}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${modelToDelete?.name})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onModelDelete,
                    loading: deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setModelToDelete(undefined),
                }}
            />

            <Modal
                id={`link-modal`}
                open={!!datamodelId}
                mediumView
                noScroll
                maxHeight="91vh"
                onClose={() => {
                    setLinkCategories([]);
                    setDatamodelId("");
                }}
                title={"Link categories"}
                primaryButton={{
                    action: () => onLinkCategories(),
                    text: "Save",
                    loading: linkingCategories,
                    disabled: fetchingCategories || linkingCategories,
                }}
                secondaryButton={{
                    action: () => {
                        setLinkCategories([]);
                        setDatamodelId("");
                    },
                    text: "Cancel",
                }}
            >
                <Table
                    id="category-list"
                    maxHeight={"20rem"}
                    headers={HEADERS.LINK_CATEGORY}
                    rows={categoryList?.maintenanceCategoryList}
                    loading={fetchingCategories || fetchingLinkedCategories}
                    type={ROWRENDERERCONST.LINK_CATEGORY}
                    viewingOptions={viewingOptionsLinkCategory}
                    callbacks={{
                        onCategorySelect: item => {
                            handleAddCategories(item?.categoryId);
                        },
                        findCategory: (categoryId: string) =>
                            !!isCategorySelected(categoryId),
                    }}
                    disabled={linkingCategories}
                    emptyMsg="No categories found!"
                    isOverview={false}
                />
            </Modal>
            <Modal
                id={`add-modal`}
                open={openAddModal}
                largeView
                onClose={resetAll}
                title={model?.datamodelId ? "Edit model" : "Add new model"}
                primaryButton={{
                    action: () => onSubmit(),
                    text: model?.datamodelId ? "Save" : "Add",
                    loading: creating,
                    disabled: disabled || fetchingCountries,
                }}
                secondaryButton={{
                    action: () => resetAll(),
                    text: "Cancel",
                }}
            >
                <LoadingWrapper
                    id={`${id}-model-loading`}
                    loading={fetchingCountries}
                >
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <InputLabel
                                shrink
                                id={`model-name`}
                                error={!!hasError("name") || !!validationMsg}
                            >
                                {"Name (*)"}
                            </InputLabel>
                            <TextField
                                id={`model-name`}
                                fullWidth
                                name={"code"}
                                onBlur={() => {
                                    if (touched?.name) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            name: true,
                                        };
                                    });
                                }}
                                error={!!hasError("name") || !!validationMsg}
                                size="small"
                                value={model?.name}
                                disabled={creating}
                                onChange={event =>
                                    handleModelChange(
                                        "name",
                                        trimAndFormat(event.target.value, true),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`dataSource`}
                                error={!!hasError("dataSource")}
                            >
                                {"Data source (*)"}
                            </InputLabel>
                            <Select
                                id={`model-data-source`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.dataSource) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            dataSource: true,
                                        };
                                    });
                                }}
                                value={model?.dataSource || ""}
                                disabled={creating}
                                size="small"
                                error={!!hasError("dataSource")}
                                onChange={event => {
                                    handleModelChange(
                                        "dataSource",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {MODEL_DATA_SOURCE.map((type: any) => {
                                    return (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`priceSource`}
                                error={!!hasError("priceSource")}
                            >
                                {"Price source (*)"}
                            </InputLabel>
                            <Select
                                id={`model-data-source`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.priceSource) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            priceSource: true,
                                        };
                                    });
                                }}
                                value={model?.priceSource || ""}
                                disabled={creating}
                                size="small"
                                error={!!hasError("priceSource")}
                                onChange={event => {
                                    handleModelChange(
                                        "priceSource",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {MODEL_PRICE_SOURCE.map((type: any) => {
                                    return (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`model-country-label`}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                            >
                                {"Country (*)"}
                            </InputLabel>
                            <Select
                                id={`model-country-select`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.countryIsoCode) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            countryIsoCode: true,
                                        };
                                    });
                                }}
                                value={model?.countryIsoCode || ""}
                                disabled={creating || disableEditCountry}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                                size="small"
                                onChange={event => {
                                    handleModelChange(
                                        "countryIsoCode",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {countries?.maintenanceCountryList?.map(
                                    (item: any) => {
                                        return (
                                            <MenuItem
                                                key={item.countryIsoCode}
                                                value={item.countryIsoCode}
                                            >
                                                {item.country}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`dmn-id`}
                                error={!!hasError("dmnId") || !!validationMsg}
                            >
                                {"DMN ID (*)"}
                            </InputLabel>
                            <TextField
                                id={`dmn-id`}
                                fullWidth
                                name={"dmnId"}
                                onBlur={() => {
                                    if (touched?.dmnId) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            dmnId: true,
                                        };
                                    });
                                }}
                                error={!!hasError("dmnId") || !!validationMsg}
                                size="small"
                                value={model?.dmnId}
                                disabled={creating}
                                onChange={event =>
                                    handleModelChange(
                                        "dmnId",
                                        trimAndFormat(event.target.value),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`model-allowedTotalUnitsTolerance`}
                            >
                                {"Allowed tolerance percentage on units"}
                            </InputLabel>
                            <NumericFormat
                                id={`model-allowedTotalUnitsTolerance`}
                                fullWidth
                                name={"allowedTotalUnitsTolerance"}
                                autoComplete="off"
                                value={model?.allowedTotalUnitsTolerance}
                                disabled={creating}
                                thousandSeparator="."
                                decimalSeparator=","
                                customInput={TextField}
                                allowNegative={false}
                                size="small"
                                isAllowed={values => {
                                    const { floatValue } = values;
                                    return floatValue
                                        ? floatValue <= 100
                                        : true;
                                }}
                                onValueChange={({ floatValue }) => {
                                    handleModelChange(
                                        "allowedTotalUnitsTolerance",
                                        floatValue?.toString() ?? "",
                                    );
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography variant="caption1">
                                                {"%"}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`type`}
                                error={!!hasError("type")}
                            >
                                {"Type (*)"}
                            </InputLabel>
                            <Select
                                id={`model-contract-type`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.type) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            type: true,
                                        };
                                    });
                                }}
                                value={model?.type || ""}
                                disabled={creating}
                                size="small"
                                error={!!hasError("type")}
                                onChange={event => {
                                    handleModelChange(
                                        "type",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {Object.values(CONTRACT_TYPES).map(
                                    (val: any) => {
                                        return (
                                            <MenuItem key={val} value={val}>
                                                {constantMapper(val)}
                                            </MenuItem>
                                        );
                                    },
                                )}

                                <MenuItem key={"FINANCIAL"} value={"FINANCIAL"}>
                                    {"Financial"}
                                </MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`model-productContextId-label`}
                                //error={!!hasError("productContextId")}
                            >
                                {"Product context"}
                            </InputLabel>
                            <Select
                                id={`model-productContextId-select`}
                                variant="outlined"
                                displayEmpty={!!model?.countryIsoCode}
                                fullWidth
                                value={model?.productContextId || ""}
                                disabled={
                                    creating ||
                                    fetchingContextList ||
                                    !model?.countryIsoCode
                                }
                                size="small"
                                // error={!!hasError("productContextId")}
                                onChange={event => {
                                    handleModelChange(
                                        "productContextId",
                                        event.target.value,
                                    );
                                }}
                                onBlur={() => {
                                    if (touched?.productContextId) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            productContextId: true,
                                        };
                                    });
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                                sx={{
                                    backgroundColor: !model?.countryIsoCode
                                        ? "light.dark"
                                        : "default",
                                }}
                            >
                                <MenuItem value={""}>Not Applicable</MenuItem>

                                {mapContext?.productContext?.map(
                                    (el: ContextItem) => {
                                        return (
                                            <MenuItem
                                                key={el.contextId}
                                                value={el.contextId}
                                            >
                                                {el.contextName}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`model-accountContextId-label`}
                                //error={!!hasError("accountContextId")}
                            >
                                {"Account context"}
                            </InputLabel>
                            <Select
                                id={`model-accountContextId-select`}
                                variant="outlined"
                                fullWidth
                                displayEmpty={!!model?.countryIsoCode}
                                value={model?.accountContextId || ""}
                                disabled={
                                    creating ||
                                    fetchingContextList ||
                                    !model?.countryIsoCode
                                }
                                sx={{
                                    backgroundColor: !model?.countryIsoCode
                                        ? "light.dark"
                                        : "default",
                                }}
                                // error={!!hasError("accountContextId")}
                                size="small"
                                onChange={event => {
                                    handleModelChange(
                                        "accountContextId",
                                        event.target.value,
                                    );
                                }}
                                onBlur={() => {
                                    if (touched?.accountContextId) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            accountContextId: true,
                                        };
                                    });
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                <MenuItem value={""}>Not Applicable</MenuItem>

                                {mapContext?.accountContext?.map(
                                    (el: ContextItem) => {
                                        return (
                                            <MenuItem
                                                key={el.contextId}
                                                value={el.contextId}
                                            >
                                                {el.contextName}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {" "}
                            <InputLabel
                                shrink
                                id={`model-regionContextId-label`}
                            >
                                {"Region context"}
                            </InputLabel>
                            <Select
                                id={`model-regionContextId-select`}
                                variant="outlined"
                                fullWidth
                                displayEmpty={!!model?.countryIsoCode}
                                value={model?.regionContextId || ""}
                                disabled={
                                    creating ||
                                    fetchingContextList ||
                                    !model?.countryIsoCode
                                }
                                size="small"
                                onChange={event => {
                                    handleModelChange(
                                        "regionContextId",
                                        event.target.value,
                                    );
                                }}
                                sx={{
                                    backgroundColor: !model?.countryIsoCode
                                        ? "light.dark"
                                        : "default",
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                <MenuItem value={""}>Not Applicable</MenuItem>

                                {mapContext?.regionContext?.map(
                                    (el: ContextItem) => {
                                        return (
                                            <MenuItem
                                                key={el.contextId}
                                                value={el.contextId}
                                            >
                                                {el.contextName}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            {!!validationMsg && (
                                <FormHelperText error>
                                    {validationMsg}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </LoadingWrapper>
            </Modal>
            <Box mt={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="model-action"
                    filterKey={ROWRENDERERCONST.MODEL}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    action={{
                        label: "Add new model",
                        callback: () => {
                            toggleAddModal(true);
                            setModel(INITIAL_MODEL);
                        },
                        disabled: false,
                    }}
                    hasFilters={false}
                />
            </Box>

            <Table
                id="model-list"
                headers={HEADERS.MODEL}
                rows={dataModelList?.maintenanceDatamodelList}
                loading={fetching}
                type={ROWRENDERERCONST.MODEL}
                viewingOptions={viewingOptions}
                callbacks={{
                    onLinkCategories: item => {
                        loadCategoryList(item?.country?.isoCode);
                        setDatamodelId(item.datamodelId);
                    },
                    onModelDelete: item => {
                        setModelToDelete(item);
                    },
                    onModelEdit: (item, index) => {
                        toggleAddModal(true);
                        const selectedModel = { ...item, index };
                        selectedModel.countryIsoCode =
                            selectedModel?.country?.isoCode;

                        selectedModel.productContextId =
                            selectedModel?.productContext?.id;

                        selectedModel.accountContextId =
                            selectedModel?.accountContext?.id;

                        selectedModel.regionContextId =
                            selectedModel?.regionContext?.id;

                        selectedModel.hasLinkedCategories =
                            !!item.nrCategories &&
                            typeof parseInt(item.nrCategories) === "number" &&
                            parseInt(item.nrCategories) > 0;

                        delete selectedModel.country;
                        delete selectedModel?.regionContext;
                        delete selectedModel?.accountContext;
                        delete selectedModel?.productContext;
                        setModel(selectedModel);
                    },
                }}
                disabled={deleting}
                emptyMsg="No models found!"
            />
        </Fragment>
    );
});
export default MaintenanceModel;
