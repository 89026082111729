// Lib
import React, { useEffect, useImperativeHandle } from "react";

// Own components
import { MaintenanceTransactionValuesList } from "@components";

// Hooks
import { useMaintenance } from "@hooks";
import { isSuccessfulCall, sortByField } from "@utils";

const FIELDS = [
    {
        id: "countryIsoCode",
        inputLabel: "Country",
        required: true,
    },
    {
        id: "brandId",
        inputLabel: "Brand",
        required: true,
    },
    {
        id: "orderType",
        inputLabel: "Order type",
        required: true,
    },
    {
        id: "bpfId",
        inputLabel: "Bpf Id",
        required: true,
    },
    {
        id: "salesOrg",
        inputLabel: "Organisation",
        required: false,
    },
    {
        id: "division",
        inputLabel: "Division",
        required: false,
    },
    {
        id: "distributionChannel",
        inputLabel: "Distribution channel",
        required: false,
    },

    {
        id: "conditionType",
        inputLabel: "Condition",
        required: false,
    },
    {
        id: "entryType",
        inputLabel: "Entry type",
        required: false,
    },
    {
        id: "legalEntity",
        inputLabel: "Legal entity",
        required: false,
    },
    {
        id: "fullReportingMrc",
        inputLabel: "MRC",
        required: false,
    },
    {
        id: "organizationalUnit",
        inputLabel: "Organizational unit",
        required: false,
    },
    {
        id: "transactionType",
        inputLabel: "Transaction type",
        required: false,
    },
    {
        id: "debetCredit",
        inputLabel: "Debet credit",
        required: false,
    },
    {
        id: "accountType",
        inputLabel: "Account type",
        required: false,
    },
    {
        id: "paymentTerms",
        inputLabel: "Payment terms",
        required: false,
    },
    {
        id: "paymentMethod",
        inputLabel: "Payment method",
        required: false,
    },
    {
        id: "glAccount",
        inputLabel: "GL account",
        required: false,
    },

    {
        id: "commodityCode",
        inputLabel: "Commodity code",
        required: false,
    },
    {
        id: "companyCode",
        inputLabel: "Company code",
        required: false,
    },
    {
        id: "taxCode",
        inputLabel: "Tax code",
        required: false,
    },
    {
        id: "referenceDocument",
        inputLabel: "Reference document",
        required: false,
    },
    {
        id: "cnReferenceQualifier",
        inputLabel: "Cn reference qualifier",
        required: false,
    },
    {
        id: "hccProfessionalPayment",
        inputLabel: "HCC Professional Payment",
        required: false,
    },
    {
        id: "hccProductRebate",
        inputLabel: "HCC Product Rebate",
        required: false,
    },
    {
        id: "hccGovernmentIndicator",
        inputLabel: "HCC Government Indicator",
        required: false,
    },
];

interface TransactionValuesProps {
    onDataReady?: () => void;
    id?: string;
}

interface TransactionValuesRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Transaction Values
 */
const TransactionValues = React.forwardRef<
    TransactionValuesRef,
    TransactionValuesProps
>(({ onDataReady, id }, ref) => {
    /**
     * API
     */
    const {
        list: transactionValues,
        loading: {
            listLoading: fetchingTransactionValues,
            creating: creatingTransactionValues,
        },
        upsert,
    } = useMaintenance(
        "financialTransactionValue",
        "financial transaction value|financial transaction values",
    );

    const {
        list: countries,
        loading: { listLoading: fetchingCountries },
    } = useMaintenance("country", "country|countries");

    const {
        list: brands,
        loading: { listLoading: fetchingBrands },
    } = useMaintenance("brand", "brand|brands");

    const handleSubmit = values => {
        const mappedValues = {
            ...values,
            countryIsoCode: values?.countryIsoCode?.countryIsoCode,
            brandId: values?.brandId?.brandId,
        };

        return upsert(mappedValues, "Transaction value").then(res =>
            isSuccessfulCall(res?.status),
        );
    };

    useEffect(() => {
        if (
            transactionValues?.maintenanceFinanceTransactionValueList &&
            !fetchingTransactionValues
        ) {
            onDataReady?.();
        }
    }, [
        transactionValues?.maintenanceFinanceTransactionValueList,
        fetchingTransactionValues,
    ]);

    useImperativeHandle(
        ref,
        () => ({
            getRecordsCount() {
                return {
                    itemCount:
                        transactionValues
                            ?.maintenanceFinanceTransactionValueList?.length,
                };
            },
        }),
        [transactionValues?.maintenanceFinanceTransactionValueList], // Add dependency array here
    );

    /**
     * Render
     */
    return (
        <MaintenanceTransactionValuesList
            title={"Interface"}
            id={`${id}-transaction-values-list`}
            list={transactionValues?.maintenanceFinanceTransactionValueList}
            loading={{
                fetchingCountries,
                fetchingTransactionValues,
                fetchingBrands,
                creatingTransactionValues,
            }}
            fields={FIELDS}
            //error={{ transactionValuesError, countriesError, brandError }}
            brands={sortByField(brands?.maintenanceBrandList, "brandName")}
            countries={sortByField(
                countries?.maintenanceCountryList,
                "country",
            )}
            onSubmit={handleSubmit}
        />
    );
});
export default TransactionValues;
