// Libs
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";

// Own components
import { Breadcrumbs } from "@components";

// Types
import { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
}

interface TasksRef {
    getRecordsCount: () => { itemCount: number | undefined };
}

/**
 * Child component props
 */
interface ChildComponentProps {
    ref: React.RefObject<TasksRef>;
    onDataReady: () => void;
    id: string;
}

/**
 * Tasks
 */
const Tasks = ({ location, children }: Props) => {
    const childRef = React.useRef<TasksRef>(null);
    const [itemCount, setItemCount] = useState<number | undefined>(undefined);

    const handleDataReady = () => {
        if (childRef.current) {
            const data = childRef.current.getRecordsCount();
            setItemCount(data.itemCount);
        }
    };

    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Ongoing",
                path: "/tasks/ongoing-tasks/",
            },
            {
                title: "Completed",
                path: "/tasks/completed-tasks/",
            },
        ];
    }, []);

    const findSubpage = useMemo(() => {
        return tabs.find(tab => tab.path?.includes(location.pathname));
    }, [location.pathname, tabs]);

    /**
     * Render
     */
    return (
        <div>
            <Breadcrumbs
                id={`tasks`}
                icon={<AssignmentIcon color="primary" />}
                title="Tasklist"
                location={location}
                itemCount={itemCount}
                subPage={findSubpage?.title}
            />

            <Box px={7}>
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(
                            child as React.ReactElement<ChildComponentProps>,
                            {
                                ref: childRef,
                                id: "tasks",
                                onDataReady: handleDataReady,
                            },
                        );
                    }
                    return child;
                })}
            </Box>
        </div>
    );
};
export default Tasks;
