import { Box, Typography, Button } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useCallback } from "react";

// types
import { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
    >;
    onDataReady?: () => void;
}

const SUB_PAGES = [
    {
        path: "/maintenance/teams/",
        label: "Teams",
    },
    {
        path: "/maintenance/roles/",
        label: "Roles",
    },
];

// Add this interface near the top with other interfaces
interface ChildComponentProps {
    ref: React.RefObject<any>; // Changed to any since ClaimsOverviewRef is not defined
    onDataReady: () => void;
}

/**
 * Teams Layout
 */
const TeamsLayout = React.forwardRef<any, Props>(
    ({ location, children, onDataReady }, ref) => {
        /**
         * Check if the page is active
         */
        const isActive = useCallback(
            page => {
                return location.pathname.includes(page);
            },
            [location],
        );

        const childWithRef = React.useMemo(() => {
            return React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(
                        child as React.ReactElement<ChildComponentProps>,
                        {
                            ref: ref as React.RefObject<any>,
                            onDataReady,
                        },
                    );
                }
                return child;
            });
        }, [children]);

        /**
         * Render
         */
        return (
            <Fragment>
                <Box mb={5} mt={3} display="flex" alignItems="center">
                    {SUB_PAGES.map((page, index) => (
                        <Button
                            id={`dashboard-financial-transaction-type-btn-${index}`}
                            key={index}
                            onClick={() => navigate(page.path)}
                            variant="outlined"
                            size="large"
                            sx={{
                                fontSize: "0.875rem",
                                backgroundColor: isActive(page.path)
                                    ? "#e6f5fc"
                                    : "none",
                                borderColor: "transparent",
                                padding: "0.3rem 1.3rem",
                                marginRight: "1rem",
                            }}
                        >
                            <Typography variant="subtitle2">
                                {page.label}
                            </Typography>
                        </Button>
                    ))}
                </Box>

                <Box mt={5}>{childWithRef}</Box>
            </Fragment>
        );
    },
);
export default TeamsLayout;
