// Lib
import { Box, Typography, Grid } from "@mui/material";
import { useFormik } from "formik";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Own components
import {
    AddProduct,
    FormFooter,
    Modal,
    Table,
    FieldRenderer,
} from "@components";

// Custom hooks
import {
    useContractsProducts,
    useUpdateStep,
    useFormValidation,
    useContractProductPrices,
} from "@hooks";

// Atoms
import { productsDetailsState, generalInformationState } from "@atoms";

// Schemas
import { performanceBasedProductDetailsSchema } from "@schemas";

// Types
import type { FlowStep, Location, PriceConditionType } from "@types";

// Utils
import { constructQueryString } from "@utils";

// Constants
import { ROWRENDERERCONST, HEADERS } from "@constants";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;

    flowState?: Array<FlowStep>;
}

/**
 * Add Performance Based Product
 */
const AddPerformanceBasedProduct = React.forwardRef(
    ({ location, currentStep, flowState }: Props, ref) => {
        const [showPrices, togglePricesModal] = useState({
            productName: undefined,
            sapSkuNo: undefined,
        });

        /**
         * API
         */
        const {
            getContractsProducts,
            response,
            loading: loadingProducts,
            error: productsErrors,
        } = useContractsProducts();

        const {
            getPrices,
            loading: pricesLoading,
            response: prices,
        } = useContractProductPrices();

        /**
         * Atoms
         */

        // Product details state
        const [contractProducts, updateProductDetails] =
            useRecoilState(productsDetailsState);

        // General information
        const generalInformation = useRecoilValue(generalInformationState);

        /**
         * Hooks
         */

        // Update step hook
        const updateStepValidation = useUpdateStep(location, flowState);

        /**
         * Formik state
         */
        const formik: any = useFormik({
            initialValues: { ...contractProducts },
            validationSchema: performanceBasedProductDetailsSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        /**
         * Form validation
         */
        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
            currentStep,
        );

        /**
         * Form validation check
         */
        const canSaveState = formik.isValid || isValidForm;

        useEffect(() => {
            if (!generalInformation) return;

            const query = constructQueryString(
                {
                    countryIsoCode: generalInformation?.country?.isoCode,
                    brandId: generalInformation?.brand?.brandId,
                },
                true,
            );

            getContractsProducts(query);
        }, [generalInformation]);

        /**
         * Check if the current step is valid
         * => set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                updateProductDetails(formik.values);
            },
        }));

        useEffect(() => {
            if (!!showPrices?.productName && generalInformation) {
                const query = constructQueryString(
                    {
                        sapSkuNo: formik.values.products[0]?.product?.sapSkuNo,
                        pricesFrom: generalInformation.startDate,
                        pricesTo: generalInformation.endDate,
                    },
                    true,
                );
                getPrices(query);
            }
        }, [showPrices]);

        /**
         * Change product handler
         */
        const onChange = (product: any) => {
            const values: any = [...formik.values.products];
            values[0] = product;
            formik.setValues({ products: values });
        };

        /**
         * Change price handler
         */
        const handlePriceChange = (value: any) => {
            const values: any = [...formik.values.products];

            const copyValue = values[0] ? { ...values[0] } : {};
            copyValue["priceCondition"] = value;
            values[0] = copyValue;

            formik.setValues({ products: values });
        };

        /**
         * Renders
         */
        return (
            <Fragment>
                <Modal
                    open={!!showPrices?.productName}
                    id={`contracts-products-prices`}
                    title={"Price table"}
                    onClose={() =>
                        togglePricesModal({
                            sapSkuNo: undefined,
                            productName: undefined,
                        })
                    }
                >
                    <div>
                        <Grid container item xs={12} spacing={3} mb={5}>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`dashboard-products-overview-products-prices-product-name`}
                                    label="Product name"
                                    value={showPrices?.productName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`dashboard-products-overview-products-prices-product-sku-number`}
                                    label="SAP SKU number"
                                    value={showPrices?.sapSkuNo}
                                />
                            </Grid>
                        </Grid>

                        <Table
                            headers={HEADERS.PRICES}
                            rows={prices?.data || []}
                            loading={pricesLoading}
                            type={ROWRENDERERCONST.PRICES}
                            id={`products-prices`}
                            emptyMsg={"No data"}
                            callbacks={{
                                selectCondition: (
                                    priceCondition: PriceConditionType,
                                ) => {
                                    handlePriceChange({
                                        priceCondition:
                                            priceCondition?.condition,
                                        currency: priceCondition?.currency,
                                        price: priceCondition?.price,
                                    });

                                    togglePricesModal({
                                        sapSkuNo: undefined,
                                        productName: undefined,
                                    });
                                },
                            }}
                            isOverview={false}
                        />
                    </div>
                </Modal>

                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height={1}
                >
                    <div>
                        <Box mb={7}>
                            <Typography variant="h2">
                                Product details
                            </Typography>
                        </Box>
                        <AddProduct
                            id={"add-single-product-form"}
                            onChange={onChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors}
                            touched={formik.touched}
                            product={formik.values?.products[0]}
                            products={{
                                data: response?.data?.records,
                                loading: loadingProducts,
                                error: productsErrors,
                            }}
                            onShowPricesClick={() =>
                                togglePricesModal({
                                    sapSkuNo:
                                        formik.values?.products[0]?.product
                                            ?.sapSkuNo,
                                    productName:
                                        formik.values?.products[0]?.product
                                            ?.productName,
                                })
                            }
                        />
                    </div>
                    <FormFooter
                        error={hasErrors}
                        textAlign="right"
                        id={"new-product-form-footer"}
                    />
                </Box>
            </Fragment>
        );
    },
);
export default AddPerformanceBasedProduct;
