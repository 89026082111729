//import libs
import { Box, TableCell, TableRow, Typography, Radio } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Import utils
import { constantMapper } from "@utils";

// styles
import * as style from "../../style.module.scss";

// import types
import { Model } from "@types";

interface Props {
    row: Model & { active: boolean; nrCategories: string; dmnId: string };
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    disabled?: boolean;
    selectedRow?: any;
}

/**
 * Model row
 */
const ModelRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    disabled,
    selectedRow,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            selected={selectedRow === row.datamodelId}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            className={!callbacks?.onModelSelect ? style.row : undefined}
            onClick={
                callbacks.onModelSelect
                    ? event => {
                          callbacks.onModelSelect(
                              row,
                              parseInt(
                                  (event.target as HTMLInputElement).value,
                              ),
                          );
                      }
                    : undefined
            }
        >
            {callbacks?.onModelSelect && (
                <TableCell width="4.6875rem">
                    <Radio
                        onChange={event => {
                            callbacks.onModelSelect(
                                row,
                                parseInt(event.target.value),
                            );
                        }}
                        checked={selectedRow === row.datamodelId}
                        value={rowIndex}
                        inputProps={{ "aria-label": `${id}-row-${rowIndex}` }}
                        required
                        id={`${id}-row-${rowIndex}-select-model`}
                    />
                </TableCell>
            )}
            {isColVisible("name") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.name || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.country?.name || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("currency") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.currency || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("type") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.type) || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("dataSource") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.dataSource || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("priceSource") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.priceSource || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("dmnId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.dmnId || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("accountContext") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.accountContext?.name || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("regionContext") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.regionContext?.name || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("productContext") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.productContext?.name || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("allowedTotalUnitsTolerance") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.allowedTotalUnitsTolerance
                            ? `${row.allowedTotalUnitsTolerance}%`
                            : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("nrCategories") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.nrCategories || "-"}
                    </Typography>
                </TableCell>
            )}

            {!callbacks?.onModelSelect && (
                <TableCell align="right" className={style.action}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-${rowIndex}-task-row"`}
                            autoHide
                            stopPropagation
                            disabled={disabled}
                            options={[
                                {
                                    label: "Link categories",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.onLinkCategories(
                                            row,
                                            rowIndex,
                                        ),
                                    disabled: row?.active,
                                },
                                {
                                    label: "Edit",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.onModelEdit(row, rowIndex),
                                    disabled: row?.active,
                                },
                                {
                                    label: "Delete",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.onModelDelete(row),
                                    disabled: row?.active,
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};

export default ModelRow;
