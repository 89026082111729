// Libs
import { Box } from "@mui/material";
import { navigate } from "gatsby";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
} from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useTasks,
    useViewingOptions,
    useGetTaskDefinitionKeys,
    usePermission,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    constructQueryString,
    isArrayWithContent,
    stringTransformer,
} from "@utils";

/**
 * Props type
 */
interface TasksProps {
    onDataReady?: () => void;
    id: string;
}

interface TaskssRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}
/**
 * CompletedTasks
 */
const CompletedTasks = React.forwardRef<TaskssRef, TasksProps>(
    ({ onDataReady, id }, ref) => {
        /**
         * API
         */

        const {
            loading: { listLoading },
            response: { list },
            error: { listError },
            search: searchTasks,
        } = useTasks(true);

        const {
            loading: fetchingTaskDefinitionKeys,
            list: taskDefinitionKeys,
        } = useGetTaskDefinitionKeys();

        /**
         * Hooks
         */
        // Viewing options
        const { viewingOptions, setViewingOptions } = useViewingOptions(
            ROWRENDERERCONST.COMPLETED_TASKS,
        );

        // Permissions
        const { hasPermissionToEditDataTables }: Permissions = usePermission();

        /**
         * Map Task definitions key
         */

        const mappedTaskDefinitionKeys = useMemo(() => {
            if (!isArrayWithContent(taskDefinitionKeys?.data?.records))
                return [];

            return taskDefinitionKeys?.data?.records?.map(key => {
                return {
                    keyName: stringTransformer(key),
                    keyId: key,
                };
            });
        }, [taskDefinitionKeys]);

        /**
         * Search handler
         */
        const onSearch = (searchFilters: SearchFilters) => {
            const params = constructQueryString(searchFilters);

            searchTasks(params);
        };

        useEffect(() => {
            if (list?.data?.records && !listLoading) {
                onDataReady?.();
            }
        }, [list?.data?.records, listLoading]);

        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount: list?.data?.records?.length,
                    };
                },
            }),
            [list?.data?.records], // Add dependency array here
        );

        /**
         * Render
         */
        return (
            <Fragment>
                <Box mt={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id={`${id}-task-overview`}
                        filterKey={ROWRENDERERCONST.COMPLETED_TASKS}
                        placeholder="Search by contract reference"
                        action={{ hidden: true }}
                        viewingOptions={viewingOptions}
                        showTasksTypeFilter
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        startDate={false}
                        endDate={false}
                        setViewingOptions={setViewingOptions}
                        taskDefinitionKeys={{
                            loading: fetchingTaskDefinitionKeys,
                            data: mappedTaskDefinitionKeys,
                        }}
                        disableSelectionFilters={listLoading}
                    />
                </Box>

                <Table
                    id={`${id}-task-overview`}
                    headers={HEADERS.COMPLETED_TASKS}
                    rows={list?.data?.records || []}
                    loading={listLoading}
                    error={listError}
                    type={ROWRENDERERCONST.COMPLETED_TASKS}
                    viewingOptions={viewingOptions}
                    callbacks={{
                        viewTaskDetails: (taskId: string) =>
                            navigate(`/tasks/task-details/${taskId}/`),
                    }}
                    emptyMsg="No tasks found"
                    permissions={{ hasPermissionToEditDataTables }}
                />
            </Fragment>
        );
    },
);
export default CompletedTasks;
