// Lib
import { Box, Typography } from "@mui/material";
import React from "react";

// Own components
import { SearchBar, Table } from "@components";

//Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Custom hooks
import {
    useBrands,
    useOutcome,
    useContractPartners,
    useTeamTherapeuticArea,
    useProducts,
    useGetContracts,
} from "@hooks";

// Types
import type { SearchFilters } from "@types";

/**
 * Utils
 */
import {
    apiResponseCounter,
    constructQueryString,
    isArrayWithContent,
} from "@utils";

/**
 * Props
 */
interface Props {
    setContract?: (value: any) => void;
    contractId?: string;
    id?: string;
    isVolumeBased?: boolean;
    isApheresisBased?: boolean;
}

/**
 * SelectContract
 */
const SelectContract = ({
    setContract,
    contractId,
    id,
    isVolumeBased,
    isApheresisBased,
}: Props) => {
    //Contracts
    const {
        list: outcomeContractList,
        loading: fetchingOutcomeContracts,
        search: searchOutcomeContracts,
        reload: reloadOutcomeContracts,
    } = useOutcome(`contracts`, "contract|contracts");

    const {
        list: claimContractsList,
        loading: fetchingClaimContracts,
        search: searchClaimContracts,
    } = useGetContracts();

    //Partners
    const {
        getPartners,
        response: partners,
        loading: partnersLoading,
    } = useContractPartners(false);

    // Brands
    const { list: brands, loading: brandsLoading } = useBrands();

    // Therapeutic area
    const { list: therapeuticAreas, loading: therapeuticAreasLoading } =
        useTeamTherapeuticArea();

    //Products
    const { response: product, loading: productLoading } = useProducts();

    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        if (isVolumeBased) {
            const params = constructQueryString(filters);
            if (hasFilters)
                searchClaimContracts(
                    `?status=INACTIVE&status=ACTIVE&status=CLOSED&type=VOLUME${params}`,
                );
            else
                searchClaimContracts(
                    `?status=INACTIVE&status=ACTIVE&status=CLOSED&type=VOLUME`,
                );
        } else {
            const params = constructQueryString(filters);
            if (hasFilters || isApheresisBased)
                searchOutcomeContracts(
                    `${isApheresisBased ? "?type=REBATE_AT_APHERESIS" : "?"}${params}`,
                );
            else reloadOutcomeContracts();
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <div>
                <Box mb={7}>
                    <Typography variant="h2">Contract selection</Typography>
                </Box>

                <SearchBar
                    id={`${id}-contract-selection-search-bar`}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    simpleLayout
                    partners={{
                        data: partners?.data,
                        loading: partnersLoading,
                        onSearch: (query: string) => getPartners(query),
                    }}
                    products={{
                        data: product?.data?.records,
                        loading: productLoading,
                    }}
                    therapeuticAreas={{
                        data: therapeuticAreas?.data?.records,
                        loading: therapeuticAreasLoading,
                    }}
                    brands={{
                        data: brands?.data,
                        loading: brandsLoading,
                    }}
                    placeholder="Search by reference"
                    searchCallback={() => !!setContract && setContract("")}
                />
                <Box display="flex" alignItems="baseline" mt={3}>
                    <Typography variant="h3">Search results</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            outcomeContractList || claimContractsList,
                            fetchingOutcomeContracts || fetchingClaimContracts,
                            "contract|contracts",
                        )}
                    </Typography>
                </Box>

                {(isArrayWithContent(outcomeContractList?.data?.records) ||
                    fetchingOutcomeContracts) && (
                    <Table
                        id={`${id}-outcome-contract-selection-list`}
                        headers={HEADERS.OUTCOME_CONTRACT_SELECTION}
                        rows={outcomeContractList?.data?.records}
                        loading={fetchingOutcomeContracts}
                        type={ROWRENDERERCONST.OUTCOME_CONTRACT_SELECTION}
                        maxHeight="30rem"
                        callbacks={{
                            onSelect: row => {
                                !!setContract && setContract(row);
                            },
                        }}
                        selectedRow={contractId}
                        emptyMsg="No contracts found!"
                        isOverview={false}
                    />
                )}

                {(isArrayWithContent(claimContractsList?.data?.records) ||
                    fetchingClaimContracts) && (
                    <Table
                        id={`${id}-claim-contract-selection-list`}
                        headers={HEADERS.CLAIM_CONTRACT}
                        rows={claimContractsList?.data?.records}
                        loading={fetchingClaimContracts}
                        type={ROWRENDERERCONST.CLAIM_CONTRACT}
                        maxHeight="30rem"
                        callbacks={{
                            onSelect: row => {
                                !!setContract && setContract(row);
                            },
                        }}
                        selectedRow={contractId}
                        emptyMsg="No contracts found!"
                        isOverview={false}
                    />
                )}
            </div>
        </Box>
    );
};

export default SelectContract;
