//import libs
import { Box, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import classnames from "classnames";
import React, { useMemo } from "react";

// Icons
import AttachFileIcon from "@mui/icons-material/AttachFile";

// import own components
import { ActionMenu } from "@components";

// Utils
import { displayDate, isArrayWithContent, truncateArray } from "@utils";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    reference: string;
    brandId: string;
    therapeuticArea: string;
    endDate: string;
    startDate: string;
    status: { id: string; name: string };
    statusId: string;
    id: string;
    type: string;
    primaryContractPartner: { name: string };
    partners: Array<{ name: string }>;
    categories: Array<{ categoryLabel: string; categoryValue: string }>;
    products: Array<{ name: string }>;
    cancellable: boolean;
    nrOfEvidenceFilesAttached: number;
    contractType: "VOLUME" | "PERFORMANCE";
    brands: Array<{ name: string; id: string }>;
    icdNo?: string;
    icdUrl?: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const ContractsRow = ({
    id,
    row,
    rowIndex,
    permissions,
    isColVisible,
    callbacks,
}: Props) => {
    /**
     * Render Categories col
     */
    const mappedCategories = useMemo(() => {
        if (!isArrayWithContent(row?.categories)) return [];

        return row?.categories?.map(el => {
            return `${el.categoryLabel}: ${el.categoryValue}`;
        });
    }, [row?.categories]);

    return (
        <TableRow
            className={classnames(style.row, {
                [style.isClickable]: permissions?.hasPermissionToReadContract,
            })}
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            onClick={() => {
                callbacks && callbacks?.viewContract(row?.id);
            }}
        >
            <TableCell padding="none">
                {!!row.nrOfEvidenceFilesAttached &&
                    row.nrOfEvidenceFilesAttached > 0 && (
                        <AttachFileIcon
                            sx={{ width: "1rem", height: "1rem" }}
                            color="inherit"
                        />
                    )}
            </TableCell>
            {isColVisible("reference") && (
                <TableCell>
                    <Typography variant="caption2">{row.reference}</Typography>
                </TableCell>
            )}

            {isColVisible("brandName") && (
                <TableCell>
                    {isArrayWithContent(row.brands) ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={truncateArray(row.brands, undefined, "name")}
                        >
                            <Typography variant="caption2">
                                {truncateArray(row.brands, 2, "name")}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}

            {isColVisible("therapeuticAreaName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.therapeuticArea}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("primaryContractPartner") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.primaryContractPartner?.name}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("additionalPartners") && (
                <TableCell>
                    {isArrayWithContent(row.partners) ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={truncateArray(
                                row.partners,
                                undefined,
                                "name",
                            )}
                        >
                            <Typography variant="caption2">
                                {truncateArray(row.partners, 2, "name")}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}

            {isColVisible("products") && (
                <TableCell>
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(row.products, undefined, "name")}
                    >
                        <Typography variant="caption2">
                            {truncateArray(row.products, 2, "name")}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )}

            {isColVisible("categories") && (
                <TableCell>
                    {isArrayWithContent(row.categories) ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={truncateArray(mappedCategories, undefined)}
                        >
                            <Typography variant="caption2">
                                {truncateArray(mappedCategories, 2)}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("validFrom") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.startDate)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("validTo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.endDate)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.status?.name}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-row-${rowIndex}-contract-row`}
                        autoHide
                        stopPropagation
                        options={[
                            /*  {
                                label: "View contract",
                                action: () =>
                                    callbacks &&
                                    callbacks?.viewContract(row?.id),

                                disabled:
                                    !permissions?.hasPermissionToReadContract,
                            }, */
                            {
                                label: "Edit contract",
                                action: () =>
                                    callbacks &&
                                    callbacks?.editContract(
                                        row?.id,
                                        [
                                            "CLOSED",
                                            "INACTIVE",
                                            "ACTIVE",
                                            "CANCELLED",
                                            "PENDING",
                                        ].includes(row?.status?.id),
                                        {
                                            reference: row?.reference,
                                            icdUrl: row.icdUrl,
                                            icdNo: row.icdNo,
                                        },
                                    ),
                                disabled:
                                    !permissions?.hasPermissionToEditContract,
                            },
                            {
                                label: "Duplicate contract",
                                action: () =>
                                    callbacks &&
                                    callbacks?.duplicateContract(row?.id),
                                disabled:
                                    row?.contractType === "VOLUME" ||
                                    !permissions?.hasPermissionToReadContract ||
                                    !permissions?.hasPermissionToCreateContract ||
                                    !["CLOSED", "ACTIVE", "INACTIVE"].includes(
                                        row.status?.id,
                                    ),
                                hidden: row?.contractType === "VOLUME",
                            },
                            {
                                label: "Upload files",

                                action: () =>
                                    callbacks &&
                                    callbacks?.uploadDocument(row?.id),
                                disabled:
                                    (!permissions?.hasPermissionToEditOutcome &&
                                        !permissions?.hasPermissionToEditContract) ||
                                    !["ACTIVE", "INACTIVE"].includes(
                                        row.status?.id,
                                    ),
                            },
                            {
                                label: "Cancel contract",
                                action: () =>
                                    callbacks &&
                                    callbacks?.cancelContract(
                                        row?.id,
                                        row?.reference,
                                    ),
                                disabled:
                                    !permissions?.hasPermissionToEditContract ||
                                    !row.cancellable,
                            },
                            {
                                label: "Change contract date",
                                action: () =>
                                    callbacks &&
                                    callbacks?.changeContractDate(
                                        row?.id,
                                        row.reference,
                                        row?.startDate,
                                        row?.endDate,
                                    ),
                                disabled:
                                    row?.contractType !== "VOLUME" ||
                                    !permissions?.hasPermissionToEditContract ||
                                    row?.status?.id !== "ACTIVE",
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ContractsRow;
