// lib
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    TextField,
    Typography,
    InputAdornment,
    Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import clonedeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// Hooks
import { useValidateDuplication } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";

// Own components
import { Table, Select, Modal } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

/**
 * Transaction values types
 */
interface TransactionValues {
    accountType?: string;
    bpfId?: string;
    brandId: string;
    commodityCode?: string;
    companyCode?: string;
    conditionType?: string;
    countryIsoCode: string;
    debetCredit?: string;
    distributionChannel?: string;
    division?: string;
    entryType?: string;
    ftvId?: string;
    fullReportingMrc?: string;
    glAccount?: string;
    legalEntity?: string;
    orderType?: string;
    organizationalUnit?: string;
    paymentMethod?: string;
    paymentTerms?: string;
    referenceDocument?: string;
    salesOrg?: string;
    taxCode?: string;
    transactionType?: string;
}
/**
 * Viewable Fields Props type
 */
interface ViewableFields {
    id: string;
    notEditable?: boolean;
    isSavedLabel?: boolean;
    inputLabel: string;
    required: boolean;
}

/**
 * Props type
 */
interface Props {
    fields: Array<ViewableFields>;
    id: string;
    title: string;
    list: Array<TransactionValues>;
    brands: any;
    countries: any;
    loading: {
        fetchingCountries: boolean;
        fetchingTransactionValues: boolean;
        fetchingBrands: boolean;
        creatingTransactionValues: boolean;
    };
    onSubmit: (values: any) => Promise<any>;
}

/**
 * Maintenance Transaction Values List
 */
const MaintenanceTransactionValuesList: React.FC<Props> = ({
    id,
    list,
    loading,
    fields,
    onSubmit,
    brands,
    countries,
}) => {
    /**
     * States
     */
    const [selectedItemIndex, setIndex] = useState<undefined | number>(
        undefined,
    );
    const [openModal, toggleModal] = useState(false);
    const [values, setValues] = useState<
        TransactionValues | Record<string, never>
    >({});
    const [editMode, setEditMode] = useState<boolean>(false);
    const [touched, setTouched] = useState({});

    /**
     * check if bpf is duplicate or not
     */
    const {
        validate,
        isNotUniqueValue,
        isNotUniqueMessage,
        loading: duplicationValidating,
        forceReset,
    } = useValidateDuplication("bpfId");

    /**
     * Custom disabled status
     * Check if the required fields are filled in
     * Check if at least bpfId OR orderType is there
     */
    const disabled = Object.values(fields).some(field => {
        return (
            (!values["bpfId"] && !values["orderType"]) ||
            (field.id !== "bpfId" &&
                field.id !== "orderType" &&
                field?.required &&
                !values[field.id]) ||
            // (!editMode && values["bpfId"] && isNotUniqueValue) ||
            duplicationValidating ||
            isNotUniqueValue
        );
    });

    useEffect(() => {
        if (!openModal) {
            resetAll();
        }
    }, [openModal]);

    const resetAll = () => {
        setValues({});
        setTouched({});
        setEditMode(false);
        forceReset();
        setIndex(undefined);
    };

    /**
     * Map TherapeuticArea & countries
     */
    const mapper = (field: string, id: string) => {
        if (
            !field ||
            !isArrayWithContent(brands) ||
            !isArrayWithContent(countries)
        )
            return;

        if (field === "brands") {
            const filteredBrands = brands.find(
                element => element.brandId === id,
            );
            return filteredBrands;
        }

        if (field === "countries") {
            const filteredCountries = countries?.find(
                element => element.countryIsoCode === id,
            );
            return filteredCountries;
        }
    };

    const mapValues = (index: number) => {
        if (!list[index]) return;
        const item = list[index];
        const copyFields: any = {};
        Object.keys(list[index]).forEach(key => {
            copyFields[key] = item[key] === null ? "" : item[key];
        });

        copyFields.brandId = mapper("brands", list[index].brandId);
        copyFields.countryIsoCode = mapper(
            "countries",
            list[index].countryIsoCode,
        );

        setValues(copyFields);
    };

    /**
     * Change handler
     */
    const handleChange = (field: string, value: any) => {
        const copyValues = clonedeep(values);
        setValues({
            ...copyValues,
            [field]: value,
        });
    };

    /**
     * Check the field validation
     * The User must add at least bpfId OR orderType
     */
    const validation = useCallback(
        (field: any) => {
            return (
                (field.id !== "bpfId" &&
                    field.id !== "orderType" &&
                    field.required &&
                    !values[field.id] &&
                    !!touched[field.id]) ||
                (!!touched["bpfId"] &&
                    field.id === "bpfId" &&
                    !values["bpfId"] &&
                    !values["orderType"]) ||
                (!!touched["orderType"] &&
                    field.id === "orderType" &&
                    !values["orderType"] &&
                    !values["bpfId"])
            );
        },
        [values, touched],
    );

    /**
     * Initial loading status
     */
    const isInitialLoading = useCallback(
        (withCreating: boolean) => {
            if (!loading) return false;
            return Object.keys(loading).some(key =>
                withCreating
                    ? !!loading[key]
                    : key !== "creatingTransactionValues" && !!loading[key],
            );
        },
        [loading],
    );

    const handleSubmit = () => {
        onSubmit(values).then(success => {
            !!success && toggleModal(false);
        });
    };

    /**
     * Debounce duplicate validation
     */
    const debounceLoadData = useCallback(
        debounce(bpfId => validate(undefined, { bpfId }), 600),
        [],
    );

    useEffect(() => {
        if (selectedItemIndex === undefined) return;
        mapValues(selectedItemIndex);
        toggleModal(true);
        setEditMode(true);
    }, [selectedItemIndex]);

    /**
     * Load debouncer of duplicate validation
     */

    useEffect(() => {
        // if no list or the stored bpf is not equal to the original bpfId we don't need to validate it
        if (
            selectedItemIndex === undefined ||
            !isArrayWithContent(list) ||
            (!!values?.bpfId &&
                typeof selectedItemIndex === "number" &&
                list[selectedItemIndex]?.bpfId === values?.bpfId)
        )
            return;

        // if no stored bpf and the original bpfId exists we need to clear the validation error

        if (
            !values?.bpfId &&
            typeof selectedItemIndex === "number" &&
            !!list[selectedItemIndex]?.bpfId
        ) {
            forceReset();
            return;
        }

        debounceLoadData(values.bpfId);
    }, [values?.bpfId, list, editMode]);

    /**
     * Duplicate validation Memoization
     */

    const bpfIdValidation = useMemo(() => {
        return { isNotUniqueValue, isNotUniqueMessage, duplicationValidating };
    }, [isNotUniqueValue, isNotUniqueMessage, duplicationValidating]);

    /**
     * Render
     */
    return (
        <Box mt={4}>
            <Modal
                open={openModal}
                onClose={() => toggleModal(false)}
                title={!editMode ? "Add new values" : "Edit values"}
                id={`${id}-modal`}
            >
                <Grid container spacing={2}>
                    {isArrayWithContent(fields) &&
                        fields?.map((field, index) => (
                            <Grid item xs={4} key={`${field.id}-${index}`}>
                                <InputLabel
                                    error={
                                        validation(field) ||
                                        (field.id == "bpfId" &&
                                            bpfIdValidation?.isNotUniqueValue)
                                    }
                                    shrink
                                >
                                    {field.inputLabel}{" "}
                                    {(field.id !== "bpfId" &&
                                        field.id !== "orderType" &&
                                        !field.required) ||
                                    (field.id === "bpfId" &&
                                        !!values["orderType"]) ||
                                    (field.id === "orderType" &&
                                        !!values["bpfId"])
                                        ? ""
                                        : " (*)"}
                                </InputLabel>

                                {field.id !== "brandId" &&
                                    field.id !== "countryIsoCode" && (
                                        <TextField
                                            value={values[field.id]}
                                            fullWidth
                                            id={`${id}-${field.id}`}
                                            autoComplete="off"
                                            error={
                                                validation(field) ||
                                                (field.id === "bpfId" &&
                                                    bpfIdValidation?.isNotUniqueValue)
                                            }
                                            onBlur={() =>
                                                setTouched({
                                                    ...touched,
                                                    [field.id]: true,
                                                })
                                            }
                                            disabled={isInitialLoading(true)}
                                            name="name"
                                            variant="outlined"
                                            size="small"
                                            onChange={event =>
                                                handleChange(
                                                    field.id,
                                                    event.target.value,
                                                )
                                            }
                                            InputProps={
                                                field.id === "bpfId"
                                                    ? bpfIdValidation?.duplicationValidating
                                                        ? {
                                                              endAdornment: (
                                                                  <InputAdornment position="start">
                                                                      <CircularProgress
                                                                          color="inherit"
                                                                          size={
                                                                              20
                                                                          }
                                                                      />
                                                                  </InputAdornment>
                                                              ),
                                                          }
                                                        : bpfIdValidation?.isNotUniqueValue
                                                          ? {
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip
                                                                            open={
                                                                                true
                                                                            }
                                                                            arrow
                                                                            title={
                                                                                bpfIdValidation?.isNotUniqueMessage
                                                                            }
                                                                            placement="top"
                                                                        >
                                                                            <InfoIcon color="error" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }
                                                          : undefined
                                                    : undefined
                                            }
                                        />
                                    )}
                                {field.id === "countryIsoCode" && (
                                    <Select
                                        value={values?.countryIsoCode}
                                        onChange={(_, index) => {
                                            handleChange(
                                                field.id,
                                                countries[index],
                                            );
                                        }}
                                        name={field.id}
                                        disabled={isInitialLoading(true)}
                                        id={`${id}-${field.id}`}
                                        menuItemLabel={"country"}
                                        menuItemId="countryIsoCode"
                                        error={validation(field)}
                                        list={countries}
                                        loading={loading?.fetchingCountries}
                                        onBlur={() =>
                                            setTouched({
                                                ...touched,
                                                [field.id]: true,
                                            })
                                        }
                                    />
                                )}

                                {field.id === "brandId" && (
                                    <Select
                                        value={values?.brandId}
                                        onChange={(_, index) =>
                                            handleChange(
                                                field.id,
                                                brands[index],
                                            )
                                        }
                                        name={field.id}
                                        disabled={isInitialLoading(true)}
                                        id={`${id}-${field.id}`}
                                        menuItemLabel={"brandName"}
                                        menuItemId="brandId"
                                        error={validation(field)}
                                        list={brands}
                                        loading={loading?.fetchingBrands}
                                        onBlur={() =>
                                            setTouched({
                                                ...touched,
                                                [field.id]: true,
                                            })
                                        }
                                    />
                                )}
                            </Grid>
                        ))}
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={() => {
                                toggleModal(false);
                            }}
                            disabled={isInitialLoading(true)}
                            sx={{ marginRight: "1rem" }}
                        >
                            <Typography variant="button">Cancel</Typography>
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            disabled={disabled || isInitialLoading(true)}
                            onClick={handleSubmit}
                        >
                            {loading?.creatingTransactionValues ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : (
                                <Typography variant="button">Save</Typography>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Modal>

            <Box mb={4}>
                <Button
                    size="large"
                    sx={{ fontSize: "0.75rem", height: "34px" }}
                    variant="contained"
                    onClick={() => {
                        toggleModal(true);
                    }}
                    disabled={isInitialLoading(false)}
                >
                    Add new value
                </Button>
            </Box>

            <Table
                loading={isInitialLoading(false)}
                headers={HEADERS.TRANSACTION_VALUES}
                rows={list}
                type={ROWRENDERERCONST.TRANSACTION_VALUES}
                id="transaction-values-list"
                callbacks={{
                    editItem: (index: number) => {
                        setIndex(index);
                    },
                }}
                emptyMsg="No data"
                mapper={(field: string, id: string) => mapper(field, id)}
            />
        </Box>
    );
};
export default React.memo(MaintenanceTransactionValuesList);
