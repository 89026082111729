// Lib
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import GridOn from "@mui/icons-material/GridOn";
import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import clonedeep from "lodash.clonedeep";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FormFooter,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    Dialog,
} from "@components";

// Hooks
import { useFinance, useValidateDuplication, useScanner } from "@hooks";

// Atoms
import {
    generateVolumeBasedBpfStepsState,
    generatePerformanceBasedBpfStepsState,
    generateApheresisBasedBpfStepsState,
    generateBpfState,
} from "@atoms";

// Types
import { Location } from "@types";

// Utils
import { isArrayWithContent, isSuccessfulCall } from "@utils";
import { useRecoilState, useResetRecoilState } from "recoil";

/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactNode;
    type: "volume-based" | "performance-based" | "apheresis-based";
}

/**
 * Generate BPF
 */
const Layout = ({ type, location, children }: Props) => {
    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    /**
     * Evidence state
     */
    const [uploadFile, setFileToUpload] = useState<File>();

    // Failed files
    const [failedFiles, setFailedFiles] = React.useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCEL";
            index: number;
        }[]
    >([]);

    // Scanner
    const { scan, loading: scanning } = useScanner("DEFAULT");

    /**
     * Check flow type
     */
    const { isVolumeBased, isPerformanceBased, isApheresisBased } =
        useMemo(() => {
            if (!type)
                return {
                    isVolumeBased: false,
                    isPerformanceBased: false,
                    isApheresisBased: false,
                };
            return {
                isVolumeBased: type === "volume-based",
                isPerformanceBased: type === "performance-based",
                isApheresisBased: type === "apheresis-based",
            };
        }, [type]);

    /**
     * States
     */
    const [steps, setSteps] = useRecoilState(
        isVolumeBased
            ? generateVolumeBasedBpfStepsState
            : isPerformanceBased
              ? generatePerformanceBasedBpfStepsState
              : generateApheresisBasedBpfStepsState,
    );

    // State values
    const [values, updateValuesState] = useRecoilState(generateBpfState);

    // Reset
    const resetBpfState = useResetRecoilState(generateBpfState);
    const resetBpfStepsState = useResetRecoilState(
        isVolumeBased
            ? generateVolumeBasedBpfStepsState
            : isPerformanceBased
              ? generatePerformanceBasedBpfStepsState
              : generateApheresisBasedBpfStepsState,
    );

    // Store successful status
    const [isBpfGenerated, setBpfStatus] = useState(false);

    // Touched
    const [touch, setTouch] = useState<{
        bpfName: boolean;
        paymentReference: boolean;
        uploadFile: boolean;
    }>({ bpfName: false, paymentReference: false, uploadFile: false });

    /**
     * Is outcomes page
     */
    const isSelectOutcomesPage = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("select-outcomes");
    }, [location]);

    /**
     * is contract page
     */
    const isSelectContractPage = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("select-contract");
    }, [location]);

    /**
     * Is claims page
     */
    const isSelectClaimsPage = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("select-claims");
    }, [location]);

    /**
     * API
     */
    // Get outcomes
    const {
        loading: { creating },
        upsert: generateBPF,
        forceReset,
    } = useFinance("bpf", "outcome|outcomes");

    /**
     * Check the uniqueness of bpf name
     */
    const {
        validate: validateBpfName,
        isUniqueValue: isBpfNameUniqueValue,
        isNotUniqueValue: isBpfNameNotUniqueValue,
        isNotUniqueMessage: isBpfNameNotUniqueMessage,
        loading: bpfNameDuplicationValidating,
    } = useValidateDuplication("bpfName");

    /**
     * Check the uniqueness of bpf name
     */
    const {
        validate: validatePaymentReference,
        isUniqueValue: isPaymentReferenceUniqueValue,
        isNotUniqueValue: isPaymentReferenceNotUniqueValue,
        isNotUniqueMessage: isPaymentReferenceNotUniqueMessage,
        loading: paymentReferenceDuplicationValidating,
    } = useValidateDuplication("paymentReference");

    /**
     * Reset and navigate to the first step
     */
    const resetAndNavigateToStart = () => {
        setBpfStatus(false);
        resetBpfStepsState();
        resetBpfState();
        navigate(`/finances/generate-bpf/${type}/select-contract/`);
        return;
    };

    /**
     * Reset the state if the user manually navigate to the select outcome step
     */
    useEffect(() => {
        if (!location) return;
        if (
            !values.contractId &&
            (isSelectOutcomesPage || isSelectClaimsPage)
        ) {
            resetAndNavigateToStart();
        }
    }, [location, values, isSelectOutcomesPage, isSelectClaimsPage]);

    useEffect(() => {
        // Reset the state if the user leaves the flow
        if (!location || location?.state?.isGenerateBpfFlow) {
            resetBpfState();
        }
    }, []);

    /**
     * Update recoil state
     */
    const onStateChange = (key: string, value: any) => {
        if (!key) return;

        const copyValues = clonedeep(values);

        if (key === "contractId") {
            copyValues["outcomes"] = [];
            copyValues["claims"] = [];
            copyValues["priceCorrectionClaims"] = [];
        }

        copyValues[key] = value;

        updateValuesState(copyValues);
    };

    /**
     * Handle generate bpf
     */
    const onGenerateBPF = async (file?: {
        fileName: string;
        fileKey: string;
    }) => {
        generateBPF({
            ...values,
            type: isVolumeBased
                ? "CLAIM"
                : isApheresisBased
                  ? "REBATE_AT_APHERESIS"
                  : "OUTCOME",
            outcomes: isVolumeBased ? undefined : values.outcomes,
            claims: isVolumeBased ? values.claims : undefined,
            priceCorrectionClaims: isVolumeBased
                ? values.priceCorrectionClaims
                : undefined,
            file: isApheresisBased && !!file ? { ...file } : undefined,
        }).then(res => {
            if (res) {
                setBpfStatus(isSuccessfulCall(res?.status));
                setFailedFiles([]);
            }
        });
    };

    /**
     * Upload handler
     */

    const handleComplete = (complete: Array<any>) => {
        forceReset();
        onGenerateBPF({
            fileKey: complete[0].fileKey,
            fileName: complete[0].fileName,
        });
    };

    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    const handleModalConfirm = (shouldRetry: boolean) => {
        if (!shouldRetry) {
            setFailedFiles([]);
            return;
        }

        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    const handleGenerateBPF = () => {
        if (isApheresisBased) {
            scan(
                [{ file: uploadFile }],
                setFailedFiles,
                handleComplete,
                handleRetry,
            );
        } else {
            onGenerateBPF();
        }
    };
    /**
     * Check select contract validity
     */
    const isSelectContractValid = useMemo(() => {
        return !!values?.contractId;
    }, [values?.contractId]);

    /**
     * Check select outcomes validity
     */
    const isSelectOutcomesValid = useMemo(() => {
        return (
            isArrayWithContent(values.outcomes) &&
            ((isPerformanceBased && !!values.bpfName && isBpfNameUniqueValue) ||
                (isApheresisBased &&
                    !!values.bpfName &&
                    isBpfNameUniqueValue &&
                    !!values.paymentReference &&
                    !!uploadFile))
        );
    }, [
        values?.bpfName,
        values?.outcomes,
        values.paymentReference,
        isBpfNameUniqueValue,
        isApheresisBased,
        isPerformanceBased,
        uploadFile,
    ]);

    /**
     * Check select outcomes validity
     */
    const isSelectClaimsValid = useMemo(() => {
        return (
            (isArrayWithContent(values.claims) ||
                isArrayWithContent(values.priceCorrectionClaims)) &&
            !!values.bpfName &&
            !!values.paymentReference &&
            isBpfNameUniqueValue &&
            isPaymentReferenceUniqueValue
        );
    }, [
        values?.bpfName,
        values?.paymentReference,
        values?.claims,
        isBpfNameUniqueValue,
        isPaymentReferenceUniqueValue,
        values.priceCorrectionClaims,
    ]);

    /**
     * Handle next click
     */
    const handleNextClick = () => {
        if (isSelectContractValid && isSelectContractPage) {
            return isVolumeBased
                ? navigate("/finances/generate-bpf/volume-based/select-claims/")
                : navigate(`/finances/generate-bpf/${type}/select-outcomes/`);
        }

        if (isSelectOutcomesValid || isSelectClaimsValid) {
            return isBpfGenerated
                ? navigate(`/dashboard/finances/bpf/`)
                : handleGenerateBPF();
        }
    };

    /**
     * Disable submit button
     */
    const disabled = useMemo(() => {
        if (isVolumeBased) {
            return (
                !isBpfGenerated &&
                ((isSelectContractPage && !isSelectContractValid) ||
                    (isSelectClaimsPage && !isSelectClaimsValid))
            );
        } else
            return (
                !isBpfGenerated &&
                ((isSelectContractPage && !isSelectContractValid) ||
                    (isSelectOutcomesPage && !isSelectOutcomesValid))
            );
    }, [
        isBpfGenerated,
        isVolumeBased,
        isSelectContractPage,
        isSelectContractValid,
        isSelectClaimsPage,
        isSelectClaimsValid,
        isSelectOutcomesPage,
        isSelectOutcomesValid,
    ]);

    useEffect(() => {
        if (!location || !steps) return;
        const copySteps = clonedeep(steps);

        if (isBpfGenerated) {
            copySteps[1].isCompleted = true;
        }

        if (values.contractId) {
            copySteps[0].isPrepared = true;
        } else {
            copySteps[0].isPrepared = false;
            copySteps[0].isCompleted = false;
        }

        if (
            (isArrayWithContent(values.outcomes) && !!values.bpfName) ||
            isArrayWithContent(
                !!values.claims &&
                    !!values.bpfName &&
                    !!values.paymentReference,
            )
        ) {
            copySteps[1].isPrepared = true;
        } else {
            copySteps[1].isPrepared = false;
            copySteps[1].isCompleted = false;
        }

        if (
            !isBpfGenerated &&
            (!!isSelectOutcomesPage || !!isSelectClaimsPage) &&
            !!values.contractId
        ) {
            copySteps[0].isCompleted = true;
        }

        setSteps(copySteps);
    }, [
        location,
        values,
        isSelectOutcomesPage,
        isSelectClaimsPage,
        isBpfGenerated,
    ]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-scanning`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        // setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Cancel claim file upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />
            <Breadcrumbs
                id={`generate-bpf-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`generate-bpf-stepper`}
                        title={"New batch payment file"}
                        steps={steps}
                        location={location}
                        disabled={isBpfGenerated}
                        onStepClick={path =>
                            navigate(path, {
                                state: {
                                    isCreateBpfFlow: true,
                                },
                            })
                        }
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            {isBpfGenerated && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    height={1}
                                >
                                    <Typography variant="h2">
                                        BPF successfully generated
                                    </Typography>
                                </Box>
                            )}

                            {!isBpfGenerated &&
                                React.Children.map(
                                    children as React.ReactElement,
                                    (child: React.ReactElement) =>
                                        React.cloneElement(child, {
                                            location,
                                            disabled: creating,
                                            setContract: contract =>
                                                onStateChange(
                                                    "contractId",
                                                    contract.id,
                                                ),
                                            contractId: values.contractId,
                                            selectedOutcomes: values.outcomes,
                                            onChange: (
                                                key: string,
                                                value: any,
                                            ) => onStateChange(key, value),
                                            bpfName: values.bpfName,
                                            paymentReference:
                                                values.paymentReference,

                                            setTouch,
                                            validateBpfName,
                                            validatePaymentReference,
                                            touch,
                                            selectedClaims: (
                                                type:
                                                    | "claims"
                                                    | "priceCorrectionClaims",
                                            ) =>
                                                type === "claims"
                                                    ? values.claims
                                                    : values.priceCorrectionClaims,
                                            bpfNameValidation: {
                                                isBpfNameNotUniqueValue,
                                                isBpfNameNotUniqueMessage,
                                                bpfNameDuplicationValidating,
                                            },
                                            paymentReferenceValidation: {
                                                isPaymentReferenceNotUniqueValue,
                                                isPaymentReferenceNotUniqueMessage,
                                                paymentReferenceDuplicationValidating,
                                            },
                                            id: `create-contract`,
                                            isVolumeBased,
                                            isApheresisBased,
                                            uploadFile,
                                            setFileToUpload,
                                        }),
                                )}
                        </FlowContent>
                        <FlowFooter>
                            <FormFooter
                                id={`generate-bpf-footer`}
                                error={
                                    (!!touch?.bpfName && !values?.bpfName) ||
                                    (!values?.paymentReference &&
                                        !!touch?.paymentReference) ||
                                    (isApheresisBased &&
                                        !uploadFile &&
                                        !!touch?.uploadFile)
                                }
                                textAlign="right"
                                showText={
                                    !isBpfGenerated && !isSelectContractPage
                                }
                            />
                            <StepActions
                                id={`generate-bpf-step-actions`}
                                loading={creating}
                                primaryButton={{
                                    text: isBpfGenerated
                                        ? "Return to overview"
                                        : isSelectOutcomesPage ||
                                            isSelectClaimsPage
                                          ? "Generate BPF"
                                          : "Next step",
                                    isSubmitButton:
                                        isBpfGenerated ||
                                        isSelectOutcomesPage ||
                                        isSelectClaimsPage,
                                    action: handleNextClick,
                                    disabled: disabled || creating,
                                }}
                                secondaryButton={{
                                    text: "Back",
                                    action: () =>
                                        isVolumeBased
                                            ? navigate(
                                                  "/finances/generate-bpf/volume-based/select-contract",
                                              )
                                            : navigate(
                                                  `/finances/generate-bpf/${type}/select-contract`,
                                                  {
                                                      state: {
                                                          isCreateBpfFlow: true,
                                                      },
                                                  },
                                              ),

                                    hidden:
                                        isSelectContractPage || isBpfGenerated,
                                }}
                                tertiaryButton={{
                                    text: "New batch payment file",
                                    action: () => resetAndNavigateToStart(),
                                    disabled: creating,
                                    hidden: !isBpfGenerated,
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};
export default Layout;
