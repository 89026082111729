// Lib
import ConstructionIcon from "@mui/icons-material/Construction";
import Box from "@mui/material/Box";
import React, { useMemo, useState } from "react";

// Own components
import { Breadcrumbs } from "@components";

// Types
import type { Location } from "@types";
import { breadcrumbsMapper } from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
}

interface MaintenancesRef {
    getRecordsCount: () => { itemCount: number | undefined };
}

/**
 * Child component props
 */
interface ChildComponentProps {
    ref: React.RefObject<MaintenancesRef>;
    onDataReady: () => void;
    id: string;
}

/**
 * Maintenance layout
 */
const MaintenanceLayout = ({ children, location }: Props) => {
    const childRef = React.useRef<MaintenancesRef>(null);
    const [itemCount, setItemCount] = useState<number | undefined>(undefined);

    const handleDataReady = () => {
        if (childRef.current) {
            const data = childRef.current.getRecordsCount();
            setItemCount(data.itemCount);
        }
    };

    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Product",
                path: "/maintenance/product/",
            },
            {
                title: "Financial conditions",
                path: "/maintenance/financial-conditions/",
            },
            {
                title: "Product conditions",
                path: "/maintenance/product-conditions/",
            },
            {
                title: "Partner",
                path: "/maintenance/partner/",
            },
            {
                title: "Country info",
                path: "/maintenance/country/",
            },
            {
                title: "Teams",
                path: "/maintenance/teams/",
                subpages: [
                    {
                        title: "Roles",
                        path: "/maintenance/roles/",
                    },
                ],
            },
            {
                title: "Users",
                path: "/maintenance/users/",
            },
            {
                title: "Context",
                path: "/maintenance/context",
            },
            {
                title: "Model",
                path: "/maintenance/model",
            },
            {
                title: "Category",
                path: "/maintenance/category",
            },
            {
                title: "Interface",
                path: "/maintenance/interface/",
            },
            {
                title: "Billing plan",
                path: "/maintenance/billing-plan/",
            },
            {
                title: "Milestone",
                path: "/maintenance/milestone/",
            },
            {
                title: "System availability",
                path: "/maintenance/system-maintenance/",
            },
        ];
    }, []);

    const findSubpage = useMemo(() => {
        return breadcrumbsMapper(tabs, location);
    }, [location.pathname, tabs]);

    // Update the childWithRef logic
    const childWithRef = React.useMemo(() => {
        return React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(
                    child as React.ReactElement<ChildComponentProps>,
                    {
                        ref: childRef,
                        onDataReady: handleDataReady,
                    },
                );
            }
            return child;
        });
    }, [children]);

    return (
        <div>
            <Breadcrumbs
                id={`maintenance-breadcrumb`}
                icon={<ConstructionIcon color="primary" />}
                title="Maintenance"
                location={location}
                itemCount={itemCount}
                subPage={findSubpage?.title}
            />

            <Box px={5}>{childWithRef}</Box>
        </div>
    );
};
export default MaintenanceLayout;
