// Lib
import { Box, Typography } from "@mui/material";
import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Own components
import { FormFooter, GrayBox, InfoBox, Table } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Custom hooks
import { useOutcomeOrders, useUpdateStep } from "@hooks";

// Atoms
import {
    contractSelectionState,
    createOutcomeStepsState,
    orderSelectionState,
} from "@atoms";

// Types
import type { FlowStep, Location } from "@types";

//Utils
import { apiResponseCounter, displayDate } from "@utils";

//Style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    id?: string;
}
/**
 * Order Selection
 */
const OrderSelection = React.forwardRef(({ location, id }: Props, ref) => {
    /**
     * API
     */

    //Orders
    const {
        list: orders,
        loading: ordersLoading,
        search: ordersSearch,
        //reload: reloadOrders,
    } = useOutcomeOrders("outcomes/contract", "order|orders");

    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [infoBoxVisibility, setInfoBoxVisibility] = useState<boolean>();

    // Get contract info from state
    const contractInfo = useRecoilValue(contractSelectionState);

    // Order selection state
    const [orderSelectionInitialState, updateOrderSelectionState] =
        useRecoilState(orderSelectionState);

    /**
     * Form validation check
     */
    const canSaveState = !!orderSelectionInitialState.orderId;

    /**
     * Set selected row when changing stepper page
     */
    useEffect(() => {
        if (!orderSelectionInitialState.linked) setInfoBoxVisibility(true);

        if (!selectedRow.length && canSaveState)
            setSelectedRow([orderSelectionInitialState.orderId]);
    }, [selectedRow]);

    // update step hook
    const updateStepValidation = useUpdateStep(
        location,
        createOutcomeStepsState,
    );

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    useEffect(() => {
        updateStepValidation(canSaveState);
    }, [canSaveState, location]);

    /**
     * Save state and go to the next page (controlled by the layout)
     */
    useImperativeHandle(ref, () => ({
        updateState() {
            updateOrderSelectionState(orderSelectionInitialState);
        },
    }));

    useEffect(() => {
        if (contractInfo?.id) ordersSearch(contractInfo.id);
    }, [contractInfo]);

    const mixedOrders = useMemo(() => {
        return {
            linkedOrders: orders?.data?.linkedOrders?.records,
            linkableOrders: orders?.data?.linkableOrders?.records,
        };
    }, [orders?.data]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={1}
        >
            <div>
                <Box mb={7}>
                    <Typography variant="h2">Select order</Typography>
                </Box>
                <GrayBox
                    id={`${id}-order-selection-gray-box`}
                    className={style.selectedContract}
                    header={
                        <Typography variant="h3">
                            {contractInfo.reference}
                        </Typography>
                    }
                    helper={
                        <Box display="flex" alignItems="center">
                            <Typography variant="captionSmall">
                                {`${contractInfo?.primaryContractPartner?.name}`}
                            </Typography>
                            <span className={style.divider}>|</span>

                            <Typography variant="captionSmall">
                                {Object.keys(contractInfo.products)
                                    .map(
                                        key =>
                                            `${contractInfo.products[key].name}`,
                                    )
                                    .join(", ")}
                            </Typography>
                            <span className={style.divider}>|</span>
                            <Typography variant="captionSmall">
                                {contractInfo.indication}
                            </Typography>
                            <span className={style.divider}>|</span>
                            <Typography variant="captionSmall">
                                {`Valid from ${displayDate(
                                    contractInfo.startDate,
                                )} to ${displayDate(contractInfo.endDate)}`}
                            </Typography>
                        </Box>
                    }
                />

                <Box display="flex" alignItems="baseline" mb={4} mt={7}>
                    <Typography variant="h3">Orders</Typography>
                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            {
                                status: orders?.status,
                                data:
                                    mixedOrders?.linkableOrders &&
                                    mixedOrders?.linkedOrders
                                        ? [
                                              ...mixedOrders.linkableOrders,
                                              ...mixedOrders.linkedOrders,
                                          ]
                                        : [],
                            },
                            ordersLoading,
                            "order|orders",
                        )}
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4">
                        Linked to this contract
                    </Typography>
                    <Table
                        id={`${id}-orders-list`}
                        headers={HEADERS.OUTCOME_ORDER_SELECTION}
                        rows={mixedOrders?.linkedOrders || []}
                        loading={ordersLoading}
                        maxHeight="30rem"
                        type={ROWRENDERERCONST.OUTCOME_ORDER_SELECTION}
                        callbacks={{
                            onSelect: row => {
                                updateOrderSelectionState({
                                    ...row,
                                    linked: true,
                                });
                                setSelectedRow([row.orderId]);
                                setInfoBoxVisibility(false);
                            },
                        }}
                        emptyMsg="No data"
                        selectedRow={selectedRow}
                        isOverview={false}
                    />
                </Box>

                <Box mt={8}>
                    <Typography variant="h4" mr={1}>
                        Not linked to any contract
                    </Typography>

                    <Table
                        id={`${id}-not-linked-orders-list`}
                        headers={HEADERS.OUTCOME_ORDER_SELECTION}
                        rows={mixedOrders?.linkableOrders || []}
                        loading={ordersLoading}
                        maxHeight="30rem"
                        type={ROWRENDERERCONST.OUTCOME_ORDER_SELECTION}
                        callbacks={{
                            onSelect: row => {
                                updateOrderSelectionState({
                                    ...row,
                                    linked: false,
                                });
                                setSelectedRow([row.orderId]);
                                setInfoBoxVisibility(true);
                            },
                        }}
                        selectedRow={selectedRow}
                        emptyMsg="No data"
                        isOverview={false}
                    />
                    {infoBoxVisibility && (
                        <Box mt="3.125rem">
                            <InfoBox
                                id="not-linked-order-info-box"
                                warning
                                message={`You’ve selected an order that is not yet linked to this contract. By continuing you will link the the order to contract ${contractInfo.reference}. This action cannot be undone.`}
                            />
                        </Box>
                    )}
                </Box>
            </div>
            <FormFooter
                error={false}
                textAlign="right"
                id={`${id}-order-selection-footer`}
            />
        </Box>
    );
});

export default React.memo(OrderSelection);
