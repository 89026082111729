// Libs
import { Box } from "@mui/material";
import { navigate } from "gatsby";
import React, { useEffect, useImperativeHandle } from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import {
    ACCOUNT_STATUS,
    filterByCountry,
    HEADERS,
    ROWRENDERERCONST,
} from "@constants";

// Hooks
import {
    useAccountTypes,
    usePartners,
    usePermission,
    useSelectedCountry,
    useViewingOptions,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import { constructQueryString } from "@utils";

type Props = {
    onDataReady?: () => void;
};

interface PartnersRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Partners
 */
const Partners = React.forwardRef<PartnersRef, Props>(
    ({ onDataReady }, ref) => {
        /**
         * Selected team country isoCode
         */
        const { countryIsoCode } = useSelectedCountry();
        /**
         * Permissions
         */
        const { hasPermissionToEditDataTables }: Permissions = usePermission();

        /**
         * Hooks
         */
        const { viewingOptions, setViewingOptions } = useViewingOptions(
            ROWRENDERERCONST.PARTNERS,
        );

        /**
         * API
         */
        const {
            list: partnerList,
            loading: { fetching },
            search,
            load: reloadPartners,
        } = usePartners(`partners`, "partner|partners", false);

        const { list: accountTypes, loading: fetchingAccountTypes } =
            useAccountTypes();

        /**
         * Search handler
         */
        const onSearch = (filters: SearchFilters) => {
            const hasFilters = Object.values(filters).some(
                filter => filter.length,
            );

            const params = constructQueryString(filters);
            if (hasFilters) search(`${params}`);
            else reloadPartners();
        };

        /**
         * Data ready
         */
        useEffect(() => {
            if (
                (partnerList?.data?.records || partnerList?.data) &&
                !fetching
            ) {
                onDataReady?.();
            }
        }, [partnerList?.data, fetching]);

        /**
         * Imperative handle
         */
        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount: partnerList?.data?.itemCount,
                    };
                },
            }),
            [partnerList?.data],
        );

        /**
         * Render
         */
        return (
            <>
                <Box mt={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id={`dashboard-partners-overview-search-bar`}
                        filterKey={ROWRENDERERCONST.PARTNERS}
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        action={{
                            label: "New partner",
                            callback: () =>
                                navigate("/partner/create-partner/"),
                            hidden: !hasPermissionToEditDataTables,
                        }}
                        startDate={false}
                        endDate={false}
                        status={{ data: ACCOUNT_STATUS, loading: false }}
                        placeholder="Search by partner name"
                        viewingOptions={viewingOptions}
                        setViewingOptions={setViewingOptions}
                        accountTypes={{
                            data: accountTypes?.data,
                            loading: fetchingAccountTypes,
                        }}
                        hasPagination={true}
                    />
                </Box>

                <Table
                    id={`dashboard-partners-overview-list`}
                    headers={filterByCountry(HEADERS.PARTNERS, countryIsoCode)}
                    rows={partnerList?.data?.records || partnerList?.data}
                    loading={fetching}
                    type={ROWRENDERERCONST.PARTNERS}
                    viewingOptions={viewingOptions}
                    permissions={{ hasPermissionToEditDataTables }}
                    callbacks={{
                        onEditPartner: item =>
                            navigate(
                                `/partner/edit-partner/${item.accountId}/`,
                            ),
                        onExternalCodesEdit: item =>
                            navigate(
                                `/partner/external-codes/${item.accountId}/`,
                            ),
                    }}
                    emptyMsg="No partners found!"
                    hasPagination={true}
                    pagesCount={partnerList?.data?.itemCount}
                />
            </>
        );
    },
);
export default React.memo(Partners);
